import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faArrowUpRightFromSquare,
    faPencil,
    faPlus,
    faStore
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState, useRef} from "react";
import {
    isError,
    isLoading,
    isSuccess, numberWithCommas,
    showErrorsInToast,
    translateError
} from "../../../common";
import {API, API_CALL} from "../../../api";
import ConfigMaterialDetails from "./ConfigMaterialDetails";
import {SelectedProcedureItem} from "../../records/RecordDetailsPage";


export interface MaterialGroup {
    id: number;
    name: string;
}

export interface MaterialItem {
    id: number | null;
    groupId: number | null;
    name: string | null;
    me: string | null;
    startQty: number | null;
    minQty: number | null;
    expense: number | null;
    sellPrice: number | null;
    expiryDate: string | null;
    upToDateQty: number | null;
    onStockQty: number | null;
    position: string | null;
    qty: string;
    onReportDayQty: number;
}

export const EMPTY_MATERIAL_GROUP_PARAMS: MaterialGroup = {
    id: -1, name: ''
}

export const EMPTY_MATERIAL_ITEM_PARAMS: MaterialItem = {
    id: null, groupId: null, name: null, me: null, minQty: 0, startQty: 0, upToDateQty: 0, onStockQty: 0, expense: 0, position: null,
    qty: '', onReportDayQty: 0, sellPrice: null, expiryDate: null
}


export interface ConfigMaterialsProps {
    onSelect?: (c: SelectedProcedureItem) => any,
    onClose: () => any,
    zIndex?: number,
    onlyFillers?: boolean
}

export default function ConfigMaterialsForm({onSelect, onClose, zIndex, onlyFillers}: ConfigMaterialsProps) {

    const [groups, setGroups] = useState<Array<MaterialGroup>>([]);
    const [materials, setMaterials] = useState<Array<MaterialItem>>([]);
    const [materialsList, setMaterialsList] = useState<Array<MaterialItem>>([]);
    const [editMaterial, setEditMaterial] = useState<MaterialItem | null>(null);
    const [working, setWorking] = useState(false);
    const [activeGroup, setActiveGroup] = useState<MaterialGroup | null>(null);
    const [filter, setFilter] = useState('');
    const [activeClinic, setActiveClinic] = useState(0);

    const grappedId = useRef<number|null|undefined>(null);
    const enteredId = useRef<number|null|undefined>(null);

    useEffect(() => {
        loadGroups()
    }, [activeClinic]);

    useEffect(() => {
        setMaterials([]);
        if (activeGroup) {
            loadMaterials();
        }
    }, [activeGroup])

    function loadGroups() {
        API.getMaterialsGroups(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    if(onlyFillers) {
                        setGroups(api_call.data.data.groups.filter((g: MaterialGroup) => g.name === "Филъри"));
                    } else {
                        setGroups(api_call.data.data.groups);
                    }
                    if (api_call.data.data.groups.length > 0) setActiveGroup(api_call.data.data.groups[0]);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на групите!', translateError)
            }, activeClinic
        )
    }

    function loadMaterials() {
        API.getMaterialsItems(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setMaterials(api_call.data.data.materials);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на материалите!', translateError)
            }, activeGroup?.id
        )
    }

    useEffect(() => {
        let _mat: Array<MaterialItem> = [];
        if (materials && activeGroup) {
            _mat = materials.filter(
                m => {
                    return (activeGroup && m && m.name && m?.groupId === activeGroup.id && (m?.name?.toLowerCase().indexOf(filter.toLowerCase()) >= 0))
                }
            )
        }
        setMaterialsList(_mat);
    }, [materials, filter]);


    function saveMaterial(m: MaterialItem) {
        setWorking(true);

        API.postMaterialsItem(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    setEditMaterial(null);
                    loadMaterials();
                }
            }, m
        );
    }

    function deleteMaterial() {
        setWorking(true);

        API.deleteMaterialsItem(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване', translateError);
                if (isSuccess(apiCall)) {
                    setEditMaterial(null);
                    loadMaterials();
                }
            }, editMaterial
        );
    }

    function selectMaterial(m: MaterialItem) {
        const c: SelectedProcedureItem = {
            itemId: m.id || 0,
            itemName: m.name || '',
            itemExpense: m.expense || 0,
            itemSellPrice: m.sellPrice || 0,
        };

        onSelect!(c);
    }

    const startDrag = function (e: any, id: number|null|undefined) {
        console.log(id);
        grappedId.current = id;
    }

    const enterDrag = function (e: any, id?: number|null|undefined) {
        console.log(id);
        enteredId.current = id;
    }

    const endDrag = function (e: any, id?: number|null|undefined) {
        console.log('Drop id# ' + grappedId.current + " before id# " + enteredId.current);
        if(grappedId.current && enteredId.current && grappedId.current !== enteredId.current) {
            API.orderMaterialsItems(
                (api_call: API_CALL) => {
                    setWorking(isLoading(api_call));
                    if (isSuccess(api_call)) {
                        setMaterials(api_call.data.data.materials);
                    }
                    if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на материалите!', translateError)
                }, grappedId.current, enteredId.current
            )
        }
    }

    return (<>
            <Modal show={true} size={"xl"} onHide={onClose} style={{zIndex: zIndex ? zIndex : 1500}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faStore} className={"mr-3"}/>Номенклатура на
                        материалите</Modal.Title>
                    <CloseButton onClick={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Tabs activeKey={activeClinic} onSelect={eventKey => setActiveClinic(parseInt(eventKey || "0"))}>
                        <Tab eventKey={0} title={"Естетична клиника"} />
                        { !onlyFillers && <Tab eventKey={1} title={"Дентална клиника"} /> }
                    </Tabs>
                    <Card className={"border-0"}>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            {
                                activeGroup &&
                                <Tabs activeKey={activeGroup.name || undefined} variant={"pills"}
                                      onSelect={(eventKey) => setActiveGroup(groups.find(g => g.name === eventKey) || null)}
                                      className={"mb-3 ml-0"}
                                >
                                    {
                                        groups.map(
                                            g => <Tab key={g.id} eventKey={g.name} title={g.name}></Tab>
                                        )
                                    }
                                </Tabs>
                            }
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" value={filter}
                                                      placeholder={"Филтър..."}
                                                      onChange={(e) => setFilter(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="table-container max-h-44vh">
                            <Table bordered className={"text-light"} size={"sm"} hover>
                                <thead>
                                <tr className={"bg-secondary"}>
                                    <th>Име на материала</th>
                                    <th>М/Е</th>
                                    <th className={"text-right"}>Нач.кол</th>
                                    <th className={"text-right"}>Мин.кол</th>
                                    <th className={"text-right"}>Разход (лв)</th>
                                    <th>Стелаж No:</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody className={"text-dark"}>
                                {
                                    materialsList?.length > 0 ?
                                        materialsList.map(
                                            m => <tr key={m.id} style={{lineHeight: "2rem"}} draggable
                                                     onDragStart={(e) => startDrag(e, m.id) }
                                                     onDragEnd={(e) => endDrag(e, m.id) }
                                                     onDragEnter={(e) => enterDrag(e, m.id) }
                                            >
                                                <td onClick={() => { if(onSelect) selectMaterial(m)} }>{m.name}</td>
                                                <td onClick={() => { if(onSelect) selectMaterial(m)} }>{m.me}</td>
                                                <td onClick={() => { if(onSelect) selectMaterial(m)} } className={"text-right"}>{m.startQty}</td>
                                                <td onClick={() => { if(onSelect) selectMaterial(m)} } className={"text-right"}>{m.minQty}</td>
                                                <td onClick={() => { if(onSelect) selectMaterial(m)} } className={"text-right"}>{numberWithCommas(m.expense?.toFixed(2))}</td>
                                                <td onClick={() => { if(onSelect) selectMaterial(m)} }>{m.position}</td>
                                                <td className={"text-center"}>
                                                    <Button variant={"outline-secondary"} size={"sm"} className={"m-0"}
                                                            onClick={() => setEditMaterial(m)}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil}/>
                                                    </Button>
                                                    &nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button variant={"outline-secondary"} size={"sm"}
                                                                className={"m-0"}
                                                                onClick={() => selectMaterial(m)}
                                                        >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={7}>{!working && <>Няма материали в тази група</>}</td>
                                        </tr>
                                }
                                </tbody>
                            </Table>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            {
                                working && !editMaterial ? <Spinner animation="border"/> :
                                    <>
                                        {
                                            activeGroup &&
                                            <Button
                                                onClick={
                                                    () => {
                                                        setEditMaterial({
                                                            ...EMPTY_MATERIAL_ITEM_PARAMS,
                                                            groupId: activeGroup.id
                                                        });
                                                    }
                                                }><FontAwesomeIcon
                                                icon={faPlus}/> Добави материал</Button>
                                        }
                                    </>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>


            {
                editMaterial &&
                <ConfigMaterialDetails material={editMaterial} working={working} groups={groups}
                                       onSave={(m) => saveMaterial(m)}
                                       onDelete={() => deleteMaterial()}
                                       onCancel={() => setEditMaterial(null)}
                                       zIndex={zIndex}
                />
            }
        </>
    )
}
