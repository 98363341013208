import {Col, Container, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import React, {FC, useEffect, useState} from "react";
import {API, API_CALL} from "../../api";
import {
    anyToDateString,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError, zeroToEmptyStringAsCurrencyWithCommas,
} from "../../common";
import ReportFinanceResultTable, {
    reportDataToFinanceItem,
    ReportFinanceResultTableItem
} from "./ReportFinanceResultTable";
import EsteticReportsSummaryPage from "./EsteticReportsSummaryPage";
import EsteticReportsDetailsPage from "./EsteticReportsDetailsPage";

interface Props {
    fromDate: Date;
    toDate: Date;
}

const EsteticReportsPage: FC<Props> = (props) => {
    const fromDate = props.fromDate;
    const toDate = props.toDate;
    const [working, setWorking] = useState(false);
    const [report, setReport] = useState<any>(null);
    const [clinicIndex, setClinicIndex] = useState("-1")

    useEffect(() => {
        loadData()
    }, [props.fromDate, props.toDate, clinicIndex]);

    function loadData() {
        API.getEsteticFinanceReport(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    const report = api_call.data.data.report;
                    setReport(report);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на отчета!', translateError)
            }, clinicIndex, anyToDateString(fromDate), anyToDateString(toDate)
        )
    }

    return (
        <>
            {
                working &&
                <Row><Col className={"text-center p-5"}><Spinner animation={"border"}/></Col></Row>
            }
            {
                !working && report &&
                <Container fluid className={"mt-0 pt-0"}>
                    <Row className={"mt-0 pt-0"}>
                        <Col className={"pb-3"}>
                            <Tabs variant={"pills"} activeKey={clinicIndex}
                                  onSelect={eventKey => setClinicIndex(eventKey || "-1")}
                            >
                                <Tab title={"Всички"} eventKey={"-1"}/>
                                <Tab title={"Пловдив"} eventKey={"0"}/>
                                <Tab title={"Варна"} eventKey={"1"}/>
                            </Tabs>
                        </Col>
                    </Row>
                    <Tabs defaultActiveKey={"summary"}>
                        <Tab title={"Обобщена справка"} eventKey={"summary"} className={"mt-3"}>
                            <EsteticReportsSummaryPage report={report}/>
                        </Tab>
                        <Tab title={"Детайлна справка"} eventKey={"details"} className={"mt-3"}>
                            <EsteticReportsDetailsPage report={report}/>
                        </Tab>
                    </Tabs>
                </Container>
            }
        </>
    )
}

export default EsteticReportsPage;
