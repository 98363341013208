import {ClientRecordDocument} from "./ClientDentalRecords";
import {Button, Col, Row, Table} from "react-bootstrap";
import {dateToString} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faTrash} from "@fortawesome/free-solid-svg-icons";
import {API} from "../../api";
import {useState} from "react";
import {ConfirmDeleteModal} from "../../common/ConfirmDeleteModal";

export default function ClientDentalRecordDocuments(props: { documents: Array<ClientRecordDocument>, onDelete: (document: ClientRecordDocument) => void }) {
    const [deleteDocument, setDeleteDocument] = useState<ClientRecordDocument | null>(null);

    if (props.documents.length === 0) {
        return <div className={"small"}>Няма прикачени документи.</div>
    }

    return <>
        <Row>
            {
                props.documents.map(
                    d =>
                        <Col key={d.id} className={"small text-center mb-4"} xs={4}>
                            <div className={"border border-secondary rounded shadow overflow-hidden"}>
                                <Row className={"w-100 m-0"}>
                                    <Col className={"mb-2 mt-2"}>
                                        <span className={"d-inline-block"}
                                              style={{minHeight: "40px"}}>{d.documentType}</span>
                                        <br/>
                                        <span className={"text-secondary"}>{dateToString(d.documentDate)}</span>
                                    </Col>
                                </Row>
                                <Row className={"m-0 mb-2"}>
                                    <Col className={"align-content-center justify-content-center"}>
                                        <a className={"btn btn-outline-primary btn-sm"} target={"_blank"}
                                           href={API.site + "api/api/download-document/" + d.id + `?token=${API.getToken()}`}>
                                            <FontAwesomeIcon icon={faEye}/></a>
                                    </Col>
                                    <Col className={"align-content-center justify-content-center"}>
                                        <Button size={"sm"} variant={"outline-danger"}
                                                onClick={() => setDeleteDocument(d)}>
                                            <FontAwesomeIcon icon={faTrash}/></Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                )
            }
        </Row>

        {
            deleteDocument &&
            <ConfirmDeleteModal working={false}
                                onConfirm={() => props.onDelete(deleteDocument)}
                                onReject={() => setDeleteDocument(null)}/>
        }

    </>;
}
