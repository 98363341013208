import {ClientItem} from "../config/clients/ConfigClientsForm";
import {Button, Col, Row} from "react-bootstrap";
import {zeroToEmptyStringAsCurrency} from "../../common";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faListDots} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";
import PrepaidDialog from "../prepaid/PrepaidDialog";

function LabelValueBlock(props: { label?: string, value?: string }) {
    return <div className={"border"}>
        <div className={"d-inline-block p-2 bg-secondary-light"}>{props.label}</div>
        <span className={"pl-2"}>{props.value}</span>
    </div>;
}

export default function ClientDetails(props: { client: ClientItem | null,
    onUpdateBalance: (newBalance: number) => void
}) {
    const [showPrepaidDialog, setShowPrepaidDialog] = useState(false);

    let content = <><Row><Col>Моля, изберете клиент...</Col></Row></>;

    if (props.client) content = <>
        <Row className={"border border-secondary pt-2 pb-2 rounded shadow-sm"}>
            <Col>
                <LabelValueBlock label={"Пациент: "} value={props.client?.name}/>
            </Col>
            <Col>
                <LabelValueBlock label={"Град: "} value={props.client?.city}/>
            </Col>
            <Col>
                <LabelValueBlock label={"Телефон: "} value={props.client?.mobile}/>
            </Col>
            <Col>
                <LabelValueBlock label={"Възраст: "} value={props.client?.age}/>
            </Col>
            <Col>
                <Row>
                    <Col>
                        <LabelValueBlock label={"Аванс: "}
                                         value={zeroToEmptyStringAsCurrency(props.client?.prepaid?.toString(), "лв") || "- няма -"}/>
                    </Col>
                    <Col xs={"auto"}>
                        <Button onClick={() => {
                            setShowPrepaidDialog(true)
                        }}>
                            <FontAwesomeIcon icon={faListDots}/>
                        </Button>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>;

    return <>
        {content}
        {
            showPrepaidDialog &&
            <PrepaidDialog
                clientId={props.client!.id}
                onClose={newBalance => {
                    props.onUpdateBalance(newBalance);
                    setShowPrepaidDialog(false)
                }}
            />
        }
    </>;
}
