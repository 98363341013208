import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {faCancel, faCrown, faRotate, faUser} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import ConfigClientsForm, {ClientItem} from "../config/clients/ConfigClientsForm";
import RecordsItemPage from "./RecordsItemPage";
import {useDispatch, useSelector} from "react-redux";
import RecordDetailsPage, {ClientRecordItem, generateEmptyClientRecordItem} from "./RecordDetailsPage";
import {addClient, getClientById, removeClient, selectClients} from "../../features/ClientsSlice";
import RecordNotePage from "./RecordNotePage";
import {textToIntOrZero} from "../../common";
import {getClientRecordsAsync, selectRecords} from "../../features/RecordsSlice";
import {useAppSelector} from "../../app/hooks";
import {getClinicName} from "../../factories";


export function RecordsPage({ clinicIndex }: { clinicIndex: number }) {
    const [activeClientTab, setActiveClientTab] = useState<string | null>(null);
    const [activeClient, setActiveClient] = useState<ClientItem | null>(null);
    const [selectClientFlag, setSelectClientFlag] = useState(false);
    const [editRecord, setEditRecord] = useState<ClientRecordItem | null>(null);

    const dispatch = useDispatch();
    const clients = useSelector(selectClients);
    const records = useAppSelector(selectRecords(textToIntOrZero(activeClientTab || "-1")));
    const lastDoctor = (records && records.length > 0) ? records[records.length-1] : null;

    useEffect(() => {
        try {
            setActiveClient(getClientById(clients, parseInt(activeClientTab || '-1')));
        } catch (e) {
            if (clients && clients.length > 0) {
                setActiveClientTab(clients[0].id.toString());
                setActiveClient(clients[0]);
            } else {
                setActiveClientTab(null);
                setActiveClient(null);
            }
        }
    }, [clients, activeClientTab]);

    function loadClientRecords(id: number | undefined) {
        console.log(id);
        if(id) {
            // @ts-ignore
            dispatch(getClientRecordsAsync(id));
        }
    }

    useEffect(() => loadClientRecords(activeClient?.id), [activeClient]);

    const clinicName = getClinicName(clinicIndex);

    return (
        <>
            <Container fluid>
                <Card className={"border-0"}>
                    <Card.Header>
                        <Row className={"pt-2 pb-0"}>
                            <Col>
                                <h5 className={"text-uppercase font-weight-bold"}>
                                    <FontAwesomeIcon icon={faCrown} className={"mr-3"}/>Досиета <span className={"text-primary"}>естетична клиника - { clinicName }</span>
                                </h5>
                            </Col>
                            <Col xs={"auto"} style={{lineHeight: "2.25rem"}}>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className={"max-h-60vh scrollable p-4"}>
                        <Tabs activeKey={activeClientTab || undefined} onSelect={(ek) => setActiveClientTab(ek)}>
                            {
                                clients.map(
                                    (c: ClientItem) => c ? <Tab title={c.name} key={c.id} eventKey={c.id.toString()}
                                                            tabClassName={activeClientTab === c.id.toString() ? "bg-primary text-light" : ""}>
                                        <RecordsItemPage client={c} clinicIndex={clinicIndex} onClose={() => {
                                        }}/>
                                    </Tab> : null
                                )
                            }
                        </Tabs>

                        {
                            clients.length === 0 && <Row>
                                <Col className={"font-weight-bold text-center"}>
                                    Изберете пациент, за да продължите...
                                </Col>
                            </Row>
                        }
                    </Card.Body>
                    <Card.Footer>
                        <Row>
                            <Col>
                                <Button
                                    onClick={() => {
                                        setSelectClientFlag(true)
                                    }}
                                ><FontAwesomeIcon
                                    icon={faUser}/>&nbsp;&nbsp;Избери пациент</Button> &nbsp;
                                {
                                    activeClient && <>
                                    <Button
                                        onClick={() => {
                                            dispatch(removeClient(activeClient))
                                        }} variant={"secondary"}
                                    ><FontAwesomeIcon
                                        icon={faCancel}/>&nbsp;&nbsp;Затвори досието</Button>&nbsp;
                                    <Button variant={"outline-secondary"}
                                            onClick={() => loadClientRecords(activeClient?.id)}
                                    ><FontAwesomeIcon
                                        icon={faRotate}/></Button> &nbsp;
                                    </>
                                }
                            </Col>
                            <Col className={"text-right"}>
                                {
                                    activeClient && <>
                                        <Button variant={"outline-success"}
                                                onClick={() => {
                                                    setEditRecord(generateEmptyClientRecordItem("процедура", textToIntOrZero(activeClient?.discount), lastDoctor?.user))
                                                }}
                                        >Добави процедура</Button>
                                        &nbsp;
                                        <Button variant={"outline-info"}
                                                onClick={() => {
                                                    setEditRecord(generateEmptyClientRecordItem("филър", textToIntOrZero(activeClient?.discount), lastDoctor?.user))
                                                }}
                                        >Добави филър</Button>
                                        &nbsp;
                                        <Button variant={"outline-warning"}
                                                onClick={() => {
                                                    setEditRecord(generateEmptyClientRecordItem("вливка", textToIntOrZero(activeClient?.discount), lastDoctor?.user))
                                                }}
                                        >Добави вливка</Button>
                                        &nbsp;
                                        <Button variant={"outline-secondary"}
                                                onClick={() => {
                                                    setEditRecord(generateEmptyClientRecordItem("бележка", textToIntOrZero(activeClient?.discount), lastDoctor?.user))
                                                }}
                                        >Добави бележка</Button>
                                    </>
                                }
                            </Col>
                        </Row>
                    </Card.Footer>
                </Card>

            </Container>

            {
                selectClientFlag &&
                <ConfigClientsForm onClose={() => setSelectClientFlag(false)}
                                   zIndex={1600}
                                   onSelect={(c) => {
                                       dispatch(addClient(c));
                                       if (c) {
                                           setActiveClientTab(c.id.toString());
                                       }
                                       setSelectClientFlag(false);
                                   }}
                />
            }

            {
                editRecord && activeClient && editRecord.recordType === "бележка" &&
                <RecordNotePage onClose={() => setEditRecord(null)} clinicIndex={clinicIndex}
                                   zIndex={1600} client={activeClient} record={editRecord}
                />
            }

            {
                editRecord && activeClient && editRecord.recordType !== "бележка" &&
                <RecordDetailsPage onClose={() => setEditRecord(null)} clinicIndex={clinicIndex}
                                   zIndex={1600} client={activeClient} record={editRecord}
                />
            }
        </>
    )
}
