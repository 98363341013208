import {Button, Card, Col, Container, Form, InputGroup, ListGroup, Row, Spinner, Tab} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faTimes,
    faUser,
} from "@fortawesome/free-solid-svg-icons";
import {useEffect, useState} from "react";
import {Account} from "../../../types";
import {isError, isLoading, showErrorsInToast, translateError} from "../../../common";
import {API_PATHS} from "../../../paths";
import useGetApiCall from "../../../hooks/useGetApiCall";

export interface SelectUserComponentProps {
    forDate: string;
    operators?: boolean;
    doctors?: boolean;
    onSelect: (user: Account) => any;
    onCancel: () => any;
}

export default function SelectUserComponent({forDate, operators, doctors, onSelect, onCancel}: SelectUserComponentProps) {

    const [filter, setFilter] = useState("");
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<Account[]>([]);

    const {apiCall, reload} = useGetApiCall(API_PATHS.accounts+'?date='+forDate, {});

    useEffect(() => {
        setLoading(isLoading(apiCall));
        if (!isLoading(apiCall)) {
            if (isError(apiCall)) showErrorsInToast(apiCall, 'Възникна грешка', translateError);
            else setUsers(
                apiCall?.data.data.users.filter((u: Account) =>
                    (operators === true && u.is_operator === 'Y') ||
                    (operators === false && u.is_operator === 'N') ||
                    (doctors === true && u.is_doctor === 'Y') ||
                    (doctors === false && u.is_doctor === 'N') ||
                    (doctors === undefined && operators === undefined)
                ) || []);
        }
    }, [apiCall]);


    return (
        <>
            <Row>
                <Col>
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon icon={faSearch}/></InputGroup.Text>
                        <Form.Control value={filter} placeholder="Търсене по име..."
                                      onChange={(v) => setFilter(v.target.value)}
                        />
                    </InputGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={"overflow-auto"} style={{maxHeight: "100%"}}>
                        <ListGroup>
                            {
                                isLoading(apiCall) ? <Spinner animation="border"/> :
                                    <>
                                        {
                                            users.filter(u => (filter.length === 0 ||
                                                u.name.toLocaleLowerCase().indexOf(filter.toLocaleLowerCase()) > -1))
                                                .map(u =>
                                                    <ListGroup.Item action href={"#" + u.id} key={"link_" + u.id}
                                                                    onClick={() => onSelect({...u})}>
                                                        <div className={"text-truncate"}>
                                                            <FontAwesomeIcon icon={faUser} className={"mr-2"}/>
                                                            {u.name}
                                                        </div>
                                                    </ListGroup.Item>
                                                )
                                        }
                                    </>
                            }
                            <ListGroup.Item onClick={() => onCancel()} action>
                                <Button size={"sm"} variant={"secondary"} className={"w-100"}>
                                    <FontAwesomeIcon icon={faTimes} className={"mr-2"}/>
                                    Откажи
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </>
    )
}
