import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCrutch, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {showErrorsListInToast, textToFloat, zeroToEmptyStringAsCurrency} from "../../../common";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";
import {StringInput} from "../../../common/StringInput";
import {InfusionItem} from "./ConfigInfusionsForm";


export interface ConfigInfusionDetailsProps {
    working: boolean;
    item: InfusionItem;
    onSave: (item: InfusionItem) => any;
    onDelete: () => any;
    onCancel: () => any;
    zIndex?: number;
}

export default function ConfigInfusionDetails({working, item, onSave, onDelete, onCancel, zIndex}: ConfigInfusionDetailsProps) {
    const [editItem, setEditItem] = useState<InfusionItem>({...item});
    const [deleteFlag, setDeleteFlag] = useState(false);

    function save(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];

        editItem.name = editItem.name?.trim();
        if(editItem.name?.length === 0) errors.push("Въведете наименование.");
        // if(!editItem.expense) errors.push("Въведете цена.");
        // if(!editItem.sellPrice) errors.push("Въведете продажна цена.");

        if(errors.length === 0) {
            onSave(editItem);
        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: zIndex || 1500}} />
            <Modal show={!deleteFlag} size={"lg"} onHide={onCancel} style={{zIndex: zIndex ? zIndex+1:1501}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faCrutch} className={"mr-3"}/>Детайли за
                        вливка</Modal.Title>
                    <CloseButton onClick={onCancel}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={save}>
                        <Card className={"border-0"}>
                            <Card.Body className={"max-h-60vh scrollable"}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Наименование</Form.Label>
                                    <Form.Control name={"name"} value={editItem.name}
                                                  onChange={(e) => setEditItem({...editItem, name: e.target.value})}/>
                                </Form.Group>
                                <Row>
                                    <Col>
                                        <StringInput value={zeroToEmptyStringAsCurrency(editItem.expense?.toString())}
                                                     label={"Разходна цена"}
                                                     onChange={(e) => setEditItem({...editItem, expense: textToFloat(e) || 0})} />
                                    </Col>
                                    <Col>
                                        <StringInput value={zeroToEmptyStringAsCurrency(editItem.sellPrice.toString())}
                                                     label={"Продажна цена"}
                                                     onChange={(e) => setEditItem({...editItem, sellPrice: textToFloat(e) || 0})} />
                                    </Col>
                                </Row>

                            </Card.Body>
                            <Card.Footer>
                                {
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button type={"submit"}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>&nbsp;
                                            <Button onClick={() => onCancel()} variant={"secondary"}><FontAwesomeIcon
                                                icon={faCancel}/> Откажи</Button>
                                            <Button onClick={() => setDeleteFlag(true)} variant={"danger"} style={{ float: "right"}}>
                                                <FontAwesomeIcon
                                                icon={faTrash}/> Изтрий</Button>
                                        </>
                                }
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>

            {
                deleteFlag && <ConfirmDeleteModal working={working} onConfirm={onDelete} onReject={() => setDeleteFlag(false)} />
            }
        </>
    )
}
