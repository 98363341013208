import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileInvoice} from "@fortawesome/free-solid-svg-icons";
import {Badge} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {API, API_CALL} from "../api";
import {isSuccess} from "../common";

export default function HeaderInvoicesComponent() {
    const [outdatedCnt, setOutdatedCnt] = useState(0);

    const checkForInvoices = function () {
        API.getOutdatedInvoices(
            (api_call: API_CALL) => {
                if (isSuccess(api_call)) {
                    setOutdatedCnt(api_call.data.data.outdated);
                }
            }
        );
    }

    useEffect(
        () => {
            checkForInvoices()

            const interval = window.setInterval(() => checkForInvoices(), 600000)
            return () => {
                window.clearInterval(interval);
            }
        }, []
    )

    return (
        <>
            <FontAwesomeIcon icon={faFileInvoice}/>&nbsp;Фактуриране&nbsp;
            {
                outdatedCnt > 0 && <Badge variant={"danger"}>{ outdatedCnt }</Badge>
            }
        </>
    )
}
