import {Button, Card, Col, Form, Modal, ProgressBar, Row} from "react-bootstrap";
import {useEffect, useReducer, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileArrowUp} from "@fortawesome/free-solid-svg-icons";
import {API} from "../../api";
import {API_PATHS} from "../../paths";
import {
    ActionKind,
    FileUploadAction,
    fileUploadReducer,
    FileUploadState,
    FileUploadStatus,
    initialState,
} from "./upload-files-dialog-store";

export interface UploadFilesDialogProps {
    fileDescriptor: any;
    title?: string;
    isVisible?: boolean;
    onClose?: () => void;
}


function ListFiles(props: { files: Array<FileUploadState>}) {
    const files: Array<React.ReactElement> = [];

    props.files
    .filter(f => f.status !== FileUploadStatus.DONE)
        .forEach((f, idx) =>
        files.push(<Col key={idx} className={"border m-1 text-nowrap shadow-sm rounded" +
            (f.status === FileUploadStatus.FAILED ? " bg-danger" : "")
        }
                        xs={"auto"} style={{minWidth: "20%"}}>
            { f.fileName }
            {
                f.status === FileUploadStatus.UPLOADING && <ProgressBar animated now={f.progress} style={{height: 5}} className={"mb-1"}/>
            }
        </Col>));

    return <>
        {
            props.files.length === 0 ? "Няма избрани файлове" :
                <Row>
                    { files }
                </Row>
        }
    </>;
}



export default function UploadFilesDialog(props: UploadFilesDialogProps) {
    const [isVisible, setIsVisible] = useState(props.isVisible === undefined ? true : props.isVisible ?? true);
    const [state, dispatch] = useReducer(fileUploadReducer, initialState);
    const [uploading, setUploading] = useState(false);
    const fileInputField = useRef(null);
    const modalTitle = props.title || "Прикачване на документи";

    function handleClose() {
        if(props.onClose) {
            props.onClose();
        } else {
            setIsVisible(false);
        }
    }

    function handleChange(e: any) {
        Object.values(e.target.files).
        forEach(
            (f: any) => {
                dispatch({type: ActionKind.ADD_FILE, filedata: f, filename: f.name} as FileUploadAction);
            }
        )
    }

    function openFileSelect() {
        // @ts-ignore
        fileInputField.current?.click();
    }

    function uploadFiles() {
        setUploading(true);

        state.files.forEach(
            f =>
                API.uploadFile(
                    API_PATHS.upload_documents,
                    f.fileData, props.fileDescriptor,
                    (progressEvent: any) => {
                        const {loaded, total} = progressEvent;
                        let precentage = Math.floor((loaded * 100) / total);
                        dispatch({type: ActionKind.SET_PROGRESS, filename: f.fileName, progress: precentage});
                    }
                ).then(
                    () => dispatch({type: ActionKind.SET_PROGRESS, filename: f.fileName, progress: 100})
                ).catch(
                    err => dispatch({type: ActionKind.FAILED, filename: f.fileName})
                )
        );
    }

    useEffect(() => {
        if(state.allDone && !state.hasFailed) {
            handleClose();
        }
    }, [state]);

    return <>
        <Modal show={isVisible} onHide={handleClose} >
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form>
                    <Form.Group>
                        <Button className={"w-100"} onClick={() => openFileSelect()} disabled={uploading}>
                            <FontAwesomeIcon icon={faFileArrowUp} /><span className={"ml-2"}>Изберете един или повече файла за прикачване</span></Button>
                        <Form.File multiple className={"d-none"} ref={fileInputField} onChange={(e: any) => handleChange(e)} />
                    </Form.Group>
                </Form>

                <Card>
                    <Card.Subtitle className={"text-center p-2"}>Избрани файлове</Card.Subtitle>
                    <Card.Body className={"small"}>
                        <ListFiles files={state.files} />
                    </Card.Body>
                </Card>

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose} >
                    Откажи
                </Button>
                <Button variant="primary" onClick={uploadFiles} disabled={uploading}>
                    Прикачи
                </Button>
            </Modal.Footer>
        </Modal>
    </>
}
