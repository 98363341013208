import {PrepaidItemType, } from "./PrepaidDialog";
import {Button, Col, Container, Form, Row, Spinner, Table} from "react-bootstrap";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {dateToString, zeroToEmptyStringAsNumber} from "../../common";
import React, {useEffect, useState} from "react";

function PrepaidDialogEdit(props: { item: PrepaidItemType, onSave: (newItem: PrepaidItemType) => void,
    onDone: () => void, working: boolean }) {
    const [editRecord, setEditRecord] = useState<PrepaidItemType>(props.item);

    useEffect(() => setEditRecord(props.item), [props.item]);

    function done() {
        props.onDone();
    }

    function save(e: any) {
        e.preventDefault();

        const rec = {...editRecord};
        rec.description = e.target.elements.description.value;
        rec.amount = parseFloat(e.target.elements.amount.value) || 0;
        rec.paidByBank = parseFloat(e.target.elements.paidByBank.value) || 0;
        rec.paidByCash = parseFloat(e.target.elements.paidByCash.value) || 0;
        rec.paidByCard = parseFloat(e.target.elements.paidByCard.value) || 0;
        props.onSave(rec!);
    }

    return <Container>
        <Form onSubmit={save}>
            <Row className={"mb-3 border-bottom"}>
                <Col>
                    <h5>Авансово плащане</h5>
                </Col>
            </Row>

            <Row className={"m-0"}>
                <Col xs={3}>
                        <Form.Label>Дата:</Form.Label>
                        <ReadOnlyDatePickerComponent onChange={(d: Date) => setEditRecord({...editRecord, date: d})}
                                                     value={dateToString(editRecord.date)}
                                                     hideDayControls
                        />
                </Col>
                <Col>
                    <Form.Label>Описаниe:</Form.Label>
                    <Form.Control name={"description"} defaultValue={props.item.description} disabled={props.working}/>
                </Col>
            </Row>

            <Row className={"m-0"}>
                <Col xs={3}>
                        <Form.Label>Стойност:</Form.Label>
                        <Form.Control name={"amount"} type={"number"}
                                      defaultValue={zeroToEmptyStringAsNumber(props.item?.amount)} disabled={props.working}/>
                </Col>
                <Col xs={3}>
                        <Form.Label>Платено в брой:</Form.Label>
                        <Form.Control name={"paidByCash"} type={"number"}
                                      defaultValue={zeroToEmptyStringAsNumber(props.item?.paidByCash)} disabled={props.working}/>
                </Col>
                <Col xs={3}>
                        <Form.Label>Платено с карта:</Form.Label>
                        <Form.Control name={"paidByCard"} type={"number"}
                                      defaultValue={zeroToEmptyStringAsNumber(props.item?.paidByCard)} disabled={props.working}/>
                </Col>
                <Col xs={3}>
                        <Form.Label>Платено по банка:</Form.Label>
                        <Form.Control name={"paidByBank"} type={"number"}
                                      defaultValue={zeroToEmptyStringAsNumber(props.item?.paidByBank)} disabled={props.working}/>
                </Col>
            </Row>
            {
                props.working ?
                    <Row className={"mt-4"}>
                        <Col className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    </Row>
                    :
                    <Row className={"mt-4"}>
                        <Col xs={"auto"}>
                        </Col>
                        <Col className={"text-right"}>
                            <Button type={"submit"}>Запази</Button>&nbsp;&nbsp;
                            <Button onClick={done} variant={"secondary"}>Откажи</Button>
                        </Col>
                    </Row>
            }
        </Form>
    </Container>;
}

export default PrepaidDialogEdit

