import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {Account} from "./types";
import {defaultAxiosResponse, FACTORY} from "./factories";
import {VALIDATION} from "./validations";
import {isAuthError, isLoading, isSuccess} from "./common";
import {API_PATHS, buildClientDentalRecordDocumentsPath, PATHS} from "./paths";
import {DailyReport} from "./pages/daily-report/DailyReportPage";
import {ClientRecordItem, RecordType} from "./pages/records/RecordDetailsPage";
import {VoucherType} from "./pages/vouchers/VouchersDialog";
import {PrepaidItemType} from "./pages/prepaid/PrepaidDialog";


export type API_CALL_EVENT = (apiCall: API_CALL) => void;

export interface API_CALL {
    status: NET_STATUS;
    message?: string;
    data: AxiosResponse<any>;
    error: any;
    onChange: API_CALL_EVENT;
}

export enum NET_STATUS {
    'NOT_STARTED',
    'LOADING',
    'SUCCESS',
    'ERROR'
}


export const API_CLASS = class {
    // public site = 'https://vandev.vivisoft-bg.com/';
    // public host = 'https://vandev.vivisoft-bg.com/api/api/';

    public site = '';
    public host = '';
    public clinic = 0;


    // public site = 'http://localhost:3000/';
    // private host = 'http://localhost:8000/';

    private token: string | null = null;
    public user: Account|null = null;
    public department: number = 0;

    constructor(token: string = '') {
        if (token.length > 0) {
            this.token = token;
            // localStorage.setItem('t', this.token);
        } else {
            // this.token = localStorage.getItem('t');

            const search = window.location.search;
            const params = new URLSearchParams(search);
            this.token = params.get('t');
        }
    }

    public getToken(): string|null {
        return this.token;
    }

    public getAPITokenAsQuery (query: string = ""): string {
        let _q = (API.getToken() ? (query.length > 0 ? query + "&t=" : "?t=") + API.getToken() : "");
        return _q;
    }


    private checkForAuthError(apiCall: API_CALL) {
        if (isAuthError(apiCall)) {
            window.location.href = PATHS.login;
        }
    }

    private callOnChangeEvent(apiCall: API_CALL) {
        this.checkForAuthError(apiCall);
        if (apiCall && apiCall.onChange) {
            apiCall.onChange(apiCall);
        }
    }

    private callOnChangeEventWithError(apiCall: API_CALL, err: any) {
        apiCall.status = NET_STATUS.ERROR;
        apiCall.data = defaultAxiosResponse;
        apiCall.error = err;

        this.callOnChangeEvent(apiCall);
    }

    private callOnChangeEventWithSuccess(apiCall: API_CALL, res: any) {
        apiCall.status = NET_STATUS.SUCCESS;
        apiCall.data = res;
        apiCall.error = null;
        this.callOnChangeEvent(apiCall);
    }

    public doGetRequest(onChange: API_CALL_EVENT, url: string, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }

        axios.get(this.host + url, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public doDeleteRequest(onChange: API_CALL_EVENT, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }

        axios.delete(this.host + url, {...requestConfig, data: data})
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public doPostRequest(onChange: any, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }

        try {
        axios.post(this.host + url, data, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
        } catch (e) {
            console.log(e);
        }
    }

    public doPutRequest(onChange: API_CALL_EVENT, url: string, data: any, requestConfig: AxiosRequestConfig = {}) {
        const me = this;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        apiCall.onChange = onChange;
        me.callOnChangeEvent(apiCall);
        if (me.token) {
            requestConfig = {...requestConfig, headers: {...requestConfig.headers, 'Authorization': 'Bearer ' + me.token}};
        }

        axios.put(this.host + url, data, requestConfig)
            .then(res => {
                me.callOnChangeEventWithSuccess(apiCall, res)
            }, err => {
                me.callOnChangeEventWithError(apiCall, err)
            });
    }

    public createFormData(data: any) {
        const fd = new FormData();
        Object.keys(data).forEach(
            k => fd.append(k, data[k])
        );
        return fd;
    }

    uploadFile(url: string, file: any, descriptor: any, onUploadProgress: any) {
        let me = this;
        let formData = new FormData();

        formData.append("file", file);
        formData.append("descriptor", JSON.stringify(descriptor));

        return axios.post(this.host + url, formData, {
            headers: {
                'Authorization': 'Bearer ' + me.token,
                "Content-Type": "multipart/form-data",
            },
            onUploadProgress,
        });
    }





    // ===================================================================================

    public getAllUsersNotSigned(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.allUsersNotSigned, {});
    }

    public getAllAccounts(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.accounts, {});
    }

    public getAccount(onChange: API_CALL_EVENT, accountId: number) {
        this.doGetRequest(onChange, API_PATHS.accounts + '/' + accountId, {});
    }

    public saveAccount(onChange: API_CALL_EVENT, data: any) {
        const fd = this.createFormData(data);
        this.doPostRequest(onChange, API_PATHS.accounts, fd);
    }

    public deleteAccount(onChange: API_CALL_EVENT, accountId: number) {
        this.doDeleteRequest(onChange, API_PATHS.accounts + "/" + accountId, {});
    }

    public getAppModules(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.appModules, {});
    }

    public postUserLogin(onChange: API_CALL_EVENT, email: string, password: string) {
        const me = this;
        const _onChange = onChange;
        const apiCall = FACTORY.createApiCall(NET_STATUS.LOADING);
        if (!VALIDATION.validateLoginData(apiCall, email, password)) {
            this.callOnChangeEvent(apiCall);
            return;
        }

        this.doPostRequest(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) return;

                if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                    me.token = apiCall.data?.data?.token;
                    localStorage.setItem('t', me.token || '');
                } else {
                    console.error(apiCall);
                }

                apiCall.onChange = _onChange;
                me.callOnChangeEvent(apiCall);
            }, API_PATHS.user_login, {email: email, password: password}, {});
    }

    public postUserLoginByKey(onChange: API_CALL_EVENT, key: string) {
        const me = this;
        const _onChange = onChange;

        this.doGetRequest(
            (apiCall: API_CALL) => {
                if (isLoading(apiCall)) return;

                if (isSuccess(apiCall) && apiCall.data.data.status === "ok") {
                    me.token = apiCall.data?.data?.token;
                    localStorage.setItem('t', me.token || '');
                } else {
                }

                apiCall.onChange = _onChange;
                me.callOnChangeEvent(apiCall);
            }, API_PATHS.user_login + '?key=' + key);
    }

    public getUserData(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.user, {});
    }

    public getUserLogout(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.logout, {});
    }


    // MATERIALS

    public getMaterialsGroups(onChange: API_CALL_EVENT, clinic: number) {
        this.doGetRequest(onChange, API_PATHS.materials + '/groups?clinic=' + clinic);
    }

    public getMaterialsItems(onChange: API_CALL_EVENT, groupId?: number, forDate?: string) {
        const params = [];
        if(groupId) params.push("group="+groupId);
        if(forDate) params.push("forDate="+forDate);
        this.doGetRequest(onChange, API_PATHS.materials + '/items' + (params ? `?${params.join('&')}` : ""));
    }

    public orderMaterialsItems(onChange: API_CALL_EVENT, movedId: number, targetId: number) {
        this.doPostRequest(onChange, API_PATHS.materials + '/items/order', {
            movedMaterialId: movedId, targetMaterialId: targetId
        });
    }

    public getMaterialsData(onChange: API_CALL_EVENT, forDate: string) {
        this.doGetRequest(onChange, API_PATHS.materials + '/items/' + forDate);
    }

    public postMaterialsItem(onChange: API_CALL_EVENT, material: any) {
        this.doPostRequest(onChange, API_PATHS.materials + '/items', material);
    }

    public postMaterialsData(onChange: API_CALL_EVENT, forDate: string, data: any) {
        this.doPostRequest(onChange, API_PATHS.materials + '/items/' + forDate, data);
    }

    public deleteMaterialsItem(onChange: API_CALL_EVENT, material: any) {
        this.doDeleteRequest(onChange, API_PATHS.materials + '/items', material);
    }

    // REPORTS
    public getEsteticFinanceReport(onChange: API_CALL_EVENT, clinicIndex: string, fromDate: string, toDate: String) {
        this.doGetRequest(onChange, API_PATHS.reports + '/finance/estetic/' + clinicIndex + '/' + fromDate + '/' + toDate);
    }

    public getDentalFinanceReport(onChange: API_CALL_EVENT, fromDate: string, toDate: String, clinicId: string) {
        this.doGetRequest(onChange, API_PATHS.reports + '/finance/dental/' + fromDate + '/' + toDate + (clinicId ? `?clinicId=${clinicId}` : ""));
    }


    // PROCEDURES

    public getProcedures(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.procedures);
    }

    public postProcedure(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.procedures, data);
    }

    public deleteProcedure(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.procedures, data);
    }

    // INFUSIONS

    public getInfusions(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.infusions);
    }

    public postInfusion(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.infusions, data);
    }

    public deleteInfusion(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.infusions, data);
    }



    // MANIPULATIONS

    public getManipulations(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.manipulations);
    }

    public postManipulation(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.manipulations, data);
    }

    public deleteManipulation(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.manipulations, data);
    }


    // SUPPLIERS

    public getSuppliers(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.suppliers);
    }

    public postSupplier(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.suppliers, data);
    }

    public deleteSupplier(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.suppliers, data);
    }


    // CLINICS

    public getClinics(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.clinics);
    }

    public postClinic(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.clinics, data);
    }

    public deleteClinic(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.clinics, data);
    }



    // CLIENT DENTAL RECORDS

    public getClientDentalRecords(onChange: API_CALL_EVENT, clientId: number) {
        this.doGetRequest(onChange, API_PATHS.clientDentalRecords + `/${clientId}`);
    }

    public postClientDentalRecord(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.clientDentalRecords, data);
    }

    public deleteClientDentalRecord(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.clientDentalRecords + `/${data.id}`, data);
    }


    // CLIENT RECORDS MANIPULATION

    public getClientDentalRecordManipulations(onChange: API_CALL_EVENT, recordId: number) {
        this.doGetRequest(onChange, API_PATHS.clientDentalRecordManipulations + `/${recordId}`);
    }

    public postClientDentalRecordManipulation(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.clientDentalRecordManipulations, data);
    }

    public deleteClientDentalRecordManipulation(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.clientDentalRecordManipulations + `/${data.id}`, data);
    }


    // CLIENT RECORDS DOCUMENTS

    public getClientDentalRecordDocuments(onChange: API_CALL_EVENT, recordId: number) {
        this.doGetRequest(onChange, buildClientDentalRecordDocumentsPath(recordId));
    }

    public deleteClientDentalRecordDocument(onChange: API_CALL_EVENT, recordId: number, documentId: number) {
        this.doDeleteRequest(onChange, buildClientDentalRecordDocumentsPath(recordId) + `/${documentId}`, null);
    }



    // INVOICES

    public getInvoicesReport(onChange: API_CALL_EVENT, year: string, clinica: string, supplierId?: number) {
        this.doGetRequest(onChange, API_PATHS.invoices+'/report/' + year + '?clinica=' + clinica + (supplierId ? '&sid=' + supplierId : ''));
    }

    public getOthersInvoices(onChange: API_CALL_EVENT, clinica: string, sortField: string, sortDirection: string, filter: string) {
        this.doGetRequest(onChange, API_PATHS.invoices+'/other?clinica=' + clinica + '&sortField=' + sortField + '&sortDirection=' + sortDirection + '&filter=' + filter);
    }

    public getCashInvoices(onChange: API_CALL_EVENT, clinica: string, sortField: string, sortDirection: string, filter: string) {
        this.doGetRequest(onChange, API_PATHS.invoices+'/cash?clinica=' + clinica + '&sortField=' + sortField + '&sortDirection=' + sortDirection + '&filter=' + filter);
    }

    public getNotPaidInvoices(onChange: API_CALL_EVENT, clinica: string, sortField: string, sortDirection: string, filter: string) {
        this.doGetRequest(onChange, API_PATHS.invoices+'/notPaid?clinica=' + clinica + '&sortField=' + sortField + '&sortDirection=' + sortDirection + '&filter=' + filter);
    }

    public getOutdatedInvoices(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.invoices+'/outdated');
    }

    public getPaidInvoices(onChange: API_CALL_EVENT, clinica: string, sortField: string, sortDirection: string, filter: string) {
        this.doGetRequest(onChange, API_PATHS.invoices+'/paid?clinica=' + clinica + '&sortField=' + sortField + '&sortDirection=' + sortDirection + '&filter=' + filter);
    }

    public getPayInvoice(onChange: API_CALL_EVENT, id: number, sortField: string, sortDirection: string, filter: string) {
        this.doGetRequest(onChange, API_PATHS.invoices+'/pay/' + id + '?sortField=' + sortField + '&sortDirection=' + sortDirection + '&filter=' + filter);
    }

    public postInvoice(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.invoices, data);
    }

    public deleteInvoice(onChange: API_CALL_EVENT, id: number) {
        this.doDeleteRequest(onChange, API_PATHS.invoices + '/' + id, null);
    }



    // MACHINES

    public getMachines(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.machines);
    }

    public postMachine(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.machines, data);
    }

    public deleteMachine(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.machines, data);
    }



    // CLIENTS

    public getClients(onChange: API_CALL_EVENT) {
        this.doGetRequest(onChange, API_PATHS.clients);
    }

    public postClient(onChange: API_CALL_EVENT, data: any) {
        this.doPostRequest(onChange, API_PATHS.clients, data);
    }

    public deleteClient(onChange: API_CALL_EVENT, data: any) {
        this.doDeleteRequest(onChange, API_PATHS.clients, data);
    }


    // CLIENT RECORDS

    public getClientRecords(onChange: API_CALL_EVENT, clientId: number) {
        this.doGetRequest(onChange, API_PATHS.clientRecords + '/' + clientId);
    }

    public postClientRecord(onChange: API_CALL_EVENT, data: ClientRecordItem) {
        this.doPostRequest(onChange, API_PATHS.clientRecords, data);
    }

    public deleteClientRecord(onChange: API_CALL_EVENT, recordId: number) {
        this.doDeleteRequest(onChange, API_PATHS.clientRecords + '/' + recordId, null);
    }
    // DAILY REPORT

    public getDailyReport(onChange: API_CALL_EVENT, forDate: string) {
        this.doGetRequest(onChange, API_PATHS.reports + '/' + forDate);
    }

    public postDailyReport(onChange: API_CALL_EVENT, report: DailyReport) {
        this.doPostRequest(onChange, API_PATHS.reports, report);
    }

    public deleteDailyReport(onChange: API_CALL_EVENT, report: DailyReport) {
        this.doDeleteRequest(onChange, API_PATHS.reports, report);
    }


    // VOUCHERS

    public loadVouchers(onChange: API_CALL_EVENT, type: "VOUCHER" | "PACKET", recordType?: RecordType, recordItemId?: number) {
        let query = "";
        if(recordType || recordItemId) {
            query = "?";
            if (recordType) query += `recordType=${recordType}&`;
            if (recordItemId) query += `recordItemId=${recordItemId}&`;
        }

        this.doGetRequest(onChange, API_PATHS.vouchers + "/" + type + query);
    }

    public createVoucher(onChange: API_CALL_EVENT, type: "VOUCHER" | "PACKET") {
        this.doPutRequest(onChange, API_PATHS.vouchers + "/" + type, null);
    }

    public saveVoucher(onChange: API_CALL_EVENT, voucher: VoucherType) {
        this.doPostRequest(onChange, API_PATHS.vouchers, voucher);
    }

    public deleteVoucher(onChange: API_CALL_EVENT, voucher: VoucherType) {
        this.doDeleteRequest(onChange, API_PATHS.vouchers, voucher);
    }



    // PREPAID


    public saveClientPrepaid(onChange: API_CALL_EVENT, item: PrepaidItemType) {
        this.doPostRequest(onChange, API_PATHS.prepaid, item);
    }

    public getClientPrepaid(onChange: API_CALL_EVENT, clientId: number) {
        this.doGetRequest(onChange, `${API_PATHS.prepaid}/${clientId}`);
    }

    public deleteClientPrepaid(onChange: API_CALL_EVENT, item: PrepaidItemType) {
        this.doDeleteRequest(onChange, API_PATHS.prepaid, item);
    }
}

export const API = new API_CLASS();
