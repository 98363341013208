import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    Button,
    Card,
    Col,
    Container, Form, InputGroup,
    Row,
    Spinner,
    Tab,
    Table,
    Tabs,
} from "react-bootstrap";
import {
    faCancel,
    faEdit,
    faFileInvoice,
    faMoneyBillWave,
    faPlus, faSearch,
} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {API, API_CALL} from "../../api";
import {MONTHS_NAMES} from "../../types";
import {
    isError,
    isLoading,
    isSuccess, numberWithCommas,
    showErrorsInToast, showMsgInToast,
    translateError,
} from "../../common";
import ConfigSuppliersForm, {SupplierItem} from "../config/suppliers/ConfigSuppliersForm";
import {DataPagination} from "../../common/DataPagination";
import InvoiceDetails from "./InvoiceDetails";
import {EventKey} from "react-bootstrap/types";
import "./invoices.css";
import moment from "moment";
import {InvoicesReportContent, InvoicesReportItem} from "./InvoicesReportContent";
import {ConfirmModal} from "../../common/ConfirmModal";

export interface InvoiceItem {
    id: number | null;
    clinica: "0" | "1";
    invType: "BANK" | "CASH" | "OTHER";
    supplier: SupplierItem | null;
    amount: number;
    currency: "BGN" | "EUR";
    invNumber: string;
    description: string;
    invDate: Date;
    isPaid: boolean;
    alertDate: Date;
    updated_at: string;
}

export function viewModeToInvType(invType: EventKey | undefined) {
    switch (invType) {
        case "NOT_PAID":
        case "ARCHIVE" :
            return "BANK";
        case "CASH" :
            return "CASH";
        default :
            return "OTHER";
    }
}

export const EMPTY_INVOICE_ITEM: InvoiceItem = {
    id: null,
    clinica: '0',
    invType: "BANK",
    supplier: null,
    amount: 0,
    currency: "BGN",
    invNumber: '',
    invDate: new Date(),
    isPaid: false,
    alertDate: new Date(),
    updated_at: '',
    description: '',
}

export function InvoicesPage() {
    const [working, setWorking] = useState(false);
    const [activeClinic, setActiveClinic] = useState(0);
    const [viewMode, setViewMode] = useState<string | undefined | EventKey>('NOT_PAID');
    const [invoices, setInvoices] = useState<Array<InvoiceItem>>([]);
    const [invoicesTotal, setInvoicesTotal] = useState(0);
    const [editInvoice, setEditInvoice] = useState<InvoiceItem | null>(null);
    const [report, setReport] = useState<Array<InvoicesReportItem>>([]);
    const [pageData, setPageData] = useState<any>(null);
    const [reportYear, setReportYear] = useState('2023');
    const [supplier, setSupplier] = useState<SupplierItem | null>(null);
    const [selectSupplier, setSelectSupplier] = useState(false);
    const [payFlagId, setPayFlagId] = useState<number | undefined>(undefined);
    const [ddsMode, setDdsMode] = useState<string>("withDDS");
    const [filter, setFilter] = useState<string>("");
    const [sortField, setSortField] = useState<string>("invDate");
    const [sortDirection, setSortDirection] = useState<string>("asc");

    function loadData() {
        setInvoices([]);
        loadInvoices(null);
    }

    useEffect(() => {
        loadData();
    }, [activeClinic, viewMode, reportYear, supplier]);

    useEffect(() => {
        let _invoicesTotal = 0;
        if (invoices && invoices.length > 0) {
            invoices.forEach(
                i => _invoicesTotal += (i.amount * (i.currency === "EUR" ? 1.95583 : 1)) || 0
            )
        }
        setInvoicesTotal(_invoicesTotal);
    }, [invoices]);


    function loadInvoices(url: string | undefined | null) {
        if (url) {
            url = url.replace(API.host, '');
            if (url!.indexOf('?') > 0) url += '&clinica=' + activeClinic;
            else url += '?clinica=' + activeClinic;
            url += '&sortField=' + sortField + '&sortDirection=' + sortDirection + '&filter=' + filter;

            API.doGetRequest(
                (api_call: API_CALL) => {
                    setWorking(isLoading(api_call));
                    if (isSuccess(api_call)) {
                        setInvoices([...api_call.data.data.invoices.data]);
                        setPageData({...api_call.data.data.invoices, data: null})
                    }
                    if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на фактурите!', translateError)
                }, url
            );
            return;
        }

        switch (viewMode) {
            case "NOT_PAID" :
                API.getNotPaidInvoices(
                    (api_call: API_CALL) => {
                        setWorking(isLoading(api_call));
                        if (isSuccess(api_call)) {
                            setInvoices([...api_call.data.data.invoices.data]);
                            setPageData({...api_call.data.data.invoices, data: null})
                        }
                        if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на фактурите!', translateError)
                    }, activeClinic.toString(), sortField, sortDirection, filter
                );
                break;

            case "ARCHIVE" :
                API.getPaidInvoices(
                    (api_call: API_CALL) => {
                        setWorking(isLoading(api_call));
                        if (isSuccess(api_call)) {
                            setInvoices([...api_call.data.data.invoices.data]);
                            setPageData({...api_call.data.data.invoices, data: null})
                        }
                        if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на фактурите!', translateError)
                    }, activeClinic.toString(), sortField, sortDirection, filter
                );
                break;

            case "CASH" :
                API.getCashInvoices(
                    (api_call: API_CALL) => {
                        setWorking(isLoading(api_call));
                        if (isSuccess(api_call)) {
                            setInvoices([...api_call.data.data.invoices.data]);
                            setPageData({...api_call.data.data.invoices, data: null})
                        }
                        if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на фактурите!', translateError)
                    }, activeClinic.toString(), sortField, sortDirection, filter
                );
                break;

            case "OTHER" :
                API.getOthersInvoices(
                    (api_call: API_CALL) => {
                        setWorking(isLoading(api_call));
                        if (isSuccess(api_call)) {
                            setInvoices([...api_call.data.data.invoices.data]);
                            setPageData({...api_call.data.data.invoices, data: null})
                        }
                        if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на фактурите!', translateError)
                    }, activeClinic.toString(), sortField, sortDirection, filter
                );
                break;

            case "REPORT" :
                API.getInvoicesReport(
                    (api_call: API_CALL) => {
                        setWorking(isLoading(api_call));
                        if (isSuccess(api_call)) {
                            setReport([...api_call.data.data.report]);
                        }
                        if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на отчета!', translateError)
                    }, reportYear, activeClinic.toString(), supplier?.id
                );
                break;
        }

    }

    function payInvoice(id: number | null) {
        if (!id) return;

        API.getPayInvoice(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    loadInvoices(null);
                    showMsgInToast("Фактурата е маркирана като платена.");
                }
                if (isError(api_call)) {
                    showErrorsInToast(api_call, 'Неуспешно маркиране на фактурата като платена', translateError)
                }
            }, id, sortField, sortDirection, filter
        );
    } // payInvoice

    function getColor(inv: InvoiceItem) {
        if (viewMode !== "NOT_PAID") return "none";

        const now = moment().startOf("day");
        const ad = moment(inv.alertDate, 'DD.MM.YYYY');

        if (ad.isValid() && now.diff(ad, "days") > -1) return 'invoice-red';
        if (ad.isValid() && now.diff(ad, "days") > -6) return 'invoice-yellow';

        return 'none';
    }

    const tableHeader: Array<any> = [];
    if (viewMode == "REPORT") {
        tableHeader.push([<th className={"text-right"}></th>,
            <th className={"w-120px text-right"}>ГОДИШНО</th>,
            MONTHS_NAMES.map(m => <th className={"w-120px text-right"}>{m.toUpperCase()}</th>)]);
    } else {
        if (["NOT_PAID", "CASH", "OTHER"].some(i => i === (viewMode?.toString() || ''))) {
            tableHeader.push(<th></th>);
        }
        if (viewMode != "NOT_PAID") {
            tableHeader.push(<th>{viewMode != "NOT_PAID" && <span className={"text-nowrap"}>Платена на</span>}</th>);
        }
        if (viewMode == "OTHER") {
            tableHeader.push(<th>Описание на разхода</th>);
        } else {
            tableHeader.push(<th>Доставчик</th>);
            tableHeader.push(<th>IBAN</th>);
            tableHeader.push(<th>Номер</th>);
            tableHeader.push(<th>Дата</th>);
        }
        if (viewMode == "NOT_PAID") {
            tableHeader.push(<th><span className={"text-nowrap"}>Срок</span></th>);
        }
        tableHeader.push(<th className={"text-right"}>Сума</th>);
    }

    return (
        <>
            <Container fluid>
                <Card className={"border-0"}>
                    <Card.Header style={{paddingBottom: 0}}>
                        <Row className={"pt-2 pb-0"}>
                            <Col>
                                <h5 className={"text-uppercase font-weight-bold"}>
                                    <FontAwesomeIcon icon={faFileInvoice} className={"mr-3"}/> Фактуриране
                                </h5>
                            </Col>
                            <Col xs={"auto"} style={{lineHeight: "2.25rem"}}>
                            </Col>
                        </Row>

                        <Tabs activeKey={activeClinic} className={"d-print-none mt-2 mb-0 pb-0"}
                              onSelect={eventKey => setActiveClinic(parseInt(eventKey || "0"))}>
                            <Tab eventKey={0} title={"Естетична клиника"}/>
                            <Tab eventKey={1} title={"Дентална клиника"}/>
                        </Tabs>

                    </Card.Header>

                    <Card.Body className={"max-h-60vh scrollable"}>

                        <Row>
                            <Col className={"pb-4 pl-4"}>
                                <Tabs activeKey={viewMode} variant={"pills"}
                                      onSelect={eventKey => setViewMode(eventKey || undefined)}>
                                    <Tab eventKey={"NOT_PAID"} title={"Неплатени фактури банка"}/>
                                    <Tab eventKey={"ARCHIVE"} title={"Платени фактури банка"}/>
                                    <Tab eventKey={"CASH"} title={"Фактури в брой"}/>
                                    <Tab eventKey={"OTHER"} title={"Разходи без фактура"}/>
                                    <Tab eventKey={"REPORT"} title={"Справка по месеци"}/>
                                </Tabs>
                            </Col>
                        </Row>
                        {
                            viewMode == "REPORT" &&
                            <Form className={"mb-3"}>
                                <Row>
                                    <Col xs={"auto"}>
                                        <InputGroup>
                                            <InputGroup.Text>Година:&nbsp;&nbsp;</InputGroup.Text>
                                            <Form.Group className={"mb-0 w-100px"}>
                                                <Form.Control as={"select"} value={reportYear}
                                                              className="text-center w-100px d-inline-block"
                                                              onChange={e => setReportYear(e.target.value)}>
                                                    <option value={"2023"}>2023</option>
                                                    <option value={"2022"}>2022</option>
                                                    <option value={"2021"}>2021</option>
                                                </Form.Control>
                                            </Form.Group>
                                        </InputGroup>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-0">
                                            <Row>
                                                <Col xs={"auto"}>
                                                    <InputGroup>
                                                        <InputGroup.Text>Издадена от:&nbsp;&nbsp;</InputGroup.Text>
                                                        <Form.Control className={"w-480px d-inline-block"}
                                                                      value={supplier?.name || ''}
                                                                      placeholder={"Изберете доставчик..."}
                                                                      onClick={() => setSelectSupplier(true)}
                                                        />
                                                        <InputGroup.Prepend>
                                                            <Button variant={"danger"} size={"sm"}
                                                                    onClick={() => setSupplier(null)}
                                                            >
                                                                <FontAwesomeIcon icon={faCancel}/>
                                                            </Button>
                                                        </InputGroup.Prepend>
                                                    </InputGroup>
                                                </Col>
                                            </Row>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Form>
                        }

                        {
                            viewMode != "REPORT" && <Row>
                                <Col xs={3}>
                                    <Form.Group className="mb-0">
                                        <Row>
                                            <Col xs={"auto"} className={"lh-2-5em"}>
                                                <Form.Label>
                                                    Филтър:
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    value={filter}
                                                    onChange={e => setFilter(e.target.value)}/>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className="mb-0">
                                        <Row>
                                            <Col xs={"auto"} className={"lh-2-5em"}>
                                                <Form.Label>
                                                    Подреди по:
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control as={"select"} value={sortField}
                                                              onChange={e => setSortField(e.target.value)}>
                                                    <option value={""}>---</option>
                                                    <option value={"supplierId"}>Доставчик</option>
                                                    <option value={"invNumber"}>Номер</option>
                                                    <option value={"invDate"}>Дата</option>
                                                    <option value={"alertDate"}>Срок</option>
                                                    <option value={"amount"}>Сума</option>
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col xs={3}>
                                    <Form.Group className="mb-0">
                                        <Row>
                                            <Col xs={"auto"} className={"lh-2-5em"}>
                                                <Form.Label>
                                                    Посока:
                                                </Form.Label>
                                            </Col>
                                            <Col>
                                                <Form.Control as={"select"} value={sortDirection}
                                                              onChange={e => setSortDirection(e.target.value)}>
                                                    <option value={""}>---</option>
                                                    <option value={"asc"}>Възходящо</option>
                                                    <option value={"desc"}>Низходящо</option>
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Button variant={"primary"} onClick={() => loadData()}>
                                        <FontAwesomeIcon icon={faSearch}/>&nbsp;Търси
                                    </Button>
                                </Col>
                            </Row>
                        }

                        <Table bordered size={"sm"} hover>
                            <thead>
                            {
                                viewMode == "REPORT" &&
                                <tr className={"bg-secondary-light"}>
                                    <th colSpan={14}>
                                        <Row>
                                            <Col className={"ml-2 pb-2"}>
                                                <Tabs activeKey={ddsMode}
                                                      onSelect={e => setDdsMode(e?.toString() || 'withDDS')}>
                                                    <Tab eventKey={"withDDS"} title={"С ДДС"}/>
                                                    <Tab eventKey={"withoutDDS"} title={"Без ДДС"}/>
                                                    <Tab eventKey={"onlyDDS"} title={"Само ДДС"}/>
                                                </Tabs>
                                            </Col>
                                        </Row>
                                    </th>
                                </tr>
                            }
                            <tr className={"bg-secondary text-light"}>
                                {tableHeader}
                            </tr>
                            </thead>
                            <tbody>
                            {
                                viewMode == "REPORT" ?
                                    <InvoicesReportContent report={report} ddsMode={ddsMode}/>
                                    :
                                    <>
                                        {
                                            invoices.length === 0 &&
                                            <tr>
                                                <td colSpan={tableHeader.length}>
                                                    <strong>
                                                        {
                                                            working ? "Моля, изчакайте. Зареждам данните ..." : "Списъка е празен."
                                                        }
                                                    </strong>
                                                </td>
                                            </tr>
                                        }
                                        {
                                            invoices.length > 0 &&
                                            <>
                                                {
                                                    invoices.map(
                                                        inv =>
                                                            <tr key={inv.id} className={getColor(inv)}>
                                                                {
                                                                    ["NOT_PAID", "CASH", "OTHER"].some(i => i === (viewMode?.toString() || '')) &&
                                                                    <td style={{
                                                                        width: "100px",
                                                                        textAlign: "center"
                                                                    }}>
                                                                        <Button
                                                                            variant={"outline-secondary"}
                                                                            size={"sm"}
                                                                            onClick={() => {
                                                                                setEditInvoice(inv)
                                                                            }}
                                                                        >
                                                                            <FontAwesomeIcon icon={faEdit}/>
                                                                        </Button>&nbsp;
                                                                        {
                                                                            viewMode === "NOT_PAID" &&
                                                                            <Button
                                                                                variant={"outline-primary"}
                                                                                size={"sm"}
                                                                                title={"Маркирай фактурата като платена."}
                                                                                onClick={() => {
                                                                                    setPayFlagId(inv.id || undefined);
                                                                                }}
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={faMoneyBillWave}/>
                                                                            </Button>
                                                                        }
                                                                    </td>
                                                                }
                                                                {
                                                                    viewMode !== "NOT_PAID" &&
                                                                    <td style={{
                                                                        width: "80px",
                                                                        textAlign: "center"
                                                                    }}>
                                                                        {inv.invDate}
                                                                    </td>
                                                                }
                                                                {
                                                                    viewMode === "OTHER" &&
                                                                    <td> {inv.description} </td>
                                                                }
                                                                {
                                                                    viewMode !== "OTHER" &&
                                                                    <>
                                                                        <td> {inv.supplier?.name} </td>
                                                                        <td> {inv.supplier?.iban} </td>
                                                                        <td> {inv.invNumber} </td>
                                                                        <td> {inv.invDate} </td>
                                                                    </>
                                                                }
                                                                {
                                                                    viewMode === "NOT_PAID" &&
                                                                    <td>{inv.alertDate}</td>
                                                                }
                                                                <td className={"text-right text-nowrap"}> {inv.amount} {inv.currency} </td>
                                                            </tr>
                                                    )
                                                }

                                                <tr>
                                                    <td colSpan={tableHeader.length}
                                                        className={"font-weight-bold text-right"}>
                                                        Всичко за
                                                        страницата:&nbsp;&nbsp;{numberWithCommas(invoicesTotal.toFixed(2))} лв.&nbsp;
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={tableHeader.length}>
                                                        <DataPagination paginationData={pageData}
                                                                        onAction={loadInvoices}/>
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                    </>
                            }
                            </tbody>
                        </Table>

                    </Card.Body>
                    <Card.Footer>
                        {
                            working ? <Spinner animation="border"/> :
                                <>
                                    {
                                        viewMode != 'REPORT' &&
                                        <Button
                                            onClick={() => {
                                                setEditInvoice({
                                                    ...EMPTY_INVOICE_ITEM,
                                                    clinica: (activeClinic === 0 ? '0' : '1'),
                                                    invType: viewModeToInvType(viewMode)
                                                })
                                            }}
                                        ><FontAwesomeIcon
                                            icon={faPlus}/> Добави нова фактура/разход</Button>
                                    }
                                </>
                        }
                    </Card.Footer>
                </Card>
            </Container>

            {
                editInvoice &&
                <InvoiceDetails item={editInvoice} onChange={() => {
                    setEditInvoice(null);
                    loadInvoices(pageData ? pageData.path + '?page=' + pageData.current_page.toString() : null);
                }} onCancel={() => setEditInvoice(null)}/>
            }

            {
                selectSupplier && <ConfigSuppliersForm
                    onSelect={(s) => {
                        setSupplier(s);
                        setSelectSupplier(false)
                    }}
                    onClose={() => setSelectSupplier(false)} zIndex={1600}/>
            }

            {
                payFlagId && <ConfirmModal title={"Плащане на фактура"}
                                           onClose={() => setPayFlagId(undefined)}
                                           message={"Моля, потвърдете плащането на фактурата."}
                                           buttons={["Плати", "Откажи"]}
                                           primaryBtn={"Плати"}
                                           onButtonClick={(btn) => {
                                               setPayFlagId(undefined);
                                               if (btn === "Плати") {
                                                   payInvoice(payFlagId);
                                               }
                                           }}
                />
            }
        </>
    )
}
