import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Container, Dropdown, Row,} from "react-bootstrap";
import {faChartLine, faEye, faPrint} from "@fortawesome/free-solid-svg-icons";
import React, {useMemo, useState} from "react";
import {
    dateToString,
} from "../../common";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import EsteticReportsPage from "./EsteticReportsPage";
import DentalReportsPage from "./DentalReportsPage";


export function ReportsPage({mode}: { mode: "estetic" | "dental" }) {
    const [printMode, setPrintMode] = useState("");
    const [fromDate, setFromDate] = useState<Date>(new Date());
    const [toDate, setToDate] = useState<Date>(new Date());
    const [recalc, setRecalc] = useState(true);

    const report = useMemo(() => {
        setRecalc(false);
        if (mode === "estetic") return <EsteticReportsPage fromDate={fromDate} toDate={toDate}/>;
        if (mode === "dental") return <DentalReportsPage fromDate={fromDate} toDate={toDate} printMode={printMode}/>;
    }, [mode, recalc, printMode]);

    function reloadData() {
        setRecalc(true);
    }

    return (
        <>
            <Container fluid>
                <Card className={"border-0"}>
                    <Card.Header>
                        <Row className={"pt-2 pb-0"}>
                            <Col>
                                <h5 className={"text-uppercase font-weight-bold"}>
                                    <FontAwesomeIcon icon={faChartLine} className={"mr-3"}/>Отчети
                                </h5>
                            </Col>
                            <Col xs={"auto"} style={{lineHeight: "2.25rem"}}>
                                <Row>
                                    <Col xs={"auto"} className={"m-0 p-0"}>
                                        От дата:&nbsp;
                                    </Col>
                                    <Col xs={"auto"} className={"m-0 p-0"}>
                                        <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                            setFromDate(date)
                                        }} value={dateToString(fromDate)}/>
                                    </Col>
                                    <Col xs={"auto"} className={"m-0 p-0"}>
                                        &nbsp;До дата:&nbsp;
                                    </Col>
                                    <Col xs={"auto"} className={"m-0 p-0"}>
                                        <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                            setToDate(date)
                                        }} value={dateToString(toDate)}/>
                                    </Col>
                                    <Col xs={"auto"} className={"m-0 p-0"} style={{lineHeight: "2.25rem"}}>
                                        <Button onClick={() => reloadData()} variant={"primary"}
                                                title={"Изчисли справката"}>
                                            <FontAwesomeIcon icon={faChartLine}/>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            {
                                mode === "dental" &&
                                <Col xs={"auto"} style={{lineHeight: "2.25rem"}} className={"d-print-none"}>
                                    <Dropdown>
                                        <Dropdown.Toggle variant="secondary">
                                            <FontAwesomeIcon icon={faEye}/>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => setPrintMode("short")}>Съкратена
                                                справка</Dropdown.Item>
                                            <Dropdown.Item onClick={() => setPrintMode("")}>Пълна
                                                справка</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Col>
                            }
                            <Col xs={"auto"} style={{lineHeight: "2.25rem"}} className={"d-print-none"}>
                                <Button onClick={() => window.print()} variant={"secondary"} title={"Отпечатай екрана"}>
                                    <FontAwesomeIcon icon={faPrint}/>
                                </Button>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className={"max-h-60vh scrollable"}>
                        {report}
                    </Card.Body>
                    <Card.Footer>
                    </Card.Footer>
                </Card>
            </Container>
        </>
    )
}
