import {
    Button, CloseButton,
    Col,
    Form, InputGroup,
    Modal,
    Row, Spinner,
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import SelectUserComponent from "../config/users/SelectUserComponent";
import ConfigProceduresForm from "../config/procedures/ConfigProceduresForm";
import {
    dateToString,
    onlyPozitiveText,
    showErrorsListInToast, showMsgInToast, textToFloatOrZero, zeroToEmptyStringAsNumber,
} from "../../common";
import {ClientItem} from "../config/clients/ConfigClientsForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faList, faSave, faUser} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import {Account} from "../../types";
import {StringInput} from "../../common/StringInput";
import {
    saveClientRecordAsync,
    selectMessage,
    selectRecordsForDate,
    selectStatus,
    setStatus
} from "../../features/RecordsSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import ConfigMaterialsForm from "../config/materials/ConfigMaterialsForm";
import ConfigInfusionsForm from "../config/infusions/ConfigInfusionsForm";
import VouchersDialog, {VoucherType} from "../vouchers/VouchersDialog";
import {CacheInput} from "../../common/CacheInput";

export type RecordType = 'процедура' | 'филър' | 'вливка' | 'бележка';
export type PayStatus = 'неплатено' | 'платено';

export interface ClientRecordItem {
    id: number;
    clinicIndex: number;
    clientId: number;
    user?: Account;
    recordType: RecordType;
    recordDate: Date;
    itemId?: number;
    itemName?: string;
    itemQty: number;
    itemExpense: number;
    itemSellPrice: number;
    machineId?: number;
    machineName?: string;
    expense: number;
    sellPrice: number;
    payStatus: PayStatus;
    paidByCard: number;
    paidByCash: number;
    paidByVoucher: number;
    paidByPacket: number;
    paid: number;
    voucherId?: number;
    voucher?: VoucherType;
    packetId?: number;
    packet?: VoucherType;
    discount: number;
    description?: string;
}

export function generateEmptyClientRecordItem(rType: RecordType, discount?: number, user?: Account): ClientRecordItem {
    return {
        id: -1,
        clinicIndex: -1,
        clientId: -1,
        recordType: rType,
        recordDate: moment().toDate(),
        user: user,
        itemQty: 0,
        itemExpense: 0,
        itemSellPrice: 0,
        expense: 0,
        sellPrice: 0,
        payStatus: 'неплатено',
        discount: discount || 0,
        paidByCard: 0,
        paidByCash: 0,
        paidByPacket: 0,
        paidByVoucher: 0,
        paid: 0
    };
}

export interface SelectedProcedureItem {
    itemId?: number;
    itemName?: string;
    itemExpense: number;
    itemSellPrice: number;
    machineId?: number;
    machineName?: string;
}

export const EMPTY_SELECTED_PROCEDURE_ITEM: SelectedProcedureItem = {
    itemExpense: 0, itemSellPrice: 0
}

export interface RecordDetailsPageProps {
    zIndex?: number;
    clinicIndex: number;
    client: ClientItem;
    record: ClientRecordItem;
    onClose: (needReload: boolean) => any;
}

export default function RecordDetailsPage({clinicIndex, client, record, onClose}: RecordDetailsPageProps) {
    const [editRecord, setEditRecord] = useState<ClientRecordItem>({...record});
    const [recordDate, setRecordDate] = useState<Date>(record.recordDate || new Date());
    const [doctor, setDoctor] = useState<Account | null>(record.user || null);

    const [selectDoctorFlag, setSelectDoctorFlag] = useState(false);
    const [selectItemFlag, setSelectItemFlag] = useState(false);
    const [selectVoucherFlag, setSelectVoucherFlag] = useState(false);
    const [selectPacketFlag, setSelectPacketFlag] = useState(false);

    const dispatch = useAppDispatch();
    const status = useAppSelector(selectStatus);
    const message = useAppSelector(selectMessage);
    const dayRecords = useAppSelector(selectRecordsForDate(client.id, recordDate));

    useEffect(() => {
        if (status == "SUCCESS") {
            showMsgInToast(message);
            dispatch(setStatus("PENDING"));
            onClose(true);
        }
        if (status == "FAILED") {
            showErrorsListInToast("Възникна грешка", [message]);
            dispatch(setStatus("PENDING"));
        }
    }, [status]);

    useEffect(() => {
        if (editRecord) {
            let itemQty = editRecord.itemQty === 0 ? 1 : editRecord.itemQty;
            let expense = 1 * editRecord.itemExpense;
            let discount = editRecord.discount;
            discount = discount < 0 ? 0 : (discount > 100 ? 100 : discount);
            let sellPrice = itemQty * editRecord.itemSellPrice;
            sellPrice -= (sellPrice * discount) / 100;

            let paidByCash = editRecord.paidByCash * 1;
            if (isNaN(paidByCash)) paidByCash = 0;
            let paidByCard = editRecord.paidByCard * 1;
            if (isNaN(paidByCard)) paidByCard = 0;
            let paidByVoucher = editRecord.paidByVoucher * 1;
            if (isNaN(paidByVoucher)) paidByVoucher = 0;
            let paidByPacket = editRecord.paidByPacket * 1;
            if (isNaN(paidByPacket)) paidByPacket = 0;

            let paid = paidByCard + paidByCash + paidByPacket + paidByVoucher;

            if (itemQty !== editRecord.itemQty || sellPrice !== editRecord.sellPrice || expense !== editRecord.expense
                || paid !== editRecord.paid || discount !== editRecord.discount) {
                setEditRecord({
                    ...editRecord, itemQty: itemQty, sellPrice: sellPrice, expense: expense,
                    paid: paid, discount: discount
                });
            }
        }
    }, [editRecord.itemQty, editRecord.itemSellPrice, editRecord.itemId, editRecord.discount]);

    useEffect(() => {
        setEditRecord({...editRecord, itemExpense: editRecord.expense});
    }, [editRecord.expense]);

    useEffect(() => {
        setRecordDate(editRecord.recordDate);
    }, [editRecord.recordDate])

    function saveRecord(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];

        const record = {...editRecord};
        record.user = doctor || undefined;
        record.clientId = client.id;
        record.clinicIndex = clinicIndex;

        if (record.paidByVoucher <= 0) {
            record.voucher = undefined;
        }

        if (record.paidByPacket <= 0) {
            record.packet = undefined;
        }

        if (errors.length === 0) {
            dispatch(saveClientRecordAsync(record));
        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    function cancelRecord() {
        onClose(false);
    }

    function onSelectRecordItem(p: SelectedProcedureItem) {
        if (p.itemId !== editRecord.itemId) {
            if (editRecord.itemQty === 0) editRecord.itemQty = 1;

            let voucher = undefined;
            let packet = undefined;
            let paidByVoucher = 0;
            let paidByPacket = 0;

            if (p.itemId === record.itemId) {
                voucher = record.voucher;
                packet = record.packet;
                paidByPacket = record.paidByPacket;
                paidByVoucher = record.paidByVoucher;
            }

            setEditRecord({
                ...editRecord, ...p, voucher: voucher, paidByVoucher: paidByVoucher, packet: packet, paidByPacket: paidByPacket
            });
        }

        setSelectItemFlag(false)
    }

    function isPriseDisabled() {
        return !editRecord.itemId || (editRecord.packet !== undefined && editRecord.packet !== null);
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: 1500}}/>
            <Modal show={true} size={"lg"} style={{zIndex: 1501}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faUser} className={"mr-3"}/>Пациентско досие -
                        <span
                            className={"small"}>{editRecord.id === -1 ? " добавяне" : " корекция"} на {editRecord.recordType}</span>
                        <br/>
                        <span className={"small text-primary"}>{client.name}</span>
                    </Modal.Title>
                    <CloseButton onClick={cancelRecord}/>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col>
                            <Form onSubmit={saveRecord}>

                                <Row>
                                    <Col xs={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Дата</Form.Label>
                                            <ReadOnlyDatePickerComponent
                                                onChange={(d: Date) => setEditRecord({...editRecord, recordDate: d})}
                                                value={dateToString(editRecord.recordDate)}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={"font-weight-bold"}>Терапевт:</Form.Label>
                                            <InputGroup className="mb-3">
                                                <Form.Control value={doctor?.name} disabled={true}/>
                                                <Button variant="outline-secondary"
                                                        onClick={() => setSelectDoctorFlag(true)}>
                                                    <FontAwesomeIcon icon={faList}/>
                                                </Button>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>
                                        {editRecord.recordType[0].toUpperCase() + editRecord.recordType.substr(1)}
                                    </Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control value={editRecord.itemName} disabled={true}/>
                                        <Button variant="outline-secondary" onClick={() => setSelectItemFlag(true)}>
                                            <FontAwesomeIcon icon={faList}/>
                                        </Button>
                                    </InputGroup>
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={"font-weight-bold"}>-</Form.Label>
                                            <StringInput
                                                value={editRecord.expense.toString()}
                                                disabled={!editRecord.itemId}
                                                onChange={e => {
                                                    setEditRecord({
                                                        ...editRecord,
                                                        expense: parseInt(onlyPozitiveText(e) || '0')
                                                    })
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={"font-weight-bold"}>Продажна цена</Form.Label>
                                            <StringInput
                                                value={editRecord.itemSellPrice.toString()}
                                                disabled={isPriseDisabled()}
                                                onChange={e => {
                                                    setEditRecord({
                                                        ...editRecord,
                                                        itemSellPrice: parseFloat(onlyPozitiveText(e) || '1')
                                                    })
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={"font-weight-bold"}>Брой</Form.Label>
                                            <StringInput
                                                value={editRecord.itemQty.toString()}
                                                disabled={isPriseDisabled()}
                                                onChange={e => {
                                                    setEditRecord({
                                                        ...editRecord,
                                                        itemQty: parseFloat(onlyPozitiveText(e) || '1')
                                                    })
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={"font-weight-bold"}>% отстъпка</Form.Label>
                                            <StringInput
                                                value={editRecord.discount.toString()}
                                                disabled={isPriseDisabled()}
                                                onChange={e => {
                                                    setEditRecord({
                                                        ...editRecord,
                                                        discount: parseFloat(onlyPozitiveText(e) || '1')
                                                    })
                                                }}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label className={"font-weight-bold"}>Цена</Form.Label>
                                            <Form.Control value={Number(editRecord.sellPrice).toFixed(2)}
                                                          disabled={true}/>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Бележки</Form.Label>
                                            <Form.Control as={"textarea"} value={editRecord.description}
                                                          rows={2} onChange={e => setEditRecord({
                                                ...editRecord,
                                                description: e.target.value
                                            })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row className={"border bg-secondary-light"}>
                                    <Col>
                                        <Row>
                                            <Col className={"pt-2"}><h4>Плащане за деня</h4></Col>
                                            <Col className={"text-right pt-2"}>
                                                <Button variant={"outline-secondary"} size={"sm"}
                                                        onClick={() => {
                                                            setEditRecord({
                                                                ...editRecord,
                                                                voucher: undefined,
                                                                paidByVoucher: 0,
                                                                packet: undefined,
                                                                paidByPacket: 0,
                                                                paidByCash: 0,
                                                                paidByCard: 0,
                                                            });
                                                        }}>
                                                    <FontAwesomeIcon icon={faCancel}/> Изчисти всичко
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-2">
                                                    <Form.Label className={"font-weight-bold"}>В брой</Form.Label>
                                                    <StringInput
                                                        value={Number(editRecord.paidByCash).toFixed(2)}
                                                        disabled={!!editRecord.packet || !editRecord.itemId}
                                                        onChange={e => {
                                                            setEditRecord({
                                                                ...editRecord,
                                                                paidByCash: parseFloat(onlyPozitiveText(e) || '0')
                                                            })
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-2">
                                                    <Form.Label className={"font-weight-bold"}>С карта</Form.Label>
                                                    <StringInput
                                                        value={Number(editRecord.paidByCard).toFixed(2)}
                                                        disabled={!!editRecord.packet || !editRecord.itemId}
                                                        onChange={e => {
                                                            setEditRecord({
                                                                ...editRecord,
                                                                paidByCard: parseFloat(onlyPozitiveText(e) || '0')
                                                            })
                                                        }}
                                                    />
                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label className={"font-weight-bold"}>От ваучер</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        {
                                                            editRecord.voucher &&
                                                            <InputGroup.Text
                                                                id="basic-addon2">#{editRecord.voucher.number} </InputGroup.Text>
                                                        }
                                                        <CacheInput
                                                            value={zeroToEmptyStringAsNumber(editRecord.paidByVoucher)}
                                                            disabled={isPriseDisabled()}
                                                            onChange={newValue => {
                                                                let _value = textToFloatOrZero(newValue);
                                                                const _freeAmount = editRecord!.voucher!.amount - editRecord!.voucher!.usedAmount;
                                                                if (_freeAmount < _value) {
                                                                    _value = _freeAmount;
                                                                }

                                                                setEditRecord({...editRecord, paidByVoucher: _value});
                                                            }}/>

                                                        <Button variant="outline-secondary"
                                                                disabled={isPriseDisabled()}
                                                                onClick={() => setSelectVoucherFlag(true)}>
                                                            <FontAwesomeIcon icon={faList}/>
                                                        </Button>
                                                    </InputGroup>

                                                </Form.Group>
                                            </Col>
                                            <Col xs={4}>
                                                <Form.Group className="mb-2">
                                                    <Form.Label className={"font-weight-bold"}>От пакет</Form.Label>
                                                    <InputGroup className="mb-3">
                                                        {
                                                            editRecord.packet &&
                                                            <InputGroup.Text
                                                                id="basic-addon2">#{editRecord.packet.number} </InputGroup.Text>
                                                        }
                                                        <Form.Control
                                                            value={zeroToEmptyStringAsNumber(editRecord.paidByPacket)}
                                                            disabled onChange={() => {
                                                        }}/>

                                                        <Button variant="outline-secondary"
                                                                disabled={!editRecord.itemId}
                                                                onClick={() => setSelectPacketFlag(true)}>
                                                            <FontAwesomeIcon icon={faList}/>
                                                        </Button>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>

                </Modal.Body>

                <Modal.Footer>

                    <Row className={"w-100 m-0 p-0"}>
                        {
                            status !== "WORKING" ? <>
                                <Col>
                                    <Button variant={"success"} onClick={saveRecord}>
                                        <FontAwesomeIcon icon={faSave}/> Запази</Button>&nbsp;
                                    <Button variant={"secondary"} onClick={cancelRecord}>
                                        <FontAwesomeIcon icon={faCancel}/> Откажи</Button>
                                </Col>
                            </> : <Col className={"text-left"}><Spinner animation={"border"}/></Col>
                        }
                    </Row>

                </Modal.Footer>
            </Modal>

            {
                selectDoctorFlag &&
                <>
                    <Modal show={true} style={{zIndex: 1610}}>
                        <Modal.Body>
                            <SelectUserComponent forDate={dateToString(editRecord.recordDate)}
                                                 operators={true}
                                                 onSelect={(u) => {
                                                     setDoctor(u);
                                                     setSelectDoctorFlag(false)
                                                 }}
                                                 onCancel={() => setSelectDoctorFlag(false)}
                            />
                        </Modal.Body>
                    </Modal>
                </>
            }

            {
                selectVoucherFlag &&
                <VouchersDialog onClose={() => setSelectVoucherFlag(false)}
                                zIndex={1610} type={"VOUCHER"}
                                onSelect={(v) => {
                                    const sellPrice = editRecord.sellPrice;
                                    let voucherAmount = v.amount - v.usedAmount;
                                    if(isNaN(voucherAmount)) {
                                        voucherAmount = v.amount;
                                    }

                                    if (voucherAmount > sellPrice) voucherAmount = sellPrice;

                                    setEditRecord({
                                        ...editRecord, voucher: v, paidByVoucher: voucherAmount
                                    });
                                    setSelectVoucherFlag(false)
                                }}
                                recordItemQty={1}
                />
            }

            {
                selectPacketFlag &&
                <VouchersDialog onClose={() => setSelectPacketFlag(false)}
                                zIndex={1610} type={"PACKET"} recordItemQty={editRecord.itemQty}
                                recordType={editRecord.recordType} recordItemId={editRecord.itemId}
                                onSelect={(v) => {
                                    const itemCnt = v.items.length || 1;
                                    const singlePrice =  v.amount / itemCnt;
                                    setEditRecord({
                                        ...editRecord, packet: v, paidByPacket: singlePrice,
                                        paidByVoucher: 0, voucher: undefined, paidByCash: 0, paidByCard: 0,
                                        sellPrice: singlePrice, itemQty: 1, discount: 0, itemSellPrice: singlePrice,
                                        paid: singlePrice
                                    });
                                    setSelectPacketFlag(false)
                                }}
                />
            }

            {
                selectItemFlag && editRecord.recordType === "процедура" &&
                <ConfigProceduresForm onClose={() => setSelectItemFlag(false)}
                                      zIndex={1610}
                                      onSelect={(p) => {
                                          onSelectRecordItem(p);
                                      }}
                />
            }

            {
                selectItemFlag && editRecord.recordType === "филър" &&
                <ConfigMaterialsForm onClose={() => setSelectItemFlag(false)}
                                     zIndex={1610}
                                     onlyFillers={true}
                                     onSelect={(p) => {
                                         onSelectRecordItem(p);
                                     }}
                />
            }

            {
                selectItemFlag && editRecord.recordType === "вливка" &&
                <ConfigInfusionsForm onClose={() => setSelectItemFlag(false)}
                                     zIndex={1610}
                                     onSelect={(p) => {
                                         onSelectRecordItem(p);
                                     }}
                />
            }

        </>
    )

}
