import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffect, useRef, useState} from "react";
import {faAngleRight, faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import {Account} from "../types";
import {FACTORY} from "../factories";
import {API, API_CALL, NET_STATUS} from "../api";
import {isSuccess} from "../common";
import {Link, useHistory} from "react-router-dom";
import * as queryString from "querystring";
import {Col, Row} from "react-bootstrap";

export function SelectClinicPage(props: any) {
    return (
        <>
            <div className={"vh-100 bg-dark page-content"}>
                <div className="h-100 row align-items-center">
                    <div className="col p-3 row justify-content-center">

                        <div className="col-lg-5 col-12">
                            <div className="card shadow ">
                                <div className="card-header row m-0">
                                    <h4 className="col-auto pl-0">Моля, изберете клиника</h4>
                                    <div className="col text-right pt-2">
                                        <FontAwesomeIcon icon="user-lock" size="lg"/>
                                    </div>
                                </div>
                                <div className="card-body overflow-auto" style={{maxHeight: "60vh"}}>
                                    <Row>
                                        <Col className={"text-center"}>
                                            <a className={"btn btn-outline-dark p-3"}

                                               href={"https://vandev-plovdiv.vivisoft-bg.com"}>Клиника Пловдив</a>
                                        </Col>
                                        <Col className={"text-center"}>
                                            <a className={"btn btn-outline-dark p-3"}

                                               href={"https://vandev-varna.vivisoft-bg.com"}>Клиника Варна</a>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
