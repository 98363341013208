import React, {useEffect} from "react";
import {AxiosRequestConfig} from "axios";
import {API, API_CALL} from "../api";

export default function useGetApiCall(url: string, requestConfig: AxiosRequestConfig = {} ) {
    const urlRef = React.useRef<string>('');
    const configRef = React.useRef(requestConfig);

    const [apiCall, setApiCall] = React.useState<API_CALL|null>(null);

    React.useEffect(() => {
        urlRef.current = url;
        configRef.current = requestConfig;
    }, [url, requestConfig]);

    useEffect(() => {
        API.doGetRequest((apiCall: API_CALL) => {
            setApiCall({...apiCall});
        }, urlRef.current, configRef.current);
    }, []);

    const reload = function() {
        API.doGetRequest((apiCall: API_CALL) => {
            setApiCall({...apiCall});
        }, urlRef.current, configRef.current);
    }

    return {apiCall, reload}
}
