import {ClientRecordItem} from "./RecordDetailsPage";
import {anyToFloat, zeroToEmptyStringAsCurrencyWithCommas} from "../../common";
import React from "react";

export function RecordDayTotal({dayRecords}: { dayRecords: ClientRecordItem[] }) {
    let totalPrice = 0.0;
    let totalPaid = 0.0;

    if (!dayRecords || dayRecords.length === 0) return null;

    dayRecords.forEach(
        r => {
            totalPaid += anyToFloat(r.paid);
            totalPrice += anyToFloat(r.sellPrice);
        }
    );

    return (
        <>
            <tr className={"font-weight-bold bg-secondary-light"}>
                <td colSpan={4}></td>
                <td> Всичко за деня:</td>
                <td className={"text-right"}> {dayRecords.length.toString()} </td>
                <td className={"text-right text-info"}> {zeroToEmptyStringAsCurrencyWithCommas(totalPrice.toString())} </td>
                <td className={"text-right text-success"}> {zeroToEmptyStringAsCurrencyWithCommas(totalPaid.toString())} </td>
                <td className={"text-right text-danger"}> {zeroToEmptyStringAsCurrencyWithCommas((totalPrice - totalPaid).toString())} </td>
                <td className={"w-100px text-center"}>
                </td>
            </tr>
            <tr>
                <td colSpan={9}>&nbsp;</td>
            </tr>
        </>
    )
}
