import {
    Button, CloseButton,
    Col,
    Form,
    Modal,
    Row, Spinner,
} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    dateToString,
    showErrorsListInToast, showMsgInToast,
} from "../../common";
import {ClientItem} from "../config/clients/ConfigClientsForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faSave,  faUser} from "@fortawesome/free-solid-svg-icons";
import {saveClientRecordAsync, selectMessage, selectStatus, setStatus} from "../../features/RecordsSlice";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {ClientRecordItem} from "./RecordDetailsPage";


export interface RecordNotePageProps {
    zIndex?: number;
    client: ClientItem;
    clinicIndex: number;
    record: ClientRecordItem;
    onClose: (needReload: boolean) => any;
}

export default function RecordNotePage({client, clinicIndex,  record, onClose}: RecordNotePageProps) {
    const [editRecord, setEditRecord] = useState<ClientRecordItem>({...record});

    const dispatch = useAppDispatch();
    const status = useAppSelector(selectStatus);
    const message = useAppSelector(selectMessage);

    useEffect(() => {
        if (status == "SUCCESS") {
            showMsgInToast(message);
            dispatch(setStatus("PENDING"));
            onClose(true);
        }
        if (status == "FAILED") {
            showErrorsListInToast("Възникна грешка", [message]);
            dispatch(setStatus("PENDING"));
        }
    }, [status]);

    function saveRecord(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];

        const record = {...editRecord};
        record.clientId = client.id;
        record.clinicIndex = clinicIndex;

        if (errors.length === 0) {
            dispatch(saveClientRecordAsync(record));
        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    function cancelRecord() {
        onClose(false);
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: 1500}}/>
            <Modal show={true} size={"lg"} style={{zIndex: 1501}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faUser} className={"mr-3"}/>Пациентско досие -
                        <span
                            className={"small"}>{editRecord.id === -1 ? " добавяне" : " корекция"} на {editRecord.recordType}</span>
                    </Modal.Title>
                    <CloseButton onClick={cancelRecord}/>
                </Modal.Header>

                <Modal.Body>
                    <Row>
                        <Col>
                            <Form onSubmit={saveRecord}>
                                <Row>
                                    <Col xs={4}>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Дата</Form.Label>
                                            <ReadOnlyDatePickerComponent onChange={(d: Date) => setEditRecord({...editRecord, recordDate: d})}
                                                                         value={dateToString(editRecord.recordDate)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                    </Col>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Бележка</Form.Label>
                                <Form.Control as={"textarea"} value={editRecord.description}
                                              rows={2} onChange={e => setEditRecord({...editRecord, description: e.target.value})}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                </Modal.Body>

                <Modal.Footer>

                    <Row className={"w-100 m-0 p-0"}>
                        {
                            status !== "WORKING" ? <>
                                <Col>
                                    <Button variant={"success"} onClick={saveRecord}>
                                        <FontAwesomeIcon icon={faSave}/> Запази</Button>&nbsp;
                                    <Button variant={"secondary"} onClick={cancelRecord}>
                                        <FontAwesomeIcon icon={faCancel}/> Откажи</Button>
                                </Col>
                            </> : <Col className={"text-left"}><Spinner animation={"border"}/></Col>
                        }
                    </Row>

                </Modal.Footer>
            </Modal>

        </>
    )

}
