import React, {FC} from "react";
import {Table} from "react-bootstrap";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../common";

interface Props {
    report: any;
    doctorId: string;
}

interface ReportItem {
    paidByCash: number;
    expenses: number;
    paidByAvans: number;
    paidByBank: number;
    paidByBankConf: number;
    paidByBankNotConf: number;
    paidByNhif: number;
    paidByCard: number;
    paidByPrepaid: number;
    price: number;
}

const DentalReportDoctorsDetails: FC<Props> = (props) => {

    const doctor = props.report.doctors.find(
        (d: any) => d.id.toString() === props.doctorId
    );

    if (!doctor) {
        return null;
    }

    const report = new Map<string, ReportItem>();
    doctor.patients.forEach(
        (p: any) => {
            if (report.has(p.date)) {
                const item = report.get(p.date);
                if (item) {
                    // @ts-ignore
                    Object.keys(item).forEach((key: string) => item[key] += key !== "date" ? p[key] : "");
                    report.set(p.date, item);
                }
            } else {
                report.set(p.date, {...p} as ReportItem)
            }
        }
    )

    const tableContent: any[] = [];

    report.forEach((doctor, key) =>
        tableContent.push(<tr key={key}>
            <td> {key} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCash)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCard)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByAvans)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCash + doctor.paidByCard + doctor.paidByAvans)} </td>
            <td className={"text-right bg-success-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBankConf)} </td>
            <td className={"text-right bg-danger-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBankNotConf)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBank)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByNhif)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.price)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.expenses)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.price - doctor.expenses)} </td>
        </tr>)
    )


    return (<Table bordered size={"sm"} hover striped className={"text-light small"}>
        <thead className={"not-sticky"}>
        <tr className={"bg-secondary"}>
            <th className={"text-nowrap"} rowSpan={2}>Дата</th>
            <th className={"text-center"} colSpan={4}>Кешов отчет</th>
            <th className={"text-center"} colSpan={3}>По банка</th>
            <th className={"text-right text-nowrap"} rowSpan={2}>НЗОК</th>
            <th className={"text-right text-nowrap"} rowSpan={2}>Приход</th>
            <th className={"text-right text-nowrap"} rowSpan={2}>Разход</th>
            <th className={"text-right text-nowrap"} rowSpan={2}>Разлика</th>
        </tr>
        <tr className={"bg-secondary"}>
            <th className={"text-right"}>В брой</th>
            <th className={"text-right"}>С карта</th>
            <th className={"text-right text-nowrap"}>от аванс</th>
            <th className={"text-right text-nowrap"}>Всичко</th>
            <th className={"text-right bg-success-light"}>Потвърд.</th>
            <th className={"text-right bg-danger-light"}>Не потв.</th>
            <th className={"text-right text-nowrap"}>Всичко</th>
        </tr>
        </thead>
        <tbody className={"text-dark"}>
        {tableContent}
        <tr className={"text-right font-weight-bold bg-secondary-light"}>
            <td className={"text-right font-weight-bold"}> ВСИЧКО ЗА {report.size.toString()} ДНИ:</td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCash)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCard)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByAvans)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCash + doctor.paidByCard + doctor.paidByAvans)} </td>
            <td className={"text-right bg-success-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBankConf)} </td>
            <td className={"text-right bg-danger-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBankNotConf)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBank)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByNhif)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.price)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.expenses)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.price - doctor.expenses)} </td>
        </tr>
        </tbody>
    </Table>)
}

export default DentalReportDoctorsDetails;
