import {zeroToEmptyStringAsCurrencyWithCommas} from "../../common";

export interface InvoicesReportItem {
    PAYED_BANK: number;
    NOT_PAYED_BANK: number;
    PAYED_CASH: number;
    PAYED_OTHER: number;
    PAYED_TOTAL: number;
}

export function InvoicesReportContent({report, ddsMode} : {report: Array<InvoicesReportItem>, ddsMode: string}) {

    const calcAmmount = (amountWithDDS: number, ddsMode: string) => {
        if(ddsMode === "withDDS") return amountWithDDS;
        let amountWithoutDDS = amountWithDDS / 1.2;
        if(ddsMode === "withoutDDS") return amountWithoutDDS;
        if(ddsMode === "onlyDDS") return (amountWithDDS - amountWithoutDDS);

        return amountWithDDS;
    }
    const paidBank = [];
    const paidCash = [];
    const paidOther = [];
    const notPaidBank = [];
    const paidTotal = [];

    for(let i = 0; i < 12; i++) {

        const PAYED_BANK = calcAmmount(report[i]?.PAYED_BANK || 0, ddsMode);
        const NOT_PAYED_BANK = calcAmmount(report[i]?.NOT_PAYED_BANK || 0, ddsMode);
        const PAYED_CASH = calcAmmount(report[i]?.PAYED_CASH || 0, ddsMode);
        const PAYED_OTHER = calcAmmount(report[i]?.PAYED_OTHER || 0, ddsMode);
        const PAYED_TOTAL = PAYED_BANK + PAYED_CASH + PAYED_OTHER;

        paidBank.push(<td className={"text-right"}>{ zeroToEmptyStringAsCurrencyWithCommas(PAYED_BANK.toString()) }</td>);
        notPaidBank.push(<td className={"text-right"}>{ zeroToEmptyStringAsCurrencyWithCommas(NOT_PAYED_BANK.toString()) }</td>);
        paidCash.push(<td className={"text-right"}>{ zeroToEmptyStringAsCurrencyWithCommas(PAYED_CASH.toString()) }</td>);
        paidOther.push(<td className={"text-right"}>{ zeroToEmptyStringAsCurrencyWithCommas(PAYED_OTHER.toString()) }</td>);
        paidTotal.push(<td className={"text-right"}>{ zeroToEmptyStringAsCurrencyWithCommas(PAYED_TOTAL.toString()) }</td>);
    }

    const PAYED_BANK = calcAmmount(report[12]?.PAYED_BANK || 0, ddsMode);
    const NOT_PAYED_BANK = calcAmmount(report[12]?.NOT_PAYED_BANK || 0, ddsMode);
    const PAYED_CASH = calcAmmount(report[12]?.PAYED_CASH || 0, ddsMode);
    const PAYED_OTHER = report[12]?.PAYED_OTHER || 0;
    const PAYED_TOTAL = PAYED_BANK + PAYED_CASH + PAYED_OTHER;

    return <>
        <tr>
            <td>Платени банка</td>
            <td className={"text-right font-weight-bold"}>{ zeroToEmptyStringAsCurrencyWithCommas(PAYED_BANK.toString())}</td>
            {paidBank}
        </tr>
        <tr>
            <td>Платени в брой</td>
            <td className={"text-right font-weight-bold"}>{ zeroToEmptyStringAsCurrencyWithCommas(PAYED_CASH.toString())}</td>
            {paidCash}
        </tr>
        <tr>
            <td>Разходи без ф-ра</td>
            <td className={"text-right font-weight-bold"}>{ zeroToEmptyStringAsCurrencyWithCommas(PAYED_OTHER.toString())}</td>
            {paidOther}
        </tr>
        <tr className={"bg-success-light"}>
            <td>Платени общо</td>
            <td className={"text-right font-weight-bold"}>{ zeroToEmptyStringAsCurrencyWithCommas(PAYED_TOTAL.toString())}</td>
            {paidTotal}
        </tr>
        <tr className={"bg-danger-light"}>
            <td>Неплатени</td>
            <td className={"text-right font-weight-bold"}>{ zeroToEmptyStringAsCurrencyWithCommas(NOT_PAYED_BANK.toString())}</td>
            {notPaidBank}
        </tr>


    </>
}