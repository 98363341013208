export enum PATHS {
    home= "/",
    login="/login",
    config="/config",
    store="/store",
    reports="/reports",
    invoices="/invoices",
    recordsPlovdiv="/recordsPlovdiv",
    recordsVarna="/recordsVarna",
    dental="/dental",
    dailyReport="/daily-report",
    accounts="/admin/accounts",
    account="/admin/accounts/",
}

export enum API_PATHS {
    login="login",
    logout="user/logout",
    allUsersNotSigned="user/all",
    accounts="admin/accounts",
    appModules="admin/app-modules",
    user="user",
    user_login="user/login",

    materials="admin/materials",
    procedures="admin/procedures",
    infusions="admin/infusions",
    manipulations="admin/manipulations",
    suppliers="admin/suppliers",
    clinics="admin/clinics",
    invoices="admin/invoices",
    machines="admin/machines",
    clients="admin/clients",
    clientRecords="admin/client/records",
    clientDentalRecords="admin/client/dental/records",
    clientDentalRecordManipulations="admin/client/dental/records/manipulations",
    reports="admin/reports",
    upload_documents="admin/upload-documents",
    vouchers="admin/vouchers",
    prepaid="admin/prepaid",
}

export function buildClientDentalRecordDocumentsPath (recordId: number) {
    return API_PATHS.clientDentalRecords +  `/${recordId}/documents`;
}

