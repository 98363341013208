import ModalHeader from "react-bootstrap/ModalHeader";
import {
    Button,
    CloseButton,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalTitle,
    Spinner,
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faDollar, faGift, faPlus,} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import PrepaidDialogList from "./PrepaidDialogList";
import PrepaidDialogEdit from "./PrepaidDialogEdit";
import {API} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast} from "../../common";
import moment from "moment";

export interface PrepaidDialogProps {
    zIndex?: number;
    clientId: number;
    onClose: (newBalance: number) => void;
}

export type PrepaidItemType = {
    id?: number;
    clientId?: number;
    date: Date;
    amount: number;
    description: string;
    paidByCash?: number;
    paidByCard?: number;
    paidByBank?: number;
}
export type PrepaidItemsType = PrepaidItemType[];

const PrepaidDialog: React.FC<PrepaidDialogProps> = ({zIndex, clientId, onClose}) => {
    const [working, setWorking] = useState(false);
    const [viewMode, setViewMode] = useState<"LIST" | "EDIT" | "DELETE">("LIST");
    const [data, setData] = useState<PrepaidItemsType>([]);
    const [selectedItem, setSelectedItem] = useState<PrepaidItemType | null>(null);
    const [balance, setBalance] = useState(0);


    const processData = (rawData: PrepaidItemsType) => {
        setData(rawData.map(value => {
            return {
                ...value, date: moment(value.date, "DD.MM.YYYY").toDate()
            } as PrepaidItemType;
        }));
    }
    const loadData = () => {
        API.getClientPrepaid(apiCall => {
            setWorking(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                processData(apiCall.data.data.items);
                setBalance(apiCall.data.data.balance);
            }
            if (isError(apiCall)) {
                setData([]);
                showErrorsInToast(apiCall, "Възникна грешка");
            }
        }, clientId);
    }

    const saveData = (item: PrepaidItemType) => new Promise<boolean>((resolve, reject) => {
        API.saveClientPrepaid(apiCall => {
            setWorking(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                processData(apiCall.data.data.items);
                setBalance(apiCall.data.data.balance);
                resolve(true);
            }
            if (isError(apiCall)) {
                setData([]);
                showErrorsInToast(apiCall, "Възникна грешка");
                reject(false);
            }
        }, item);
    });

    const savePrapaidItem = async (item: PrepaidItemType) => {
        if(await saveData(item)) {
            setViewMode("LIST");
        }
    };

    const addPrepaidItem = () => {
        setSelectedItem({
            clientId: clientId,
            date: new Date(),
            amount: 0,
            description: ""
        } as PrepaidItemType);
        setViewMode("EDIT");
    }

    const deletePrepaidItem = (item: PrepaidItemType) => {
        API.deleteClientPrepaid(apiCall => {
            setWorking(isLoading(apiCall));
            if (isSuccess(apiCall)) {
                processData(apiCall.data.data.items);
                setBalance(apiCall.data.data.balance);
            }
            if (isError(apiCall)) {
                showErrorsInToast(apiCall, "Възникна грешка");
            }
        }, item);
    }

    useEffect(() => {
        loadData();
    }, []);

    let modalBody;

    switch (viewMode) {
        case "LIST":
            modalBody = <PrepaidDialogList data={data || []}
                                           onEdit={item => {
                                                setSelectedItem(item);
                                                setViewMode("EDIT");
                                            }}
                                           onDelete={item => deletePrepaidItem(item)}
            />;
            break;
        case "EDIT":
            modalBody = <PrepaidDialogEdit item={selectedItem!} working={working}
                                           onSave={newItem => savePrapaidItem(newItem)}
                                           onDone={() => setViewMode("LIST")}/>;
            break;
        case "DELETE":
            modalBody = null;
            break;
    }

    return <Modal show={true} size={"xl"} onHide={() => onClose(balance)}
                  style={{zIndex: zIndex ? zIndex : 1600}}>
        <ModalHeader>
            <ModalTitle>
                <FontAwesomeIcon icon={faDollar}/> Аванс
            </ModalTitle>
            <CloseButton onClick={() => onClose(balance)}/>
        </ModalHeader>
        <ModalBody>
            {modalBody}
        </ModalBody>
        {
            viewMode === "LIST" &&
            <ModalFooter>
                {
                    working ? <Col className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                        :
                        <>
                            <Col>
                                <Button variant={"primary"} onClick={addPrepaidItem}>
                                    <FontAwesomeIcon icon={faPlus}/> Добави
                                </Button>
                            </Col>
                            <Col className={"text-right"}>
                                <Button variant={"secondary"} onClick={() => onClose(balance)}>
                                    <FontAwesomeIcon icon={faCancel}/> Затвори...
                                </Button>
                            </Col>
                        </>
                }
            </ModalFooter>
        }
    </Modal>
}

export default PrepaidDialog;
