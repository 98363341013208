import {Col, Row, Spinner, Table} from "react-bootstrap";
import React, {FC, useEffect, useState} from "react";
import {API, API_CALL} from "../../api";
import {
    anyToDateString,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError, zeroToEmptyStringAsCurrencyWithCommas, zeroToEmptyStringAsNumber,
} from "../../common";
import ReportFinanceResultTable, {
    reportDataToFinanceItem,
    ReportFinanceResultTableItem
} from "./ReportFinanceResultTable";

interface Props {
    report: any;
}

const EsteticReportsSummaryPage: FC<Props> = ({ report }) => {


    function extractReportData(field: string): Array<ReportFinanceResultTableItem> {
        let result: Array<ReportFinanceResultTableItem> = [];

        try {
            let keys = Object.keys(report[field]);
            keys.forEach(
                k => {
                    result.push(reportDataToFinanceItem(report[field][k]));
                }
            )
        } catch (e) {
            console.error(e);
        }

        if(field === "infusions") {
            const materials = report["materials"];
            materials && Object.values(materials).forEach(
                (m: any) => {
                    result.push({
                        name: m["materialName"],
                        total: "",
                        qty: zeroToEmptyStringAsNumber(m["qty"].toString()),
                        expense: zeroToEmptyStringAsCurrencyWithCommas(m["expense"].toString()),
                        paidByCard: "",
                        paidByCash: "",
                        sellPrice: "",
                    });
                }
            )
        }

        return result;
    }


    return (
        <>
            <Row>
                <Col className={"col col-6"}>
                    <ReportFinanceResultTable firstColTitle={"Доктор / терапевт"}
                                              data={extractReportData('doctors')}/>
                </Col>
                <Col>
                    <div className="table-container mb-3 w-100">
                        <Table bordered size={"sm"} hover striped className={"w-100 text-light small"}>
                            <thead>
                            <tr className={"bg-secondary"}>
                                <th>ВСИЧКО ЗА ПЕРИОДА</th>
                                <th className={"text-right w-120px"}>Сума</th>
                            </tr>
                            </thead>
                            <tbody className={"text-dark"}>
                            <tr>
                                <th className={"text-nowrap"}> Плащания в брой</th>
                                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByCash)} </td>
                            </tr>
                            <tr>
                                <th className={"text-nowrap"}> Плащания с карта</th>
                                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByCard)} </td>
                            </tr>
                            <tr className={"font-weight-bold bg-success-light"} style={{fontSize: "larger"}}>
                                <td className={"text-right"}
                                    colSpan={2}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paid)} </td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className={"col col-6"}>
                    <ReportFinanceResultTable firstColTitle={"Процедури"}
                                              data={extractReportData('procedures')}/>
                </Col>
                <Col>
                    <ReportFinanceResultTable firstColTitle={"Филъри и козметика"}
                                              data={extractReportData('fillers')}/>
                </Col>
            </Row>
            <Row>
                <Col className={"col col-6"}>
                    <ReportFinanceResultTable firstColTitle={"Вливки"}
                                              data={extractReportData('infusions')}
                    />
                </Col>
                <Col>
                    <ReportFinanceResultTable firstColTitle={"Апарати"}
                                              data={extractReportData('machines')}/>
                </Col>
            </Row>
        </>
    )
}

export default EsteticReportsSummaryPage;
