import {Button, Col, Row, Spinner, Tab, Table, Tabs,} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {anyToDateString, showErrorsListInToast, zeroToEmptyStringAsCurrencyWithCommas} from "../../common";
import {ClientItem} from "../config/clients/ConfigClientsForm";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {
    deleteClientRecordsAsync,
    getClientRecordsAsync,
    selectMessage,
    selectRecords, selectRecordsForDate,
    selectStatus,
    setStatus
} from "../../features/RecordsSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import RecordDetailsPage, {ClientRecordItem} from "./RecordDetailsPage";
import {ConfirmDeleteModal} from "../../common/ConfirmDeleteModal";
import RecordNotePage from "./RecordNotePage";
import moment from "moment";
import store from "../../app/store";
import {RecordDayTotal} from "./RecordDayTotal";
import {getClinicName} from "../../factories";

export interface RecordsItemPageProps {
    clinicIndex: number;
    client: ClientItem;
    onClose: () => any;
}

export default function RecordsItemPage({clinicIndex, client, onClose}: RecordsItemPageProps) {
    const [activeType, setActiveType] = useState("всички");
    const [editRecord, setEditRecord] = useState<ClientRecordItem | null>(null);
    const [deleteRecord, setDeleteRecord] = useState<ClientRecordItem | null>(null);
    const dispatch = useAppDispatch();
    const records = useAppSelector(selectRecords(client.id));
    const status = useAppSelector(selectStatus);
    const message = useAppSelector(selectMessage);

    useEffect(() => {
        dispatch(getClientRecordsAsync(client.id));
        console.log("loading ...")
    }, [client]);

    useEffect(() => {
        if (status === "FAILED") {
            dispatch(setStatus("PENDING"));
            showErrorsListInToast("Възникна грешка", [message]);
        }
        if (status === "SUCCESS") {
            dispatch(setStatus("PENDING"));
            setDeleteRecord(null);
        }
    }, [status]);


    function getBGColor(r: ClientRecordItem) {
        switch (r.recordType) {
            case "бележка":
                return "#f0f0f0";
            case "процедура":
                return "#f0fcf3";
            case "вливка":
                return "#fff6db";
            case "филър":
                return "#e3fbff";
        }
    }

    function filteredRecords(): ClientRecordItem[] {
        if (activeType === "всички") return records;
        return records.filter(r => r.recordType === activeType);
    }

    const _filteredRecords = filteredRecords();


    const tableContent: any[] = [];

    let recordDate: string = '';
    let dayRecords: ClientRecordItem[] = [];

    _filteredRecords.forEach(
        r => {
            let _rd = anyToDateString(r.recordDate);

            if(_rd !== recordDate) {
                if(dayRecords.length > 0) {
                    tableContent.push( <RecordDayTotal key={_rd} dayRecords={dayRecords} /> );
                }

                recordDate = _rd;
                dayRecords = selectRecordsForDate(client.id, moment(recordDate, 'DD.MM.YYYY').toDate())(store.getState());
            }

            tableContent.push(
                <tr key={r.id} style={{backgroundColor: getBGColor(r)}}>
                    <td> {r.recordDate} </td>
                    <td> {getClinicName(r.clinicIndex)} </td>
                    <td> {r.recordType} </td>

                    {
                        r.recordType === "бележка" ?
                            <td colSpan={6}>
                                {r.description}
                            </td>
                            :
                            <>
                                <td> {r.user?.name} </td>
                                <td> {r.itemName} </td>
                                <td className={"text-right"}> {r.itemQty} </td>
                                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(r.sellPrice.toString())} </td>
                                <td></td>
                                <td></td>
                            </>
                    }
                    <td className={"w-100px text-center"}>
                        {
                            clinicIndex === r.clinicIndex && <>
                                <Button size={"sm"} onClick={() => setEditRecord(r)}>
                                    <FontAwesomeIcon icon={faPencil}/>
                                </Button>&nbsp;
                                <Button size={"sm"} variant={"danger"} onClick={() => setDeleteRecord(r)}>
                                    <FontAwesomeIcon icon={faTrash}/></Button>
                            </>
                        }
                    </td>
                </tr>
            );
        }
    );
    if(dayRecords.length > 0) {
        tableContent.push( <RecordDayTotal key={"lastday"} dayRecords={dayRecords} /> );
    }


    function doDeleteRecord() {
        console.log('deleteing');
        dispatch(deleteClientRecordsAsync(deleteRecord!.id));
    }

    return (
        <>
            <Row className={"p-3 mt-3 border}"}>
                <Col>
                    <div className={"mb-3 pl-2"}>
                        <Tabs variant={"pills"} onSelect={e => setActiveType(e?.toString() || "всички")}>
                            <Tab title={"Всички типове"} eventKey={"всички"}></Tab>
                            <Tab title={"Процедури"} eventKey={"процедура"}/>
                            <Tab title={"Филъри"} eventKey={"филър"}/>
                            <Tab title={"Вливки"} eventKey={"вливка"}/>
                            <Tab title={"Бележки"} eventKey={"бележка"}/>
                        </Tabs>
                    </div>
                    <div className={"max-h-40vh scrollable"}>
                        <Table size={"sm"} hover bordered className={"text-light"}>
                            <thead style={{top: "-1px"}}>
                            <tr className={"bg-secondary"}>
                                <th>Дата</th>
                                <th>Клиника</th>
                                <th>Тип</th>
                                <th>Терапевт</th>
                                <th>Описание</th>
                                <th className={"text-right"}>Брой</th>
                                <th className={"text-right"}>Цена</th>
                                <th className={"text-right"}>Платено</th>
                                <th className={"text-right"}>Разлика</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody className={"text-dark"}>
                            {
                                _filteredRecords?.length === 0 &&
                                <tr>
                                    <td className={"text-center font-weight-bold"} colSpan={9}>
                                        Досието на пациента е празно.
                                    </td>
                                </tr>
                            }
                            { tableContent }
                            {
                                status === "WORKING" &&
                                <tr>
                                    <td className={"text-center font-weight-bold"} colSpan={9}>
                                        <Spinner animation={"border"}/>
                                    </td>
                                </tr>
                            }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>

            {
                editRecord && editRecord.recordType === "бележка" &&
                <RecordNotePage onClose={() => setEditRecord(null)} clinicIndex={clinicIndex}
                                zIndex={1600} client={client} record={editRecord}
                />
            }

            {
                editRecord && editRecord.recordType !== "бележка" &&
                <RecordDetailsPage onClose={() => setEditRecord(null)} clinicIndex={clinicIndex}
                                   zIndex={1600} client={client} record={editRecord}
                />
            }

            {
                deleteRecord &&
                <ConfirmDeleteModal working={status === "WORKING"}
                                    onConfirm={doDeleteRecord}
                                    onReject={() => setDeleteRecord(null)}
                />
            }
        </>
    )

}
