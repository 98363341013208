import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Container, Row, Spinner, Table} from "react-bootstrap";
import {faFileLines, faPencil, faPlus, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {MaterialItem} from "../config/materials/ConfigMaterialsForm";
import {API, API_CALL} from "../../api";
import {Account} from "../../types";
import {
    dateToString,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError, zeroToEmptyStringAsNumber
} from "../../common";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {ConfirmModal} from "../../common/ConfirmModal";
import {ClientItem} from "../config/clients/ConfigClientsForm";
import {ProcedureItem} from "../config/procedures/ConfigProceduresForm";
import {MachineItem} from "../config/machines/ConfigMachinesForm";
import {ConfirmDeleteModal} from "../../common/ConfirmDeleteModal";
import DailyReportItemPage from "./DailyReportItemPage";
import moment from "moment";


export interface DailyReportItem {
    id: number;
    reportDate: string;
    doctor: Account | null | undefined;
    client: ClientItem | null | undefined;
    procedure: ProcedureItem | null | undefined;
    machine: MachineItem | null | undefined;
    materials: Array<MaterialItem>;
    discount: string;
    payedCash: string;
    payedCard: string;
    costs: string;
    sellPrice: string;
    comments: string;
}

export interface DailyReport {
    records: Array<DailyReportItem>;
}

const EMPTY_DAILY_REPORT: DailyReport = { records: [] }
const EMPTY_DAILY_REPORT_ITEM: DailyReportItem = {
    id: -1, reportDate: '', doctor: null, client: null, comments: '', costs: '0', machine: null, discount: '0',
    payedCard: '0', payedCash: '0', procedure: null, materials: [], sellPrice: ''
}

export function DailyReportPage() {
    const NO_VALUE_TEXT = '- не е избрано -';
    const [reportDate, setReportDate] = useState<Date>(new Date());
    const [report, setReport] = useState<DailyReport>(EMPTY_DAILY_REPORT);
    const [editReportItem, setEditReportItem] = useState<DailyReportItem | null>(null);
    const [editReportItemIdx, setEditReportItemIdx] = useState<number | null>(null);
    const [working, setWorking] = useState(false);
    const [confirmSave, setConfirmSave] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState(false);


    useEffect(() => {
        loadReport()
    }, [reportDate]);


    function loadReport() {
        API.getDailyReport(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    const report = api_call.data.data.records;
                    console.log(report);
                    setReport({records: report});
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на дневният отчет!', translateError)
            }, moment(reportDate).format('YYYY-MM-DD')
        )
    }

    function saveData() {
        setConfirmSave(true)
    }

    function doSaveData(btn: string) {
        setConfirmSave(false)

        if(btn === "Запази") {
            API.postDailyReport(
                (api_call: API_CALL) => {
                    setWorking(isLoading(api_call));
                    if (isSuccess(api_call)) {
                        loadReport()
                    }
                    if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при записване на дневния отчет!', translateError)
                }, report
            )
        }
    }

    function deleteReport() {
        setConfirmDelete(true);
    }

    function doDeleteReport(proceed: boolean) {
        setConfirmDelete(false);

        if(proceed) {
            API.deleteDailyReport(
                (api_call: API_CALL) => {
                    setWorking(isLoading(api_call));
                    if (isSuccess(api_call)) {
                        loadReport()
                    }
                    if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при изтриване на дневния отчет!', translateError)
                }, report
            )
        }
    }

    function saveReportItem(item: DailyReportItem) {
        if(editReportItemIdx !== null) report.records[editReportItemIdx] = item
        else report.records.push(item);

        setReport({...report});
        setEditReportItem(null);
        setEditReportItemIdx(null);
    }

    function deleteReportItem() {
        setReport({...report, records: report.records.filter(r => r !== editReportItem)});
        setEditReportItem(null);
    }

    console.log(report)
    return (
        <>
            <Container fluid>
                <Card className={"border-0"}>
                    <Card.Header>
                        <Row className={"pt-2 pb-0"}>
                            <Col>
                                <h5 className={"text-uppercase font-weight-bold"}>
                                    <FontAwesomeIcon icon={faFileLines} className={"mr-3"}/>Дневен отчет
                                </h5>
                            </Col>
                            <Col xs={"auto"} style={{lineHeight: "2.25rem"}}>
                                <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                    setReportDate(date)
                                }} value={dateToString(reportDate)}/>
                            </Col>
                        </Row>
                    </Card.Header>
                    <Card.Body className={"max-h-60vh scrollable"}>
                        <Table size={"sm"} bordered hover>
                            <thead>
                            <tr className={"bg-secondary text-light"}>
                                <th>Терапевт</th>
                                <th>Пациент</th>
                                <th>Процедура</th>
                                <th>Апарат</th>
                                <th className={"text-right w-120px"}>Разход (лв)</th>
                                <th className={"text-right w-120px"}>Цена (лв)</th>
                                <th className={"text-right w-120px"}>Отстъпка (%)</th>
                                <th className={"text-right w-120px"}>В брой</th>
                                <th className={"text-right w-120px"}>С карта</th>
                                <th>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                report?.records?.length > 0 ?
                                    report.records.map(
                                        (r, idx) => <tr key={idx}>
                                            <td>{ r.doctor?.name || NO_VALUE_TEXT }</td>
                                            <td>{ r.client?.name || NO_VALUE_TEXT }</td>
                                            <td>{ r.procedure?.name || NO_VALUE_TEXT }</td>
                                            <td>{ r.machine?.name || NO_VALUE_TEXT }</td>
                                            <td className={"text-right w-120px"}>{ zeroToEmptyStringAsNumber(r.costs) }</td>
                                            <td className={"text-right w-120px"}>{ zeroToEmptyStringAsNumber(r.sellPrice) }</td>
                                            <td className={"text-right w-120px"}>{ zeroToEmptyStringAsNumber(r.discount) }</td>
                                            <td className={"text-right w-120px"}>{ zeroToEmptyStringAsNumber(r.payedCash) }</td>
                                            <td className={"text-right w-120px"}>{ zeroToEmptyStringAsNumber(r.payedCard) }</td>
                                            <td>
                                                <Button variant={"outline-secondary"}
                                                    onClick={() => {
                                                        setEditReportItem(r)
                                                        setEditReportItemIdx(idx)
                                                    }}
                                                ><FontAwesomeIcon
                                                    icon={faPencil}/></Button>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    <tr>
                                        <td>Дневният отчет е празен.</td>
                                    </tr>
                            }
                            </tbody>
                        </Table>
                    </Card.Body>
                    <Card.Footer>
                        {
                            working ? <Spinner animation="border"/> :
                                <>
                                    <Button
                                        onClick={() => {
                                            setEditReportItem({...EMPTY_DAILY_REPORT_ITEM,
                                                reportDate: moment(reportDate).format('DD.MM.YYYY')} )
                                            setEditReportItemIdx(null)
                                        }}
                                    ><FontAwesomeIcon
                                        icon={faPlus}/> Добави ред</Button>


                                    <Button
                                        onClick={() => deleteReport()}
                                        variant={"danger"}
                                        style={{float: "right", marginLeft: 10}}
                                    ><FontAwesomeIcon
                                        icon={faTrash}/> Изтрий отчета</Button>
                                    <Button
                                        onClick={saveData}
                                        style={{float: "right"}}
                                        variant={"success"}
                                    ><FontAwesomeIcon
                                        icon={faSave}/> Запази отчета</Button>
                                </>
                        }
                    </Card.Footer>
                </Card>

            </Container>


            {
                confirmSave && <ConfirmModal
                    title={"Внимание"}
                    message={"Моля, потвърдете записването на промените!"}
                    onClose={() => {}}
                    buttons={["Запази", "Откажи"]}
                    primaryBtn={"Запази"}
                    onButtonClick={(btn) => doSaveData(btn)}
                />
            }

            {
                confirmDelete &&
                <ConfirmDeleteModal working={working} onConfirm={() => doDeleteReport(true)} onReject={() => doDeleteReport(false)} />
            }

            {
                editReportItem &&
                <DailyReportItemPage item={editReportItem}
                                     onSave={saveReportItem}
                                     onCancel={() => {setEditReportItem(null)}}
                                     onDelete={deleteReportItem} />
            }
        </>
    )
}
