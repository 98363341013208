import {Col, Row, Spinner, Table} from "react-bootstrap";
import React, {FC, useEffect, useState} from "react";
import {API, API_CALL} from "../../api";
import {
    anyToDateString,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast, textToFloatOrZero,
    translateError, zeroToEmptyStringAsCurrencyWithCommas,
} from "../../common";
import ReportFinanceResultTable, {
    reportDataToFinanceItem,
    ReportFinanceResultTableItem
} from "./ReportFinanceResultTable";
import {StringInput} from "../../common/StringInput";
import useDebounce from "../../hooks/UseDebounce";

interface Props {
    report: any;
}

const EsteticReportsDetailsPage: FC<Props> = ({report}) => {
    const [filterDate, setFilterDate] = useState("");
    const [filterClient, setFilterClient] = useState("");
    const [filterDoctor, setFilterDoctor] = useState("");
    const [filterType, setFilterType] = useState("");
    const [filterProcedure, setFilterProcedure] = useState("");
    const [filterMachine, setFilterMachine] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [totals, setTotals] = useState(
        {
            expense: 0,
            sellPrice: 0,
            paidByCash: 0,
            paidByCard: 0,
            paidByVoucher: 0,
            paidByPacket: 0,
            totalPaid: 0,
            difference: 0
        }
    );

    useDebounce(() => {
        const client = filterClient.toLowerCase();
        const doctor = filterDoctor.toLowerCase();
        const recordType = filterType.toLowerCase();
        const itemName = filterProcedure.toLowerCase();
        const machineName = filterMachine.toLowerCase();
        const totals = {
            expense: 0,
            sellPrice: 0,
            paidByCash: 0,
            paidByCard: 0,
            paidByVoucher: 0,
            paidByPacket: 0,
            totalPaid: 0,
            difference: 0
        }

        const data = report.patients.filter(
            (p: any) => (
                (!filterDate || p.recordDate.includes(filterDate)) &&
                (!client || p.client?.name?.toLowerCase().includes(client)) &&
                (!doctor || p.user?.name?.toLowerCase().includes(doctor)) &&
                (!recordType || p.recordType.toLowerCase().includes(recordType)) &&
                (!itemName || p.itemName.toLowerCase().includes(itemName)) &&
                (!machineName || p.machineName.toLowerCase().includes(machineName))
            )
        );

        data.forEach(
            (d: any) => {
                const expense = (d.expense * d.itemQty).toString();
                totals.expense += textToFloatOrZero(expense);
                totals.sellPrice += textToFloatOrZero(d.sellPrice);
                totals.paidByCash += textToFloatOrZero(d.paidByCash);
                totals.paidByCard += textToFloatOrZero(d.paidByCard);
                totals.paidByVoucher += textToFloatOrZero(d.paidByVoucher);
                totals.paidByPacket += textToFloatOrZero(d.paidByPacket);
            }
        );
        totals.totalPaid = totals.paidByCash + totals.paidByCard + totals.paidByPacket + totals.paidByVoucher;
        totals.difference = totals.sellPrice - totals.totalPaid;

        setFilteredData(data);
        setTotals(totals);
    }, [report, filterDate, filterClient, filterMachine, filterDoctor, filterProcedure, filterType], 1000);

    return (
        <>
            <Row>
                <Col className={"col col-12"}>
                    <div className="table-container mb-3 w-100">
                        <Table bordered size={"sm"} hover striped className={"w-100 text-light small"}>
                            <thead>
                            <tr className={"bg-secondary"}>
                                <th>Дата</th>
                                <th>Клиент</th>
                                <th>Терапевт</th>
                                <th>Вид</th>
                                <th>Наименование</th>
                                <th>Апарат</th>
                                <th className={"text-right w-120px"}>Разход</th>
                                <th className={"text-right w-120px"}>Цена</th>
                                <th className={"text-right w-120px"}>В брой</th>
                                <th className={"text-right w-120px"}>С карта</th>
                                <th className={"text-right w-120px"}>От ваучер</th>
                                <th className={"text-right w-120px"}>От пакет</th>
                                <th className={"text-right w-120px"}>Платено</th>
                                <th className={"text-right w-120px"}>Остатък</th>
                            </tr>
                            <tr className={"bg-secondary"}>
                                <th>
                                    <input className={"form form-control form-control-sm"}
                                           value={filterDate} onChange={event => setFilterDate(event.target.value)}/>
                                </th>
                                <th>
                                    <input className={"form form-control form-control-sm"}
                                           value={filterClient}
                                           onChange={event => setFilterClient(event.target.value)}/>
                                </th>
                                <th>
                                    <input className={"form form-control form-control-sm"}
                                           value={filterDoctor}
                                           onChange={event => setFilterDoctor(event.target.value)}/>
                                </th>
                                <th>
                                    <input className={"form form-control form-control-sm"}
                                           value={filterType} onChange={event => setFilterType(event.target.value)}/>
                                </th>
                                <th>
                                    <input className={"form form-control form-control-sm"}
                                           value={filterProcedure}
                                           onChange={event => setFilterProcedure(event.target.value)}/>
                                </th>
                                <th>
                                    <input className={"form form-control form-control-sm"}
                                           value={filterMachine}
                                           onChange={event => setFilterMachine(event.target.value)}/>
                                </th>
                                <th className={"text-right w-120px"}></th>
                                <th className={"text-right w-120px"}></th>
                                <th className={"text-right w-120px"}></th>
                                <th className={"text-right w-120px"}></th>
                                <th className={"text-right w-120px"}></th>
                                <th className={"text-right w-120px"}></th>
                                <th className={"text-right w-120px"}></th>
                                <th className={"text-right w-120px"}></th>
                            </tr>
                            </thead>
                            <tbody className={"text-dark"}>
                            {
                                filteredData.map(
                                    (p: any) => {
                                        const totalPaid = textToFloatOrZero(p.paidByCash) +
                                            textToFloatOrZero(p.paidByPacket) +
                                            textToFloatOrZero(p.paidByVoucher) +
                                            textToFloatOrZero(p.paidByCard);
                                        const sellPrice = textToFloatOrZero(p.sellPrice);
                                        const isPaid = sellPrice === totalPaid;

                                        return <tr key={p.id} className={isPaid ? "" : " bg-danger-light"}>
                                            <td> {p.recordDate} </td>
                                            <td> {p.client?.name} </td>
                                            <td> {p.user?.name} </td>
                                            <td> {p.recordType} </td>
                                            <td> {p.itemName} </td>
                                            <td> {p.machineName} </td>
                                            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(p.expense * p.itemQty)} </td>
                                            <td className={"font-weight-bold text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(p.sellPrice)} </td>
                                            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(p.paidByCash)} </td>
                                            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(p.paidByCard)} </td>
                                            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(p.paidByVoucher)} </td>
                                            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(p.paidByPacket)} </td>
                                            <td className={"font-weight-bold text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totalPaid)} </td>
                                            <td className={"font-weight-bold text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(sellPrice - totalPaid)} </td>
                                        </tr>
                                    }
                                )
                            }
                            {
                                filteredData.length ?
                                    <tr className={"bg-secondary text-light font-weight-bold"}>
                                        <td colSpan={6} className={"text-right"}> Всичко за справката: </td>
                                        <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totals.expense)} </td>
                                        <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totals.sellPrice)} </td>
                                        <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totals.paidByCash)} </td>
                                        <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totals.paidByCard)} </td>
                                        <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totals.paidByVoucher)} </td>
                                        <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totals.paidByPacket)} </td>
                                        <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totals.totalPaid)} </td>
                                        <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totals.difference)} </td>
                                    </tr>
                                    :
                                    <tr>
                                        <td colSpan={14} className={"text-center"}><strong>Няма дейност за този период</strong></td>
                                    </tr>
                            }
                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </>
    )
}

//дата/терапевт/процедура,филър,вливка/стойност/платена-неплатена

export default EsteticReportsDetailsPage;
