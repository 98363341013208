import React, {useEffect, useMemo, useState} from "react";
import {EventKey} from "react-bootstrap/types";
import {dateToString, zeroToEmptyStringAsNumber} from "../../common";
import {Button, Col, Container, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCancel, faCheck, faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {VoucherOrPacketType, VouchersType, VoucherType} from "./VouchersDialog";

export interface VouchersDialogListProps {
    type: VoucherOrPacketType;
    data: VouchersType;
    onChangeType: (newType: EventKey) => void;
    onEdit: (voucher: VoucherType) => void;
    onDelete: (voucher: VoucherType) => void;
    onSelect?: (voucher: VoucherType) => void;
}

const VouchersDialogList: React.FC<VouchersDialogListProps> =
    ({type, data, onChangeType, onEdit, onDelete, onSelect}) => {
        const [loading, setLoading] = useState(true);
        const [deleteVoucher, setDeleteVoucher] = useState<VoucherType | null>(null);

        const tableHead = <thead>
        <tr className={"bg-secondary"}>
            <th>Номер</th>
            <th>От дата</th>
            <th>Купувач</th>
            <th>Получател</th>
            <th className={"text-right"}>Сума</th>
            <th className={"text-right"}>Използвана</th>
            <th className={"text-right"}>Остатък</th>
            <th className={"text-right"}>Валиден</th>
            <th></th>
        </tr>
        </thead>;

        const filteredData = useMemo(() => data.filter(value => value.voucherType === type), [data]);

        const tableBody = useMemo(() => filteredData.map(value => {

            let voucherButtons;
            if (deleteVoucher && deleteVoucher.id === value.id) {
                voucherButtons =
                    <>
                        <Button size={"sm"} variant={"success"}
                                onClick={() => onDelete(value)}
                        ><FontAwesomeIcon icon={faCheck}/></Button>&nbsp;
                        <Button size={"sm"} variant={"danger"} onClick={() => setDeleteVoucher(null)}
                        ><FontAwesomeIcon icon={faCancel}/></Button>
                    </>
            } else if (onSelect) {
                voucherButtons = value.isValid && (value.amount > value.usedAmount) ?
                    <>
                        <Button size={"sm"} variant={"outline-secondary"}
                                onClick={() => onSelect(value)}
                        ><FontAwesomeIcon icon={faArrowUpRightFromSquare}/></Button>
                    </> : null;
            } else {
                voucherButtons = <>
                    <Button size={"sm"} variant={"primary"}
                            onClick={() => onEdit(value)}
                    ><FontAwesomeIcon icon={faPencil}/></Button>&nbsp;
                    {
                        Number(value.usedAmount) === 0 &&
                        <Button size={"sm"} variant={"danger"} onClick={() => setDeleteVoucher(value)}
                        ><FontAwesomeIcon icon={faTrash}/></Button>
                    }
                </>
            }


            return <tr key={value.id}>
                <td> {value.number} </td>
                <td> {dateToString(value.date)} </td>
                <td> {value.buyer} </td>
                <td> {value.recipient} </td>
                <td className={"text-right"}> {zeroToEmptyStringAsNumber(value.amount)} </td>
                <td className={"text-right"}> {zeroToEmptyStringAsNumber(value.usedAmount)} </td>
                <td className={"text-right"}> {zeroToEmptyStringAsNumber(value.amount - value.usedAmount)} </td>
                <td className={"text-center font-weight-bold " + (value.isValid && (value.amount > value.usedAmount) ? "text-success" : "text-danger")}>
                    <FontAwesomeIcon icon={value.isValid ? faCheck : faCancel}/>
                </td>
                <td className={"text-right"}>
                    {voucherButtons}
                </td>
            </tr>
        }), [filteredData, deleteVoucher]);


        useEffect(() => {
            setLoading(false);
        }, []);

        if (loading) {
            return <Container>
                <Row>
                    <Col className={"text-center"}>
                        <Spinner animation={"border"}/>
                    </Col>
                </Row>
            </Container>
        }

        return <Container>
            <Tabs activeKey={type} onSelect={eventKey => onChangeType(eventKey || "VOUCHER")}>
                <Tab title={"Ваучери"} eventKey={"VOUCHER"}/>
                <Tab title={"Пакети"} eventKey={"PACKET"}/>
            </Tabs>

            <Table size={"sm"} hover bordered className={"text-light"}>
                {tableHead}
                <tbody className={"text-dark"}>
                {tableBody}
                {tableBody.length === 0 &&
                    <tr>
                        <td colSpan={7} className={"fw-bold text-center"}>Няма издадени.</td>
                    </tr>}
                </tbody>
            </Table>

        </Container>;
    }

export default VouchersDialogList;
