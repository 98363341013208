import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";


export interface CacheInputProps {
    value: string;
    placeholder?: string;
    disabled?:boolean;
    onChange: (newValue: string) => any;
}

export function CacheInput(props: CacheInputProps) {
    const [editValue, setEditValue] = useState(props.value);

    useEffect(() => {
        setEditValue(props.value);
    }, [props.value]);

    return (
            <Form.Control value={editValue} placeholder={props.placeholder}
                          disabled={props.disabled}
                          onChange={(e) => setEditValue(e.target.value)}
                          onBlur={() => {
                              console.log('onBlur')
                              if(editValue !== props.value) {
                                  console.log('Change parent', editValue)
                                  props.onChange(`${editValue}`)
                              }
                          }}
            />
    )
}
