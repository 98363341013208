import {ClientRecord} from "./DentalClinicPage";
import {Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import {useState} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {API, API_CALL} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, showErrorsListInToast, translateError} from "../../common";

export interface ClientRecordFormProps {
    clientName: string;
    record: ClientRecord;
    onClose: (record?: ClientRecord) => void;
    zIndex?: number;
}

export default function ClientRecordForm(props: ClientRecordFormProps) {
    const [working, setWorking] = useState(false);
    const [recordDate, setRecordDate] = useState<Date | null>(props.record.recordDate || new Date());
    const [recordPlan, setRecordPlan] = useState<string | undefined>(props.record.treatmentPlan);

    function handleClose(record?: ClientRecord) {
        props.onClose(record);
    }

    function saveRecord() {
        if(!recordDate) {
            showErrorsListInToast("Внимание", ["Полето начална дата не може да е празно."]);
            return;
        }
        let record = {...props.record, recordDate: recordDate, treatmentPlan: recordPlan};

        API.postClientDentalRecord(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    handleClose(apiCall.data.data.savedRecord);
                }
            }, record
        );
    }

    return <>
        <Modal show={true} onHide={handleClose} style={{zIndex: (props.zIndex || 1500)}} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>Лечение</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className={working ? " opacity-50 " : ""}>
                    <Row className={"border rounded mt-2 mb-3 ml-0 mr-1 p-2 bg-secondary-light"}>
                        <Col className={"p-0 m-0"}>Пациент: <strong> {props.clientName} </strong></Col>
                    </Row>
                    <Form.Group className={"w-140px"}>
                        <Form.Label>Начална дата: </Form.Label>
                        <ReactDatePicker className={"form-control mt-0"} isClearable
                                         title={"Начална дата: "}
                                         dateFormat="dd.MM.yyyy"
                                         onChange={date => setRecordDate(date as Date)}
                                         selected={recordDate}
                        />
                    </Form.Group>

                    <Form.Group style={{height: 300}}>
                        <Form.Label>План за лечение:</Form.Label>
                        <ReactQuill theme="snow" style={{marginTop: 0, height: 240, width: "100%"}} value={recordPlan}
                                    onChange={e => setRecordPlan(e)}
                                    formats={["header", "font", "size", "bold", "italic", "underline", "strike", "blockquote", "list", "bullet", "indent", "color"]}
                        />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {
                    working ? <Spinner animation={"border"} /> :
                        <>
                            <Button variant="secondary" onClick={() => handleClose()}>
                                Откажи
                            </Button>
                            <Button variant="primary" onClick={saveRecord} disabled={working}>
                                Запази
                            </Button>
                        </>
                }
            </Modal.Footer>
        </Modal>
    </>
}
