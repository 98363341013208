import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faSave, faStore, faTrash, faUser} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {showErrorsListInToast, zeroToEmptyString} from "../../../common";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";
import {ClientItem} from "./ConfigClientsForm";
import {validateDate, validateNumber} from "../../../validations";


export interface ConfigClientDetailsProps {
    working: boolean;
    item: ClientItem;
    onSave: (item: ClientItem) => any;
    onDelete: () => any;
    onCancel: () => any;
    zIndex?: number;
}

export default function ConfigureClientDetails({working, item, onSave, onDelete, onCancel, zIndex}: ConfigClientDetailsProps) {
    const [editItem, setEditItem] = useState<ClientItem>({...item});
    const [deleteFlag, setDeleteFlag] = useState(false);

    function save(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];

        editItem.name = editItem.name?.trim();
        if(editItem.name?.length === 0) errors.push("Въведете имената на пациента.");

        if(errors.length === 0) {
            onSave(editItem);
        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: zIndex || 1500}} />
            <Modal show={!deleteFlag} size={"lg"} onHide={onCancel} style={{zIndex: zIndex?zIndex+1:1501}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faUser} className={"mr-3"}/>Детайли за
                        пациент</Modal.Title>
                    <CloseButton onClick={onCancel}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={save}>
                        <Card className={"border-0"}>
                            <Card.Body className={"max-h-60vh scrollable"}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Имена</Form.Label>
                                            <Form.Control name={"name"} value={editItem.name}
                                                          onChange={(e) => setEditItem({...editItem, name: e.target.value})}/>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={3}>
                                        <Row>
                                            <Col>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>Пол</Form.Label>
                                                    <Form.Check type={"radio"} name={"gender"} value={'woman'} checked={editItem.gender === 'жена'}
                                                                label={"жена"}
                                                                onClick={(e) => setEditItem({...editItem, gender: 'жена'})}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group className="mb-3" >
                                                    <Form.Label>&nbsp;</Form.Label>
                                                    <Form.Check type={"radio"} name={"gender"} value={'man'} checked={editItem.gender === 'мъж'}
                                                                label={"мъж"}
                                                                onClick={(e) => setEditItem({...editItem, gender: 'мъж'})}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>


                                <Form.Group className="mb-3" >
                                    <Form.Label>Град</Form.Label>
                                    <Form.Control name={"name"} value={editItem.city}
                                                  onChange={(e) => setEditItem({...editItem, city: e.target.value})}/>
                                </Form.Group>

                                <Form.Group className="mb-3" >
                                    <Form.Label>Телефон</Form.Label>
                                    <Form.Control name={"name"} value={editItem.mobile}
                                                  onChange={(e) => setEditItem({...editItem, mobile: e.target.value})}/>
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Възраст</Form.Label>
                                            <Form.Control name={"age"} value={editItem.age}
                                                          onChange={(e) => setEditItem({...editItem, age: e.target.value})}
                                                          onBlur={() => setEditItem({...editItem, age: validateNumber(editItem.age)})}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Отстъпка % <small>(за естетична клиника)</small></Form.Label>
                                            <Form.Control name={"discount"} value={zeroToEmptyString(editItem.discount)}
                                                          onChange={(e) => setEditItem({...editItem, discount: e.target.value})}
                                                          onBlur={() => setEditItem({...editItem, discount: validateNumber(editItem.discount)})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>


                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <Form.Group className="mb-3">*/}
                                {/*            <Form.Label>Валидна от</Form.Label>*/}
                                {/*            <Form.Control type="text" value={editItem.validFrom}*/}
                                {/*                          placeholder={"формат: дд.мм.гггг"}*/}
                                {/*                          onChange={(e) => setEditItem({...editItem, validFrom: e.target.value})}*/}
                                {/*                          onBlur={() => setEditItem({...editItem, validFrom: validateDate(editItem.validFrom)})}*/}
                                {/*            />*/}
                                {/*        </Form.Group>*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <Form.Group className="mb-3">*/}
                                {/*            <Form.Label>Валидна до</Form.Label>*/}

                                {/*            <Form.Control type="text" value={editItem.validTo}*/}
                                {/*                          placeholder={"формат: дд.мм.гггг"}*/}
                                {/*                          onChange={(e) => setEditItem({...editItem, validTo: e.target.value})}*/}
                                {/*                          onBlur={() => setEditItem({...editItem, validTo: validateDate(editItem.validTo)})}*/}
                                {/*            />*/}
                                {/*        </Form.Group>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}

                            </Card.Body>
                            <Card.Footer>
                                {
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button type={"submit"}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>&nbsp;
                                            <Button onClick={() => onCancel()} variant={"secondary"}><FontAwesomeIcon
                                                icon={faCancel}/> Откажи</Button>
                                            <Button onClick={() => setDeleteFlag(true)} variant={"danger"} style={{ float: "right"}}>
                                                <FontAwesomeIcon
                                                icon={faTrash}/> Изтрий</Button>
                                        </>
                                }
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>

            {
                deleteFlag && <ConfirmDeleteModal working={working} onConfirm={onDelete} onReject={() => setDeleteFlag(false)} />
            }
        </>
    )
}
