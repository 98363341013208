import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faPencil, faPlus, faUser} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError, zeroToEmptyString, zeroToEmptyStringAsNumber
} from "../../../common";
import {API, API_CALL} from "../../../api";
import ConfigureClientDetails from "./ConfigClientDetails";


export interface ClientItem {
    id: number;
    name: string;
    gender: 'мъж' | 'жена';
    age: string;
    city: string;
    mobile: string;
    discount: string;
    validFrom: string;
    validTo: string;
    prepaid: number;
}

export const EMPTY_CLIENT_ITEM: ClientItem = {
    id: -1, name: '', gender: 'жена', age: '', city: '', mobile: '', discount: '', validFrom: '', validTo: '', prepaid: 0
}

export interface ConfigClientsProps { onSelect?: (c: ClientItem) => any, onClose: () => any, zIndex?: number }

export default function ConfigClientsForm({onClose, zIndex, onSelect}: ConfigClientsProps) {

    const [clients, setClients] = useState<Array<ClientItem>>([]);
    const [clientsList, setClientsList] = useState<Array<ClientItem>>([]);
    const [editClient, setEditClient] = useState<ClientItem | null>(null);
    const [working, setWorking] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadClients()
    }, []);

    useEffect(() => {
        const _list = clients.filter(p => p.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
        setClientsList(_list);
    }, [clients, filter])

    function loadClients() {
        API.getClients(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setClients(api_call.data.data.clients);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на пациентите!', translateError)
            }
        )
    }


    function saveClient(m: ClientItem) {
        API.postClient(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    setEditClient(null);
                    loadClients();
                }
            }, m
        );
    }

    function deleteClient() {
        setWorking(true);

        API.deleteClient(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване', translateError);
                if (isSuccess(apiCall)) {
                    setEditClient(null);
                    loadClients();
                }
            }, editClient
        );
    }

    return (<>
            <Modal show={true} size={"xl"} onHide={onClose} style={{zIndex: zIndex ? zIndex : 1500}} >
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faUser} className={"mr-3"}/>Номенклатура пациенти</Modal.Title>
                    <CloseButton onClick={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Card className={"border-0"}>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" value={filter}
                                                      placeholder={"Филтър..."}
                                                      onChange={(e) => setFilter(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="table-container max-h-44vh">
                            <Table  size={"sm"} hover bordered className={"text-light"}>
                                <thead>
                                <tr className={"bg-secondary"}>
                                    <th>Име на пациента</th>
                                    <th>Град</th>
                                    <th>Телефон</th>
                                    <th className={"text-right"}>Възраст</th>
                                    <th className={"text-right"}>Отстъпка</th>
                                    <th>Пол</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody className={"text-dark"}>
                                {
                                    clientsList?.length > 0 ?
                                        clientsList.map(
                                            m => <tr key={m.id} style={{lineHeight: "2rem"}}>
                                                <td onClick={() => { if(onSelect) onSelect(m)} }>{m.name}</td>
                                                <td onClick={() => { if(onSelect) onSelect(m)} }>{m.city}</td>
                                                <td onClick={() => { if(onSelect) onSelect(m)} }>{m.mobile}</td>
                                                <td onClick={() => { if(onSelect) onSelect(m)} } className={"text-right"}>{zeroToEmptyString(m.age)}</td>
                                                <td onClick={() => { if(onSelect) onSelect(m)} } className={"text-right"}>{zeroToEmptyString(m.discount, '%')}</td>
                                                <td onClick={() => { if(onSelect) onSelect(m)} }>{m.gender}</td>
                                                <td className={"text-center"}>
                                                    <Button variant={"outline-secondary"} size={"sm"} className={"m-0"}
                                                            onClick={() => setEditClient(m)}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil}/>
                                                    </Button>
                                                    &nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button variant={"outline-secondary"} size={"sm"}
                                                                className={"m-0"}
                                                                onClick={() => onSelect(m)}
                                                        >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={4}>{!working && <>Няма добавени пациенти</>}</td>
                                        </tr>
                                }
                                </tbody>
                            </Table>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            {
                                working && !editClient ? <Spinner animation="border"/> :
                                    <>
                                        {
                                            <Button
                                                onClick={
                                                    () => setEditClient({
                                                        ...EMPTY_CLIENT_ITEM
                                                    })
                                                }><FontAwesomeIcon
                                                icon={faPlus}/> Добави пациент</Button>
                                        }
                                    </>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>


            {
                editClient &&
                <ConfigureClientDetails item={editClient} working={working}
                                        onSave={(m) => saveClient(m)}
                                        onDelete={() => deleteClient()}
                                        onCancel={() => setEditClient(null)}
                                        zIndex={zIndex}
                />
            }
        </>
    )
}
