import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faSave, faStore, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {showErrorsListInToast} from "../../../common";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";
import moment from "moment";
import {MachineItem} from "./ConfigMachinesForm";


export interface ConfigMachineDetailsProps {
    working: boolean;
    item: MachineItem;
    onSave: (item: MachineItem) => any;
    onDelete: () => any;
    onCancel: () => any;
    zIndex?: number
}

export default function ConfigureMachineDetails({working, item, onSave, onDelete, onCancel, zIndex}: ConfigMachineDetailsProps) {
    const [editItem, setEditItem] = useState<MachineItem>({...item});
    const [deleteFlag, setDeleteFlag] = useState(false);

    function save(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];

        editItem.name = editItem.name?.trim();
        if(editItem.name?.length === 0) errors.push("Въведете наименование.");

        if(errors.length === 0) {
            onSave(editItem);
        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    function validateDate(date: string) {
        const d = moment(date, 'DD.MM.YYYY');
        return d.isValid() ? d.format('DD.MM.YYYY') : '';
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: zIndex || 1500}} />
            <Modal show={!deleteFlag} size={"lg"} onHide={onCancel} style={{zIndex: zIndex ? zIndex+1:1501}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faStore} className={"mr-3"}/>Детайли за
                        апарат</Modal.Title>
                    <CloseButton onClick={onCancel}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={save}>
                        <Card className={"border-0"}>
                            <Card.Body className={"max-h-60vh scrollable"}>
                                <Form.Group className="mb-3" >
                                    <Form.Label>Наименование</Form.Label>
                                    <Form.Control name={"name"} value={editItem.name}
                                                  onChange={(e) => setEditItem({...editItem, name: e.target.value})}/>
                                </Form.Group>

                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Валидна от</Form.Label>
                                            <Form.Control type="text" value={editItem.validFrom}
                                                          placeholder={"формат: дд.мм.гггг"}
                                                          onChange={(e) => setEditItem({...editItem, validFrom: e.target.value})}
                                                          onBlur={() => setEditItem({...editItem, validFrom: validateDate(editItem.validFrom)})}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Валидна до</Form.Label>

                                            <Form.Control type="text" value={editItem.validTo}
                                                          placeholder={"формат: дд.мм.гггг"}
                                                          onChange={(e) => setEditItem({...editItem, validTo: e.target.value})}
                                                          onBlur={() => setEditItem({...editItem, validTo: validateDate(editItem.validTo)})}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Card.Body>
                            <Card.Footer>
                                {
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button type={"submit"}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>&nbsp;
                                            <Button onClick={() => onCancel()} variant={"secondary"}><FontAwesomeIcon
                                                icon={faCancel}/> Откажи</Button>
                                            <Button onClick={() => setDeleteFlag(true)} variant={"danger"} style={{ float: "right"}}>
                                                <FontAwesomeIcon
                                                icon={faTrash}/> Изтрий</Button>
                                        </>
                                }
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>

            {
                deleteFlag && <ConfirmDeleteModal working={working} onConfirm={onDelete} onReject={() => setDeleteFlag(false)} />
            }
        </>
    )
}
