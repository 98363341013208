import {AxiosResponse} from "axios";
import {API_CALL, NET_STATUS} from "./api";

export const defaultAxiosResponse: AxiosResponse<any> = {
    data: null,
    config: {},
    headers: null,
    status: -1,
    statusText: ''
}

export const FACTORY_CLASS = class {
    createApiCall(status = NET_STATUS.NOT_STARTED, data = defaultAxiosResponse, error = null, onChange = () => {}): API_CALL {
        return {
            status: status,
            data: data,
            error: error,
            onChange: onChange
        }
    }
}

export const FACTORY = new FACTORY_CLASS();


export function getClinicName(clinicIndex: number) {
    switch (clinicIndex) {
        case 0: return "Пловдив"
        case 1: return "Варна"
    }
}
