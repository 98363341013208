import {Button, Card, Col, Container, Row, Tab, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faPrint, faRotate, faTeethOpen, faTrash, faUser} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import ConfigClientsForm, {ClientItem} from "../config/clients/ConfigClientsForm";
import ClientDetails from "./ClientDetails";
import ClientDentalRecords from "./ClientDentalRecords";
import UploadFilesDialog from "../../common/upload-files-dialog/UploadFilesDialog";
import ClientRecordForm from "./ClientRecordForm";
import {API, API_CALL} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import moment from "moment";
import {ConfirmDeleteModal} from "../../common/ConfirmDeleteModal";

export interface ClientRecord {
    id: number;
    clientId: number;
    recordDate: Date;
    treatmentPlan: string;
}


export default function DentalClinicPage() {
    const [working, setWorking] = useState(false);
    const [selectClientFlag, setSelectClientFlag] = useState(false);
    const [removeRecordFlag, setRemoveRecordFlag] = useState(false);
    const [addRecordFlag, setAddRecordFlag] = useState(false);
    const [client, setClient] = useState<ClientItem | null>(null);
    const [prepaid, setPrepaid] = useState(0);
    const [activeRecord, setActiveRecord] = useState<ClientRecord | null>(null);

    const [records, setRecords] = useState<Array<ClientRecord>>([]);

    function loadClientRecords() {
        API.getClientDentalRecords(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно зареждане на данните.', translateError);
                if (isSuccess(apiCall)) {
                    setRecords(apiCall.data.data.records.map(
                        (r: any) => {
                            return {
                                ...r, recordDate: moment(r.recordDate, 'DD.MM.YYYY').toDate()
                            } as ClientRecord;
                        }
                    ));
                }
            }, client!.id
        );
    }

    useEffect(() => {
        if(client) {
            setPrepaid(client.prepaid);
            loadClientRecords();
        }
    }, [client]);


    function removeActiveRecord() {
        API.deleteClientDentalRecord(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване.', translateError);
                if (isSuccess(apiCall)) {
                    setRemoveRecordFlag(false);
                    loadClientRecords();
                }
            }, activeRecord
        );
    }

    return <>
        <Container fluid>
            <Card className={"border-0"}>
                <Card.Header className={"d-print-none"}>
                    <Row className={"pt-2 pb-0"}>
                        <Col>
                            <h5 className={"text-uppercase font-weight-bold"}>
                                <FontAwesomeIcon icon={faTeethOpen} className={"mr-3"}/>Досиета <span
                                className={"text-primary"}>дентална клиника</span>
                            </h5>
                        </Col>
                        <Col xs={"auto"} style={{lineHeight: "2.25rem"}}>
                        </Col>
                    </Row>
                </Card.Header>
                <Card.Body className={"max-h-60vh scrollable"}>
                    <div className={"d-print-none"}>
                        <ClientDetails client={ client ? {...client!, prepaid: prepaid} : null}
                                       onUpdateBalance={ newBalance => setPrepaid(newBalance) }
                        />
                    </div>
                    { client && <ClientDentalRecords records={records}
                                                     clientName={client.name || ""}
                                                     clientPrepaid={prepaid}
                                                     onRecordChanged={record => setActiveRecord(record)}
                                                     onReload={loadClientRecords}
                                                     onUpdatePrepaid={balance => setPrepaid(balance) }/> }
                </Card.Body>
                <Card.Footer>
                    <Row>
                        {
                            client && <>
                                {
                                    activeRecord &&
                                    <Col xs={"auto"}>
                                        <Button variant={"outline-secondary"}
                                            onClick={() => loadClientRecords()}
                                        ><FontAwesomeIcon
                                            icon={faRotate}/></Button> &nbsp;
                                        <Button variant={"outline-secondary"}
                                            onClick={() => window.print()}
                                        ><FontAwesomeIcon
                                            icon={faPrint}/></Button> &nbsp;
                                        <Button
                                            onClick={() => setAddRecordFlag(true)}
                                        ><FontAwesomeIcon
                                            icon={faPencil}/>&nbsp;&nbsp;Коригирай</Button> &nbsp;
                                        <Button variant={"danger"}
                                                onClick={() => setRemoveRecordFlag(true)}
                                        ><FontAwesomeIcon
                                            icon={faTrash}/>&nbsp;&nbsp;Изтрий</Button> &nbsp;
                                    </Col>
                                }
                                <Col>
                                <Button
                                    onClick={() => {
                                        setActiveRecord(null);
                                        setAddRecordFlag(true)
                                    }}
                                ><FontAwesomeIcon
                                    icon={faTeethOpen}/>&nbsp;&nbsp;Започни лечение</Button> &nbsp;

                            </Col>
                            </>
                        }
                        <Col xs={"auto"}>
                            <Button
                                onClick={() => setSelectClientFlag(true)}
                            ><FontAwesomeIcon
                                icon={faUser}/>&nbsp;&nbsp;Избери пациент</Button> &nbsp;

                        </Col>
                    </Row>
                </Card.Footer>
            </Card>

        </Container>
        {
            selectClientFlag &&
            <ConfigClientsForm onClose={() => setSelectClientFlag(false)}
                               zIndex={1600}
                               onSelect={(c) => {
                                   setClient(c);
                                   setSelectClientFlag(false);
                               }}
            />
        }
        {
            addRecordFlag &&
            <ClientRecordForm  zIndex={1600}
                               clientName={client?.name || ""}
                               record={ activeRecord || {
                                   id: -1, clientId: client!.id, treatmentPlan: "", recordDate: new Date()
                               }}
                               onClose={c => {
                                   setAddRecordFlag(false);
                                   loadClientRecords();
                               }}
            />
        }
        {
            removeRecordFlag &&
            <ConfirmDeleteModal working={working} onConfirm={removeActiveRecord} onReject={() => setRemoveRecordFlag(false)} />
        }

    </>
}
