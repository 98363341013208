import VouchersDialog, {VoucherItemGroupType, VoucherItemType, VoucherType} from "./VouchersDialog";
import {Button, Col, Container, Form, InputGroup, Row, Spinner, Table} from "react-bootstrap";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {dateToString, textToFloatOrZero, zeroToEmptyStringAsNumber} from "../../common";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCopy, faList, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import ConfigProceduresForm from "../config/procedures/ConfigProceduresForm";
import ConfigMaterialsForm from "../config/materials/ConfigMaterialsForm";
import ConfigInfusionsForm from "../config/infusions/ConfigInfusionsForm";
import {SelectedProcedureItem} from "../records/RecordDetailsPage";
import {CacheInput} from "../../common/CacheInput";

function VouchersDialogEdit(props: {
    voucher: VoucherType, onSave: (newVoucher: VoucherType) => void,
    onDone: () => void, working: boolean
}) {
    const [editRecord, setEditRecord] = useState<VoucherType>(props.voucher);
    const [isValid, setIsValid] = useState(props.voucher?.isValid);

    const [selectItemFlag, setSelectItemFlag] = useState(false);
    const [selectItemType, setSelectItemType] = useState("");
    const [selectVoucherFlag, setSelectVoucherFlag] = useState(false);

    useEffect(() => setEditRecord(props.voucher), [props.voucher]);

    function done() {
        props.onDone();
    }

    function save(e: any) {
        e.preventDefault();

        const rec = {...editRecord};
        rec.number = e.target.elements.number.value;
        rec.buyer = e.target.elements.buyer.value;
        rec.recipient = e.target.elements.recipient.value;
        rec.amount = parseFloat(e.target.elements.amount.value) || 0;
        rec.payedByCard = parseFloat(e.target.elements.payedByCard.value) || 0;
        rec.payedByCash = parseFloat(e.target.elements.payedByCash.value) || 0;
        rec.isValid = isValid;

        props.onSave(rec!);
    }

    function addItemToRecord(p: SelectedProcedureItem, group: VoucherItemGroupType) {
        const items = [...editRecord.items || []];
        items.push({
            itemGroup: group,
            itemId: p.itemId,
            itemName: p.itemName,
            usedOn: null,
        } as VoucherItemType);

        setEditRecord({...editRecord, items: items});
    }

    function removeItem(index: number) {
        const items = [...editRecord.items];
        items.splice(index, 1);
        setEditRecord({...editRecord, items: items});
    }

    function copyItem(index: number): void {
        const items = [...editRecord.items];
        items.push({...items[index], usedOn: null});
        setEditRecord({...editRecord, items: items});
    }

    return <Container>
        <Form onSubmit={save}>
            <Row className={"mb-3 border-bottom"}>
                <Col>
                    <h5>Детайли за {editRecord.voucherType === "VOUCHER" ? "ваучер" : "пакет"}</h5>
                </Col>
            </Row>

            <Row className={"m-0"}>
                <Col xs={2}>
                    <Form.Label>Номер:</Form.Label>
                    <Form.Control name={"number"} defaultValue={props.voucher?.number} disabled={props.working}/>
                </Col>
                <Col xs={2}>
                    <Form.Label>Дата:</Form.Label>
                    <ReadOnlyDatePickerComponent onChange={(d: Date) => setEditRecord({...editRecord, date: d})}
                                                 value={dateToString(editRecord.date)}
                                                 hideDayControls
                    />
                </Col>
                <Col xs={4}>
                    <Form.Label>Име на купувача:</Form.Label>
                    <Form.Control name={"buyer"} defaultValue={props.voucher?.buyer} disabled={props.working}/>
                </Col>
                <Col xs={4}>
                    <Form.Label>Име на получателя:</Form.Label>
                    <Form.Control name={"recipient"} defaultValue={props.voucher?.recipient} disabled={props.working}/>
                </Col>
            </Row>

            {
                editRecord.voucherType === "PACKET" &&
                <Row className={"m-0 mb-2 pt-1 border"}>
                    <Col>
                        <strong>Дейности включени в пакета:</strong>
                        <Table size={"sm"} hover bordered className={"small"}>
                            <thead className={"bg-secondary-light font-weight-bold"}>
                            <tr>
                                <th>Вид</th>
                                <th>Дейност</th>
                                <th className={"text-nowrap"}>Използван на</th>
                                <th className={"w-100px"}></th>
                            </tr>
                            </thead>
                            <tbody>
                            {
                                !editRecord.items || editRecord.items.length === 0 ?
                                    <tr>
                                        <td colSpan={3} className={"text-center font-weight-bold"}>Няма включени
                                            дейности
                                        </td>
                                    </tr>
                                    :
                                    editRecord.items.map(
                                        (value, index) =>
                                            <tr key={index}>
                                                <td> {value.itemGroup} </td>
                                                <td> {value.itemName} </td>
                                                <td> {value.usedOn} </td>
                                                <td className={"w-50px text-right small"}
                                                    style={{lineHeight: "0.75rem"}}>
                                                    <Button size={"sm"} variant={"secondary"}
                                                            onClick={() => copyItem(index)}>
                                                        <FontAwesomeIcon icon={faCopy} size={"sm"}/>
                                                    </Button>
                                                    {
                                                        !value.usedOn &&
                                                        <>
                                                            &nbsp;<Button size={"sm"} variant={"danger"}
                                                                          onClick={() => removeItem(index)}>
                                                            <FontAwesomeIcon icon={faTrash} size={"sm"}/>
                                                        </Button>
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                    )
                            }
                            </tbody>
                        </Table>

                        <Row className={"mb-2"}>
                            <Col>
                                <Button size={"sm"} variant={"outline-success"}
                                        onClick={() => {
                                            setSelectItemFlag(true);
                                            setSelectItemType("процедура")
                                        }}
                                >
                                    <FontAwesomeIcon icon={faPlus}/> Процедура
                                </Button>&nbsp;

                                <Button size={"sm"} variant={"outline-info"}
                                        onClick={() => {
                                            setSelectItemFlag(true);
                                            setSelectItemType("филър")
                                        }}
                                >
                                    <FontAwesomeIcon icon={faPlus}/> Филър
                                </Button>&nbsp;

                                <Button size={"sm"} variant={"outline-warning"}
                                        onClick={() => {
                                            setSelectItemFlag(true);
                                            setSelectItemType("вливка")
                                        }}
                                >
                                    <FontAwesomeIcon icon={faPlus}/> Вливка
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            }

            <Row className={"m-0"}>
                <Col xs={3}>
                    <Form.Label>Стойност:</Form.Label>
                    <Form.Control name={"amount"} type={"number"}
                                  defaultValue={zeroToEmptyStringAsNumber(props.voucher?.amount)}
                                  onChange={e => setEditRecord({...editRecord, amount: textToFloatOrZero(e.target.value)})}
                                  disabled={props.working}/>
                </Col>
                <Col xs={3}>
                    <Form.Label>Платено в брой:</Form.Label>
                    <Form.Control name={"payedByCash"} type={"number"}
                                  defaultValue={zeroToEmptyStringAsNumber(props.voucher?.payedByCash)}
                                  disabled={props.working}/>
                </Col>
                <Col xs={3}>
                    <Form.Label>Платено с карта:</Form.Label>
                    <Form.Control name={"payedByCard"} type={"number"}
                                  defaultValue={zeroToEmptyStringAsNumber(props.voucher?.payedByCard)}
                                  disabled={props.working}/>
                </Col>
                {
                    editRecord.voucherType === "PACKET" &&
                    <Col xs={3}>
                        <Form.Group className="mb-2">
                            <Form.Label>Платено с ваучер:</Form.Label>
                            <InputGroup className="mb-3">
                                {
                                    editRecord.voucher &&
                                    <InputGroup.Text
                                        id="basic-addon2">#{editRecord.voucher.number} </InputGroup.Text>
                                }
                                <CacheInput
                                    value={zeroToEmptyStringAsNumber(editRecord.paidByVoucher)}
                                    disabled={!editRecord.voucher}
                                    onChange={newValue => {
                                        let _value = textToFloatOrZero(newValue);
                                        let _paidByVoucher = Number(editRecord.paidByVoucher);

                                        const _freeAmount = editRecord!.voucher!.amount - editRecord!.voucher!.usedAmount + _paidByVoucher;
                                        if (_freeAmount < _value) {
                                            _value = _freeAmount;
                                        }
                                        setEditRecord({...editRecord, paidByVoucher: _value});
                                    }}/>

                                <Button variant="outline-secondary"
                                        onClick={() => setSelectVoucherFlag(true)}>
                                    <FontAwesomeIcon icon={faList}/>
                                </Button>
                                <Button variant="outline-secondary"
                                        onClick={() => setEditRecord({...editRecord, voucher: undefined, voucherId: undefined, paidByVoucher: 0})}>
                                    <FontAwesomeIcon icon={faCancel}/>
                                </Button>
                            </InputGroup>
                        </Form.Group>
                    </Col>
                }
                <Col xs={3}>
                    <Form.Label>Състояние:</Form.Label>
                    <Form.Check label={"ВАЛИДЕН"}
                                checked={isValid}
                                onChange={e => setIsValid(e.target.checked)}
                                name={"isValid"} className={"font-weight-bold text-danger"} disabled={props.working}/>
                </Col>
            </Row>
            {
                props.working ?
                    <Row className={"mt-3"}>
                        <Col className={"text-center"}>
                            <Spinner animation={"border"}/>
                        </Col>
                    </Row>
                    :
                    <Row className={"mt-3"}>
                        <Col xs={"auto"}>
                        </Col>
                        <Col className={"text-right"}>
                            <Button type={"submit"} disabled={Number(editRecord.usedAmount) !== 0}>Запази</Button>&nbsp;&nbsp;
                            <Button onClick={done} variant={"secondary"}>Откажи</Button>
                        </Col>
                    </Row>
            }
        </Form>

        {
            selectItemFlag && selectItemType === "процедура" &&
            <ConfigProceduresForm onClose={() => setSelectItemFlag(false)}
                                  zIndex={1610}
                                  onSelect={(p) => {
                                      setSelectItemFlag(false);
                                      addItemToRecord(p, "Процедура");
                                  }}
            />
        }

        {
            selectItemFlag && selectItemType === "филър" &&
            <ConfigMaterialsForm onClose={() => setSelectItemFlag(false)}
                                 zIndex={1610}
                                 onlyFillers={true}
                                 onSelect={(p) => {
                                     setSelectItemFlag(false)
                                     addItemToRecord(p, "Филър");
                                 }}
            />
        }

        {
            selectItemFlag && selectItemType === "вливка" &&
            <ConfigInfusionsForm onClose={() => setSelectItemFlag(false)}
                                 zIndex={1610}
                                 onSelect={(p) => {
                                     setSelectItemFlag(false)
                                     addItemToRecord(p, "Вливка");
                                 }}
            />
        }

        {
            selectVoucherFlag &&
            <VouchersDialog onClose={() => setSelectVoucherFlag(false)}
                            zIndex={1610} type={"VOUCHER"}
                            onSelect={(v) => {
                                const sellPrice = editRecord.amount;
                                let voucherAmount = Number(v.amount);

                                if (voucherAmount > sellPrice) voucherAmount = sellPrice;
                                setEditRecord({
                                    ...editRecord, voucher: v, paidByVoucher: voucherAmount
                                });
                                setSelectVoucherFlag(false)
                            }}
                            recordItemQty={1}
            />
        }

    </Container>;
}

export default VouchersDialogEdit

