import {
    Button,
    Card,
    CloseButton,
    Col,
    Form, FormControl,
    Modal,
    Row,
    Spinner, Table,
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faPlus, faSave, faSearch, faTrash, faUser} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import React, {useEffect, useState} from "react";
import {DailyReportItem} from "./DailyReportPage";
import {ConfirmDeleteModal} from "../../common/ConfirmDeleteModal";
import {EMPTY_ACCOUNT} from "../../types";
import SelectUserComponent from "../config/users/SelectUserComponent";
import ConfigClientsForm from "../config/clients/ConfigClientsForm";
import ConfigProceduresForm from "../config/procedures/ConfigProceduresForm";
import ConfigMachinesForm from "../config/machines/ConfigMachinesForm";
import ConfigMaterialsForm, {MaterialItem} from "../config/materials/ConfigMaterialsForm";
import {validateNumber} from "../../validations";
import {showErrorsListInToast} from "../../common";
import ValueViewer from "../../common/ValueViewer";

export interface DailyReportItemProps {
    item: DailyReportItem;
    onSave: (item: DailyReportItem) => any;
    onCancel: () => any;
    onDelete: () => any;
}

export default function DailyReportItemPage({item, onSave, onDelete, onCancel}: DailyReportItemProps) {
    // const [doctors, setDoctors] = useState<Array<Account>>([]);
    const [editItem, setEditItem] = useState<DailyReportItem>(item);
    // const [loading, setLoading] = useState(false);
    const [working, setWorking] = useState(false);
    const [selectDoctorFlag, setSelectDoctorFlag] = useState(false);
    const [selectClientFlag, setSelectClientFlag] = useState(false);
    const [selectProcedureFlag, setSelectProcedureFlag] = useState(false);
    const [selectMachineFlag, setSelectMachineFlag] = useState(false);
    const [selectMaterialFlag, setSelectMaterialFlag] = useState(false);
    const [deleteFlag, setDeleteFlag] = useState(false);


    useEffect(() => {
        calculateTotals();
        setEditItem({...editItem});
    }, [selectMaterialFlag]);


    function calculateTotals() {
        let costs = 0;
        let sellPrice = 0;

        if (!editItem) return;

        editItem?.materials?.forEach(
            m => {
                // @ts-ignore
                const exp = m.qty * 1 * m.expense;
                if(!isNaN(exp)) {
                    costs += exp;
                }
                // @ts-ignore
                const price = m.qty * 1 * m.sellPrice;
                if(!isNaN(price)) {
                    sellPrice += price;
                }
            }
        );

        editItem.costs = costs.toFixed(2);
        editItem.sellPrice = sellPrice.toFixed(2);
    }




    function save(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];

        // if(!editItem.doctor) {
        //     errors.push("Моля, изберете терапевт.")
        // }
        //
        // if(!editItem.client) {
        //     errors.push("Моля, изберете клиент.")
        // }
        //
        // if(!editItem.procedure) {
        //     errors.push("Моля, изберете процедура.")
        // }
        //
        // if(!editItem.machine) {
        //     errors.push("Моля, изберете апарат.")
        // }


        if (errors.length === 0) {
            onSave(editItem);
        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    function findDoctor(value: string) {
        return EMPTY_ACCOUNT;
    }

    function removeMaterial(matIdx: number) {
        editItem.materials.splice(matIdx, 1);
        calculateTotals();
        setEditItem({...editItem});
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: 1500}}/>
            <Modal show={!deleteFlag} size={"lg"} onHide={onCancel} style={{zIndex: 1501}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faUser} className={"mr-3"}/>Дневен отчет - детайли</Modal.Title>
                    <CloseButton onClick={onCancel}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={save}>
                        <Card className={"border-0"}>
                            <Card.Body className={"max-h-60vh scrollable"}>
                                <Form.Group className="mb-3 w-100">
                                    <Form.Label className={"font-weight-bold"}>Терапевт</Form.Label>
                                    <Row>
                                        <Col className={"bg-secondary-light"} onClick={() => setSelectDoctorFlag(true)}>
                                            <ValueViewer value={editItem.doctor?.name} /></Col>
                                        <Col xs={"auto"}>
                                            <Button
                                                onClick={() => setSelectDoctorFlag(true)}
                                            >...</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Пациент</Form.Label>
                                    <Row>
                                        <Col className={"bg-secondary-light"} onClick={() => setSelectClientFlag(true)}>
                                            <ValueViewer value={editItem.client?.name} />
                                        </Col>
                                        <Col xs={"auto"}>
                                            <Button
                                                onClick={() => setSelectClientFlag(true)}
                                            >...</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Процедура</Form.Label>
                                    <Row>
                                        <Col className={"bg-secondary-light"} onClick={() => setSelectProcedureFlag(true)}>
                                            <ValueViewer value={editItem.procedure?.name} /></Col>
                                        <Col xs={"auto"}>
                                            <Button
                                                onClick={() => setSelectProcedureFlag(true)}
                                            >...</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Апарат</Form.Label>
                                    <Row>
                                        <Col className={"bg-secondary-light"} onClick={() => setSelectMachineFlag(true)}>
                                            <ValueViewer value={editItem.machine?.name} /></Col>
                                        <Col xs={"auto"}>
                                            <Button
                                                onClick={() => setSelectMachineFlag(true)}
                                            >...</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Материали</Form.Label>

                                    <Table size={"sm"} hover bordered>
                                        <thead>
                                        <tr className={"bg-secondary text-light"}>
                                            <th>Наименование</th>
                                            <th>М/Е</th>
                                            <th className={"text-right"}>Количество</th>
                                            <th className={"text-right"}>Разход (лв)</th>
                                            <th className={"text-right"}>Прод.цена (лв)</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            editItem.materials.length > 0 ?
                                                <>
                                                {
                                                    editItem.materials.map(
                                                        (m, idx) => <tr key={m.id}>
                                                            <td>{m.name}</td>
                                                            <td>{m.me}</td>
                                                            <td className={"w-100px text-right"}>
                                                                <FormControl
                                                                    value={m.qty}
                                                                    onChange={(e) => {
                                                                        m.qty = e.target.value;
                                                                        setEditItem({...editItem});
                                                                    }}
                                                                    onBlur={() => {
                                                                        m.qty = validateNumber(m.qty);
                                                                        calculateTotals();
                                                                        setEditItem({...editItem});
                                                                    }}
                                                                    className={"text-right"}
                                                                />
                                                            </td>
                                                            <td className={"text-right"}>{ m.expense?.toFixed(2) }</td>
                                                            <td className={"text-right"}>{ m.sellPrice?.toFixed(2) }</td>
                                                            <td className={"text-center"}>
                                                                <Button
                                                                    size={"sm"}
                                                                    variant={"outline-danger"}
                                                                    onClick={() => removeMaterial(idx)}
                                                                >
                                                                    <FontAwesomeIcon icon={faTrash}/>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }

                                                </>
                                                :
                                                <tr>
                                                    <td colSpan={5}>Няма използвани материали</td>
                                                </tr>
                                        }
                                        <tr className={"bg-secondary-light"}>
                                            <td colSpan={5}>Добавяне на материал</td>
                                            <td className={"text-center"}>
                                                <Button
                                                    variant={"success"}
                                                    size={"sm"}
                                                    onClick={() => setSelectMaterialFlag(true)}
                                                >
                                                    <FontAwesomeIcon icon={faPlus}/>
                                                </Button>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Отстъпка (%)</Form.Label>
                                    <Form.Control value={editItem.discount}
                                                  onChange={(e) => setEditItem({...editItem, discount: e.target.value})}
                                                                    onBlur={() => setEditItem({
                                                                        ...editItem,
                                                                        discount: validateNumber(editItem.discount)
                                                                    })}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Всичко разход (лв)</Form.Label>
                                    <Form.Control value={editItem.costs} disabled
                                                  onChange={(e) => setEditItem({...editItem, costs: e.target.value})}
                                                  onBlur={() => setEditItem({
                                                      ...editItem,
                                                      costs: validateNumber(editItem.costs)
                                                  })}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Всичко цена (лв)</Form.Label>
                                    <Form.Control value={editItem.sellPrice} disabled
                                                  onChange={(e) => setEditItem({...editItem, sellPrice: e.target.value})}
                                                  onBlur={() => setEditItem({
                                                      ...editItem,
                                                      sellPrice: validateNumber(editItem.sellPrice)
                                                  })}
                                    />
                                </Form.Group>


                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Платени в брой</Form.Label>
                                    <Form.Control value={editItem.payedCash}
                                                  onChange={(e) => setEditItem({...editItem, payedCash: e.target.value})}
                                                  onBlur={() => setEditItem({
                                                      ...editItem,
                                                      payedCash: validateNumber(editItem.payedCash)
                                                  })}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Платени с карта</Form.Label>
                                    <Form.Control value={editItem.payedCard}
                                                  onChange={(e) => setEditItem({...editItem, payedCard: e.target.value})}
                                                  onBlur={() => setEditItem({
                                                      ...editItem,
                                                      payedCard: validateNumber(editItem.payedCard)
                                                  })}
                                    />
                                </Form.Group>


                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Коментари и забележки</Form.Label>
                                    <Form.Control value={editItem.comments} as={"textarea"}
                                                  onChange={(e) => setEditItem({...editItem, comments: e.target.value})}
                                    />
                                </Form.Group>

                            </Card.Body>
                            <Card.Footer>
                                {
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button type={"submit"}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>&nbsp;
                                            <Button onClick={() => onCancel()} variant={"secondary"}><FontAwesomeIcon
                                                icon={faCancel}/> Откажи</Button>
                                            <Button onClick={() => setDeleteFlag(true)} variant={"danger"}
                                                    style={{float: "right"}}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}/> Изтрий</Button>
                                        </>
                                }
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>

            {
                selectDoctorFlag &&
                <>
                <Modal show={true} style={{zIndex: 1505}}>
                    <Modal.Body>
                        <SelectUserComponent forDate={editItem.reportDate}
                                             operators={true}
                                             onSelect={(u) => {
                                                 setEditItem({...editItem, doctor: u})
                                                 setSelectDoctorFlag(false)
                                             }}
                                             onCancel={() => setSelectDoctorFlag(false)}
                        />
                    </Modal.Body>
                </Modal>
                </>
            }

            {
                selectClientFlag &&
                <ConfigClientsForm onClose={() => setSelectClientFlag(false)}
                                   zIndex={1600}
                                   onSelect={(c) => {
                                       setEditItem({...editItem, client: c});
                                       setSelectClientFlag(false)
                                   }}
                />
            }

            {
                // selectProcedureFlag &&
                // <ConfigProceduresForm onClose={() => setSelectProcedureFlag(false)}
                //                    zIndex={1600}
                //                    onSelect={(p) => {
                //                        setEditItem({...editItem, procedure: p});
                //                        setSelectProcedureFlag(false)
                //                    }}
                // />
            }

            {
                selectMachineFlag &&
                <ConfigMachinesForm onClose={() => setSelectMachineFlag(false)}
                                   zIndex={1600}
                                   onSelect={(m) => {
                                       setEditItem({...editItem, machine: m});
                                       setSelectMachineFlag(false)
                                   }}
                />
            }



            {
                deleteFlag &&
                <ConfirmDeleteModal working={working} onConfirm={onDelete} onReject={() => setDeleteFlag(false)}/>
            }
        </>
    )

}
