import {Form} from "react-bootstrap";
import React, {useEffect, useState} from "react";


export interface StringInputProps {
    label?: string;
    value: string;
    disabled?: boolean;
    placeholder?: string;
    onChange: (newValue: string) => any;
}

export function StringInput({label, value, placeholder, disabled, onChange}: StringInputProps) {
    const [editValue, setEditValue] = useState(value);

    useEffect(() => {
        setEditValue(value);
    }, [value]);

    return (
        <Form.Group className="mb-3">
            {
                label && <Form.Label>{ label }</Form.Label>
            }
            <Form.Control value={editValue} placeholder={placeholder}
                          onChange={(e) => setEditValue(e.target.value)}
                          disabled={disabled}
                          onBlur={() => {
                              if(editValue !== value) {
                                  onChange(editValue)
                              }
                          }}
            />
        </Form.Group>
    )
}
