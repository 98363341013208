import {Pagination} from "react-bootstrap";
import React from "react";


export interface PaginationData {
    current_page: number;
    first_page_url: string;
    from: number;
    last_page: number;
    last_page_url: string;
    next_page_url: string;
    path: string;
    per_page: number;
    prev_page_url: string;
    to: number;
    total: number;
}

export interface DataPaginationProps {
    paginationData: PaginationData;
    onAction: (url: string) => any;
}


export function DataPagination({paginationData, onAction}: DataPaginationProps) {

    const items: any[] = [];

    if(!paginationData) return null;

    for(let i = 1; i <= paginationData.last_page; i++) {
        items.push(
            <Pagination.Item onClick={()=>onClick(paginationData.path + '?page=' + i)}>{ i }</Pagination.Item>
        )
    }

    const onClick = (url: string) => {
        onAction(url);
    }

    return (
        <Pagination >
            {
                paginationData.first_page_url && paginationData.prev_page_url &&
                <Pagination.First onClick={() => {onClick(paginationData.first_page_url)}} />
            }
            {
                paginationData.prev_page_url &&
                <Pagination.Prev onClick={() => {onClick(paginationData.prev_page_url)}} />
            }

            {
                paginationData.next_page_url &&
                <Pagination.Next onClick={() => {onClick(paginationData.next_page_url)}} />
            }
            {
                paginationData.next_page_url && paginationData.last_page_url &&
                <Pagination.Last onClick={() => {onClick(paginationData.last_page_url)}} />
            }
        </Pagination>

    )
}