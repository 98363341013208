import {Table} from "react-bootstrap";
import React from "react";
import {
    textToFloatOrZero, textToIntOrZero,
    zeroToEmptyString,
    zeroToEmptyStringAsCurrencyWithCommas,
    zeroToEmptyStringAsNumber
} from "../../common";


export interface ReportFinanceResultTableItem {
    name: string,
    qty: string,
    expense: string,
    sellPrice: string,
    paidByCard: string,
    paidByCash: string,
    total: string,
}

export function reportDataToFinanceItem(data: any): ReportFinanceResultTableItem {
    return {
        name: data.name,
        qty: zeroToEmptyString(data.qty),
        expense: zeroToEmptyStringAsCurrencyWithCommas(data.expense.toString()),
        sellPrice: zeroToEmptyStringAsCurrencyWithCommas(data.sellPrice.toString()),
        paidByCard: zeroToEmptyStringAsCurrencyWithCommas(data.paidByCard.toString()),
        paidByCash: zeroToEmptyStringAsCurrencyWithCommas(data.paidByCash.toString()),
        total: zeroToEmptyStringAsCurrencyWithCommas((data.paidByCash*1 + data.paidByCard*1).toString()),
    } as ReportFinanceResultTableItem;
}

export interface ReportFinanceResultTableProps {
    firstColTitle: string;
    data: Array<ReportFinanceResultTableItem>;
}

export default function ReportFinanceResultTable({firstColTitle, data}: ReportFinanceResultTableProps) {
    let totalExpense = 0;
    let totalPrice = 0;
    let totalByCash = 0;
    let totalByCard = 0;
    let totalQty = 0;
    let total = 0;

    data.map(
        value => {
            totalExpense += textToFloatOrZero(value.expense);
            totalPrice += textToFloatOrZero(value.sellPrice);
            totalByCash += textToFloatOrZero(value.paidByCash);
            totalByCard += textToFloatOrZero(value.paidByCard);
            totalQty += textToIntOrZero(value.qty);
            total += textToFloatOrZero(value.total);
        }
    );

    return (
        <div className="table-container mb-3 w-100">
            <Table bordered size={"sm"} hover striped className={"w-100 text-light small"}>
                <thead>
                <tr className={"bg-secondary"}>
                    <th className={"text-nowrap"}>{firstColTitle}</th>
                    <th className={"text-right w-120px"}>Брой</th>
                    <th className={"text-right"}>Разх.мат.</th>
                    <th className={"text-right"}>Изработена сума</th>
                    <th className={"text-right text-nowrap"}>Платено кеш</th>
                    <th className={"text-right text-nowrap"}>Платено карта</th>
                    <th className={"text-right text-nowrap"}>Общо платено</th>
                </tr>
                </thead>
                <tbody className={"text-dark"}>
                {
                    data.length === 0 ?
                        <tr>
                            <td colSpan={5} className={"font-weight-bold"}>Няма данни</td>
                        </tr> :
                        <>
                            {
                                data.map(
                                    (r, idx) =>
                                        <tr key={idx}>
                                            <td> {r.name} </td>
                                            <td className={"text-right"}> {r.qty} </td>
                                            <td className={"text-right bg-danger-light"}> {r.expense} </td>
                                            <td className={"text-right"}> {r.sellPrice} </td>
                                            <td className={"text-right"}> {r.paidByCash} </td>
                                            <td className={"text-right"}> {r.paidByCard} </td>
                                            <td className={"text-right bg-success-light font-weight-bold"}> {r.total} </td>
                                        </tr>
                                )
                            }
                        </>
                }
                <tr className={"font-weight-bold text-right bg-secondary-light"}>
                    <td> Общо: </td>
                    <td className={"text-right"}> {totalQty} </td>
                    <td className={"text-right bg-danger-light"}> {zeroToEmptyStringAsCurrencyWithCommas(totalExpense)} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totalPrice)} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totalByCash)} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totalByCard)} </td>
                    <td className={"text-right bg-success-light font-weight-bold"}> {zeroToEmptyStringAsCurrencyWithCommas(total)} </td>
                </tr>
                </tbody>
            </Table>
        </div>

    )
}
