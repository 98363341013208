import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCrutch, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
// @ts-ignore
import React, {useState} from "react";
import {showErrorsListInToast, textToFloat, zeroToEmptyStringAsCurrency} from "../../../common";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";
import {ProcedureItem} from "./ConfigProceduresForm";
import ValueViewer from "../../../common/ValueViewer";
import ConfigMachinesForm from "../machines/ConfigMachinesForm";
// @ts-ignore
import moment from "moment";
import {StringInput} from "../../../common/StringInput";


export interface ConfigProcedureDetailsProps {
    working: boolean;
    item: ProcedureItem;
    onSave: (item: ProcedureItem) => any;
    onDelete: () => any;
    onCancel: () => any;
    zIndex?: number;
}

export default function ConfigProcedureDetails({working, item, onSave, onDelete, onCancel, zIndex}: ConfigProcedureDetailsProps) {
    const [editItem, setEditItem] = useState<ProcedureItem>({...item});
    const [deleteFlag, setDeleteFlag] = useState(false);
    const [selectMachineFlag, setSelectMachineFlag] = useState(false);

    function save(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];

        editItem.name = editItem.name?.trim();
        if(editItem.name?.length === 0) errors.push("Въведете наименование.");
        // if(!editItem.machine) errors.push("Изберете апарат.");
        // if(!editItem.sellPrice) errors.push("Въведете цена.");

        if(errors.length === 0) {
            onSave(editItem);
        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    function validateDate(date: string) {
        const d = moment(date, 'DD.MM.YYYY');
        return d.isValid() ? d.format('DD.MM.YYYY') : '';
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: zIndex || 1500}} />
            <Modal show={!deleteFlag} size={"lg"} onHide={onCancel} style={{zIndex: zIndex ? zIndex+1:1501}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faCrutch} className={"mr-3"}/>Детайли за
                        процедура</Modal.Title>
                    <CloseButton onClick={onCancel}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={save}>
                        <Card className={"border-0"}>
                            <Card.Body className={"max-h-60vh scrollable"}>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" >
                                            <Form.Label>Наименование</Form.Label>
                                            <Form.Control name={"name"} value={editItem.name}
                                                          onChange={(e) => setEditItem({...editItem, name: e.target.value})}/>
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={3}>
                                        <StringInput value={zeroToEmptyStringAsCurrency(editItem.sellPrice?.toString())}
                                                     label={"Цена жени"}
                                                     onChange={(e) => setEditItem({...editItem, sellPrice: textToFloat(e) || 0})} />
                                    </Col>
                                    <Col xs={3}>
                                        <StringInput value={zeroToEmptyStringAsCurrency(editItem.sellPriceMan?.toString())}
                                                     label={"Цена мъже"}
                                                     onChange={(e) => setEditItem({...editItem, sellPriceMan: textToFloat(e) || 0})} />
                                    </Col>
                                    <Col xs={3}>
                                        <StringInput value={zeroToEmptyStringAsCurrency(editItem.packetPrice?.toString())}
                                                     label={"Пакетна цена жени"}
                                                     onChange={(e) => setEditItem({...editItem, packetPrice: textToFloat(e) || 0})} />
                                    </Col>
                                    <Col xs={3}>
                                        <StringInput value={zeroToEmptyStringAsCurrency(editItem.packetPriceMan?.toString())}
                                                     label={"Пакетна цена мъже"}
                                                     onChange={(e) => setEditItem({...editItem, packetPriceMan: textToFloat(e) || 0})} />
                                    </Col>
                                </Row>

                                <Form.Group className="mb-3">
                                    <Form.Label className={"font-weight-bold"}>Апарат</Form.Label>
                                    <Row>
                                        <Col className={"bg-secondary-light"} onClick={() => setSelectMachineFlag(true)}>
                                            <ValueViewer value={editItem.machine?.name} /></Col>
                                        <Col xs={"auto"}>
                                            <Button
                                                onClick={() => setSelectMachineFlag(true)}
                                            >...</Button>
                                        </Col>
                                    </Row>
                                </Form.Group>

                                {/*<Row>*/}
                                {/*    <Col>*/}
                                {/*        <Form.Group className="mb-3">*/}
                                {/*            <Form.Label>Валидна от</Form.Label>*/}
                                {/*            <Form.Control type="text" value={editItem.validFrom}*/}
                                {/*                          placeholder={"формат: дд.мм.гггг"}*/}
                                {/*                          onChange={(e) => setEditItem({...editItem, validFrom: e.target.value})}*/}
                                {/*                          onBlur={() => setEditItem({...editItem, validFrom: validateDate(editItem.validFrom)})}*/}
                                {/*            />*/}
                                {/*        </Form.Group>*/}
                                {/*    </Col>*/}
                                {/*    <Col>*/}
                                {/*        <Form.Group className="mb-3">*/}
                                {/*            <Form.Label>Валидна до</Form.Label>*/}

                                {/*            <Form.Control type="text" value={editItem.validTo}*/}
                                {/*                          placeholder={"формат: дд.мм.гггг"}*/}
                                {/*                          onChange={(e) => setEditItem({...editItem, validTo: e.target.value})}*/}
                                {/*                          onBlur={() => setEditItem({...editItem, validTo: validateDate(editItem.validTo)})}*/}
                                {/*            />*/}
                                {/*        </Form.Group>*/}
                                {/*    </Col>*/}
                                {/*</Row>*/}

                            </Card.Body>
                            <Card.Footer>
                                {
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button type={"submit"}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>&nbsp;
                                            <Button onClick={() => onCancel()} variant={"secondary"}><FontAwesomeIcon
                                                icon={faCancel}/> Откажи</Button>
                                            <Button onClick={() => setDeleteFlag(true)} variant={"danger"} style={{ float: "right"}}>
                                                <FontAwesomeIcon
                                                icon={faTrash}/> Изтрий</Button>
                                        </>
                                }
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>

            {
                deleteFlag && <ConfirmDeleteModal working={working} onConfirm={onDelete} onReject={() => setDeleteFlag(false)} />
            }

            {
                selectMachineFlag &&
                <ConfigMachinesForm onClose={() => setSelectMachineFlag(false)}
                                    zIndex={1600}
                                    onSelect={(m) => {
                                        setEditItem({...editItem, machine: m});
                                        setSelectMachineFlag(false)
                                    }}
                />
            }
        </>
    )
}
