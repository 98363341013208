import {ClientRecordItem} from "../pages/records/RecordDetailsPage";
import {AnyAction, createAsyncThunk, createSlice, ThunkAction} from "@reduxjs/toolkit";
import {API} from "../api";
import {isError, isLoading, isSuccess} from "../common";
import {RootState} from "../app/store";
import moment from "moment";

export interface RecordsSliceState {
    status: "PENDING" | "WORKING" | "SUCCESS" | "FAILED";
    message: string;
    lastRecord?: ClientRecordItem;
    records: ClientRecordItem[];
}

export const recordsSlice = createSlice({
    name: "records",

    initialState: {
        status: "PENDING",
        message: '',
        records: [],
    } as RecordsSliceState,

    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload.status;
            state.message = action.payload.message;
            return state;
        },
        setLastRecord: (state, action) => {
            state.lastRecord = action.payload;
            return state;
        }
    },

    extraReducers: builder => {
        builder
            .addCase(saveClientRecordAsync.pending, state => {
                state.status = "WORKING";
                state.message = "";
            })
            .addCase(saveClientRecordAsync.fulfilled, (state, action) => {
                state.status = "SUCCESS";
                state.message = "Данните са записани успешно.";
                state.records = mergeClientRecords(state.records, action.payload.records);
                state.lastRecord = action.payload.savedRecord;
            })
            .addCase(saveClientRecordAsync.rejected, (state, action) => {
                state.status = "FAILED";
                state.message = action.error.message || action.error.name?.toString() ||
                    "Възникна грешка при опит да се запазят данните.";
                console.error(action);
            })

            .addCase(getClientRecordsAsync.pending, state => {
                state.status = "WORKING";
                state.message = "";
            })
            .addCase(getClientRecordsAsync.fulfilled, (state, action) => {
                state.status = "SUCCESS";
                state.message = "";
                state.records = mergeClientRecords(state.records, action.payload);
            })
            .addCase(getClientRecordsAsync.rejected, (state, action) => {
                state.status = "FAILED";
                state.message = action.error.message || action.error.name?.toString() ||
                    "Възникна грешка при опит да се зареди досието на пациент.";
                console.error(action);
            })

            .addCase(deleteClientRecordsAsync.pending, state => {
                state.status = "WORKING";
                state.message = "";
            })
            .addCase(deleteClientRecordsAsync.fulfilled, (state, action) => {
                state.status = "SUCCESS";
                state.message = "Записа е изтрит успешно.";
                state.records = mergeClientRecords(state.records, action.payload);
            })
            .addCase(deleteClientRecordsAsync.rejected, (state, action) => {
                state.status = "FAILED";
                state.message = action.error.message || action.error.name?.toString() ||
                    "Възникна грешка при опит да се изтрие записа.";
                console.error(action);
            })
    }
})

export const mergeClientRecords = (oldList: ClientRecordItem[], newList: ClientRecordItem[]): ClientRecordItem[] => {
    if(newList && newList.length > 0) {
        const clientId = newList[0].clientId;
        return oldList.filter(i => i.clientId !== clientId).concat(newList);
    } else {
        if(!newList) return oldList;
        return newList;
    }
}

export const {setStatus, setLastRecord} = recordsSlice.actions;

export const selectRecords = (clientId: number) => (state: RootState): ClientRecordItem[] => {
    return state.records.records.filter(r => r.clientId === clientId);
}
export const selectRecordsForDate = (clientId: number, recordDate: Date) => (state: RootState): ClientRecordItem[] => {
    return state.records.records.filter(r => {
        const _rd1 = (typeof r.recordDate === "string") ? r.recordDate : moment(r.recordDate).format('DD.MM.YYYY');
        const _rd2 = (typeof recordDate === "string") ? recordDate : moment(recordDate).format('DD.MM.YYYY');

        return r.clientId === clientId && _rd1 === _rd2 && r.recordType !== "бележка";
    });
}
export const selectStatus = (state: RootState) => state.records.status;
export const selectMessage = (state: RootState) => state.records.message;
export const selectLastRecord = (state: RootState) => state.records.lastRecord;


export const saveClientRecordAsync = createAsyncThunk('records/saveClientRecord',
    async (record: ClientRecordItem):
    Promise<{ savedRecord: ClientRecordItem, records: ClientRecordItem[] }> => {
        return new Promise((resolve, reject) => {
            try {
                API.postClientRecord(apiCall => {
                    if (isError(apiCall)) {
                        reject(apiCall.data.data?.message || apiCall.error.response.statusText)
                    }
                    if (isSuccess(apiCall)) {
                        resolve({
                            records: apiCall.data.data.records,
                            savedRecord: apiCall.data.data.savedRecord
                        })
                    }
                }, record);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        })
    });

export const getClientRecordsAsync = createAsyncThunk('records/getClientRecords',
    async (clientId: number): Promise<ClientRecordItem[]> => {
        return new Promise((resolve, reject) => {
            try {
                API.getClientRecords(apiCall => {
                    if (isError(apiCall)) {
                        reject(apiCall.data.data?.message || apiCall.error.response.statusText)
                    }
                    if (isSuccess(apiCall)) {
                        resolve(apiCall.data.data.records)
                    }
                }, clientId);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        })
    });

export const deleteClientRecordsAsync = createAsyncThunk('records/deleteClientRecords',
    async (recordId: number): Promise<ClientRecordItem[]> => {
        return new Promise((resolve, reject) => {
            try {
                API.deleteClientRecord(apiCall => {
                    if (isError(apiCall)) {
                        reject(apiCall.data.data?.message || apiCall.error.response.statusText)
                    }
                    if (isSuccess(apiCall)) {
                        resolve(apiCall.data.data.records)
                    }
                }, recordId);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        })
    });


export const recordReducer = recordsSlice.reducer;
