import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCrutch, faPencil, faPlus, faTooth} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError, zeroToEmptyStringAsCurrency
} from "../../../common";
import {API, API_CALL} from "../../../api";
import ConfigManipulationDetails from "./ConfigManipulationDetails";


export interface ManipulationItem {
    id: number;
    name: string;
    price: number;
}

export const EMPTY_MANIPuLATION_ITEM: ManipulationItem = {
    id: -1, name: '', price: 0
}

export interface ConfigManipulationsProps { onSelect?: (c: ManipulationItem) => any, onClose: () => any, zIndex?: number }

export default function ConfigManipulationsForm({onClose, onSelect, zIndex}: ConfigManipulationsProps) {

    const [manipulations, setManipulation] = useState<Array<ManipulationItem>>([]);
    const [manipulationsList, setManipulationsList] = useState<Array<ManipulationItem>>([]);
    const [editManipulation, setEditManipulation] = useState<ManipulationItem | null>(null);
    const [working, setWorking] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadManipulations()
    }, []);

    useEffect(() => {
        const _list = manipulations.filter(p => p.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
        setManipulationsList(_list);
    }, [manipulations, filter])

    function loadManipulations() {
        API.getManipulations(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setManipulation(api_call.data.data.manipulations);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на манипулациите!', translateError)
            }
        )
    }


    function saveManipulation(m: ManipulationItem) {
        setWorking(true);

        API.postManipulation(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    setEditManipulation(null);
                    loadManipulations();
                }
            }, m
        );
    }

    function deleteManipulation() {
        setWorking(true);

        API.deleteManipulation(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване', translateError);
                if (isSuccess(apiCall)) {
                    setEditManipulation(null);
                    loadManipulations();
                }
            }, editManipulation
        );
    }

    function selectManipulation(p: ManipulationItem) {
        const item: ManipulationItem = {
            id: p.id,
            name: p.name,
            price: p.price || 0
        }

        if(onSelect) onSelect(item);
    }

    return (<>
            <Modal show={true} size={"xl"} onHide={onClose} style={{zIndex: zIndex ? zIndex : 1500}} >
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faTooth} className={"mr-3"}/>Номенклатура на
                        манипулациите</Modal.Title>
                    <CloseButton onClick={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Card className={"border-0"}>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" value={filter}
                                                      placeholder={"Филтър..."}
                                                      onChange={(e) => setFilter(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="table-container max-h-44vh">
                            <Table  size={"sm"} hover bordered className={"text-light"}>
                                <thead>
                                <tr className={"bg-secondary"}>
                                    <th>Манипулация</th>
                                    <th className={"text-right"}>Цена</th>
                                    <th className={"w-100px"}></th>
                                </tr>
                                </thead>
                                <tbody className={"text-dark"}>
                                {
                                    manipulationsList?.length > 0 ?
                                        manipulationsList.map(
                                            m => <tr key={m.id} style={{lineHeight: "2rem"}}>
                                                <td onClick={() => { if(onSelect) selectManipulation(m)} }>{m.name}</td>
                                                <td onClick={() => { if(onSelect) selectManipulation(m)} } className={"text-right"}>
                                                    {zeroToEmptyStringAsCurrency(m.price.toString())}</td>
                                                <td className={"text-center"}>
                                                    <Button variant={"outline-secondary"} size={"sm"} className={"m-0"}
                                                            onClick={() => setEditManipulation(m)}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil}/>
                                                    </Button>
                                                    &nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button variant={"outline-secondary"} size={"sm"}
                                                                className={"m-0"}
                                                                onClick={() => selectManipulation(m)}
                                                        >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={4}>{!working && <>Няма дефинирани манипулации</>}</td>
                                        </tr>
                                }
                                </tbody>
                            </Table>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            {
                                working && !editManipulation ? <Spinner animation="border"/> :
                                    <>
                                        {
                                            <Button
                                                onClick={
                                                    () => setEditManipulation({
                                                        ...EMPTY_MANIPuLATION_ITEM
                                                    })
                                                }><FontAwesomeIcon
                                                icon={faPlus}/> Добави манипулация</Button>
                                        }
                                    </>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>


            {
                editManipulation &&
                <ConfigManipulationDetails item={editManipulation} working={working}
                                       onSave={(m) => saveManipulation(m)}
                                       onDelete={() => deleteManipulation()}
                                       onCancel={() => setEditManipulation(null)}
                                       zIndex={1 + (zIndex ? zIndex : 1500)}
                />
            }
        </>
    )
}
