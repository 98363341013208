import React, {useEffect, useMemo, useState} from "react";
import {dateToString, zeroToEmptyStringAsNumber} from "../../common";
import {Button, Col, Container, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faCheck, faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {PrepaidItemType, PrepaidItemsType,} from "./PrepaidDialog";

export interface PrepaidDialogListProps {
    data?: PrepaidItemsType;
    onEdit: (item: PrepaidItemType) => void;
    onDelete: (item: PrepaidItemType) => void;
}

const PrepaidDialogList: React.FC<PrepaidDialogListProps> =
    ({data, onEdit, onDelete}) => {
    const [loading, setLoading] = useState(true);
    const [deleteItem, setDeleteItem] = useState<PrepaidItemType | null>(null);

    const tableHead = <thead>
    <tr className={"bg-secondary"}>
        <th className={"w-120px"}>Дата</th>
        <th className={"text-right w-120px"}>Сума</th>
        <th>Описание</th>
        <th className={"w-120px"}></th>
    </tr>
    </thead>;

    const tableBody = useMemo(() => !data ? [] :
        data.map((value, idx) => {

        let ItemButtons;
        if(deleteItem && deleteItem === value) {
            ItemButtons =
                <>
                    <Button size={"sm"} variant={"success"}
                            onClick={() => onDelete(deleteItem)}
                    ><FontAwesomeIcon icon={faCheck}/></Button>&nbsp;
                    <Button size={"sm"} variant={"danger"} onClick={() => setDeleteItem(null)}
                    ><FontAwesomeIcon icon={faCancel}/></Button>
                </>
        } else {
            ItemButtons = <>
                <Button size={"sm"} variant={"primary"}
                        onClick={() => onEdit(value)}
                ><FontAwesomeIcon icon={faPencil}/></Button>&nbsp;
                <Button size={"sm"} variant={"danger"} onClick={() => setDeleteItem(value)}
                ><FontAwesomeIcon icon={faTrash}/></Button>
            </>
        }

        return <tr key={idx}>
            <td> {dateToString(value.date)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsNumber(value.amount)} </td>
            <td> {value.description} </td>
            <td className={"text-right"}>
                { ItemButtons }
            </td>
        </tr>
    }), [data, deleteItem]);


    useEffect(() => {
        setLoading(false);
    }, []);

    if (loading) {
        return <Container>
            <Row>
                <Col className={"text-center"}>
                    <Spinner animation={"border"}/>
                </Col>
            </Row>
        </Container>
    }

    return <Container>
        <Table size={"sm"} hover bordered className={"text-light"}>
            {tableHead}
            <tbody className={"text-dark"}>
            {tableBody}
            {tableBody.length === 0 &&
                <tr>
                    <td colSpan={4} className={"fw-bold text-center"}>Няма авансови плащания.</td>
                </tr>}
            </tbody>
        </Table>

    </Container>;
}

export default PrepaidDialogList;
