import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faBedPulse, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError
} from "../../../common";
import {API, API_CALL} from "../../../api";
import ConfigureMachineDetails from "./ConfigMachineDetails";


export interface MachineItem {
    id: number;
    name: string;
    validFrom: string;
    validTo: string;
}

export const EMPTY_MACHINE_ITEM: MachineItem = {
    id: -1, name: '', validFrom: '', validTo: ''
}

export interface ConfigMachinesProps { onSelect?: (c: MachineItem) => any, onClose: () => any, zIndex?: number }

export default function ConfigMachinesForm({onClose, onSelect, zIndex}: ConfigMachinesProps) {

    const [machines, setMachines] = useState<Array<MachineItem>>([]);
    const [machinesList, setMachinesList] = useState<Array<MachineItem>>([]);
    const [editMachine, setEditMachine] = useState<MachineItem | null>(null);
    const [working, setWorking] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadMachines()
    }, []);

    useEffect(() => {
        const _list = machines.filter(p => p.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
        setMachinesList(_list);
    }, [machines, filter])

    function loadMachines() {
        API.getMachines(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setMachines(api_call.data.data.machines);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на апаратите!', translateError)
            }
        )
    }


    function saveMachine(m: MachineItem) {
        API.postMachine(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    setEditMachine(null);
                    loadMachines();
                }
            }, m
        );
    }

    function deleteMachine() {
        setWorking(true);

        API.deleteMachine(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване', translateError);
                if (isSuccess(apiCall)) {
                    setEditMachine(null);
                    loadMachines();
                }
            }, editMachine
        );
    }

    return (<>
            <Modal show={true} size={"xl"} onHide={onClose} style={{zIndex: zIndex ? zIndex : 1500}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faBedPulse} className={"mr-3"}/>Номенклатура на
                        апаратите</Modal.Title>
                    <CloseButton onClick={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Card className={"border-0"}>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" value={filter}
                                                      placeholder={"Филтър..."}
                                                      onChange={(e) => setFilter(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Table size={"sm"} hover bordered className={"text-light"}>
                                <thead>
                                <tr className={"bg-secondary"}>
                                    <th>Име на апарата</th>
                                    <th>Активен от</th>
                                    <th>Активен до</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody className={"text-dark"}>
                                {
                                    machinesList?.length > 0 ?
                                        machinesList.map(
                                            m => <tr key={m.id} style={{lineHeight: "2rem"}}>
                                                <td onClick={() => { if(onSelect) onSelect(m)} }>{m.name}</td>
                                                <td onClick={() => { if(onSelect) onSelect(m)} }>{m.validFrom}</td>
                                                <td onClick={() => { if(onSelect) onSelect(m)} }>{m.validTo}</td>
                                                <td className={"text-center"}>
                                                    <Button variant={"outline-secondary"} size={"sm"} className={"m-0"}
                                                            onClick={() => setEditMachine(m)}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil}/>
                                                    </Button>
                                                    &nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button variant={"outline-secondary"} size={"sm"}
                                                                className={"m-0"}
                                                                onClick={() => onSelect(m)}
                                                        >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={4}>{!working && <>Няма дефинирани апарати</>}</td>
                                        </tr>
                                }
                                </tbody>
                            </Table>

                        </Card.Body>
                        <Card.Footer>
                            {
                                working && !editMachine ? <Spinner animation="border"/> :
                                    <>
                                        {
                                            <Button
                                                onClick={
                                                    () => setEditMachine({
                                                        ...EMPTY_MACHINE_ITEM
                                                    })
                                                }><FontAwesomeIcon
                                                icon={faPlus}/> Добави апарат</Button>
                                        }
                                    </>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>


            {
                editMachine &&
                <ConfigureMachineDetails item={editMachine} working={working}
                                        onSave={(m) => saveMachine(m)}
                                        onDelete={() => deleteMachine()}
                                        onCancel={() => setEditMachine(null)}
                                        zIndex={1 + (zIndex ? zIndex : 1500)}
                />
            }
        </>
    )
}
