import React from "react";

export interface ValueViewerProps {
    value: string | null | undefined;
    className?: string;
}

export default function ValueViewer({value, className} : ValueViewerProps) {
    return (
        <div className={"bg-secondary-light w-100 lh-2-5em pl-2 " + className}>{value}</div>
    )
}