import React, {FC} from "react";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../common";
import {Table} from "react-bootstrap";

interface Props {
    doctor: any;
}

const DentalReportDoctorPatientsDetails: FC<Props> = (props) => {
    const doctor = props.doctor;
    let totalExp = 0;
    let totalPrice = 0;

    return (
        <Table bordered size={"sm"} hover striped className={"text-light small mb-3"}>
            <thead className={"not-sticky"}>
            <tr>
                <th className={"text-center text-dark font-weight-bold"}
                    colSpan={13}>{doctor.name}</th>
            </tr>
            <tr className={"bg-secondary"}>
                <th className={"text-nowrap"} rowSpan={2}>Дата</th>
                <th className={"text-nowrap"} rowSpan={2}>Пациент</th>
                <th className={"text-nowrap"} rowSpan={2}>Манипулация</th>

                <th className={"text-center"} colSpan={4}>Кешов отчет</th>
                <th className={"text-center"} colSpan={3}>По банка</th>
                <th className={"text-right text-nowrap"} rowSpan={2}>НЗОК</th>

                <th className={"text-right text-nowrap"} rowSpan={2}>Приход</th>
                <th className={"text-right text-nowrap"} rowSpan={2}>Разход</th>
                <th className={"text-right text-nowrap"} rowSpan={2}>Разлика</th>
            </tr>

            <tr className={"bg-secondary"}>
                <th className={"text-right"}>В брой</th>
                <th className={"text-right"}>С карта</th>
                <th className={"text-right text-nowrap"}>от аванс</th>
                <th className={"text-right text-nowrap"}>Всичко</th>
                <th className={"text-right bg-success-light"}>Потвърд.</th>
                <th className={"text-right bg-danger-light"}>Не потв.</th>
                <th className={"text-right text-nowrap"}>Всичко</th>
            </tr>


            </thead>
            <tbody className={"text-dark"}>
            {
                doctor.patients
                    .sort((a: any, b: any) => a.date < b.date ? -1 : (a.date > b.date ? 1 : 0))
                    .map(
                        (patient: any, idx: number) => {
                            totalPrice += patient.price;
                            totalExp += patient.expenses;

                            return <tr key={patient.name + idx.toString()}>
                                <td className={"text-nowrap"}> {patient.date} </td>
                                <td> {patient.name} </td>
                                <td>
                                    {patient.manipulation}
                                    {
                                        patient.manipulationDescription &&
                                        <><br/><i>{patient.manipulationDescription}</i></>
                                    }
                                </td>
                                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.paidByCash)} </td>
                                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.paidByCard)} </td>
                                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.paidByAvans)} </td>
                                <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.paidByCash + patient.paidByCard + patient.paidByAvans)} </td>
                                <td className={"text-right bg-success-light"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.paidByBankConf)} </td>
                                <td className={"text-right bg-danger-light"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.paidByBankNotConf)} </td>
                                <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.paidByBank)} </td>
                                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.paidByNhif)} </td>
                                <td className={"text-right text-nowrap"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.price)} </td>
                                <td className={"text-right text-nowrap"}>
                                    {zeroToEmptyStringAsCurrencyWithCommas(patient.expenses)}
                                    {
                                        patient.manipulationExpensesDescription &&
                                        <><br/><i>{patient.manipulationExpensesDescription}</i></>
                                    }
                                </td>
                                <td className={"text-right text-nowrap"}> {zeroToEmptyStringAsCurrencyWithCommas(patient.price - patient.expenses)} </td>
                            </tr>
                        }
                    )
            }
            <tr className={"text-right font-weight-bold bg-secondary-light"}>
                <td className={"text-right font-weight-bold"} colSpan={11}> ВСИЧКО
                    {
                        doctor.name.indexOf("Авансови") === -1 && <> ЗА {doctor.patients.length.toString()} МАНИПУЛАЦИИ</>
                    }
                    :&nbsp;
                </td>
                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totalPrice)} </td>
                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totalExp)} </td>
                <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(totalPrice - totalExp)} </td>
            </tr>
            </tbody>
        </Table>
    );
}

export default DentalReportDoctorPatientsDetails;
