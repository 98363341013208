import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCrutch, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError, zeroToEmptyStringAsCurrency
} from "../../../common";
import {API, API_CALL} from "../../../api";
import ConfigProcedureDetails from "./ConfigProcedureDetails";
import {MachineItem} from "../machines/ConfigMachinesForm";
import {SelectedProcedureItem} from "../../records/RecordDetailsPage";


export interface ProcedureItem {
    id: number;
    name: string;
    machine?: MachineItem;
    sellPrice: number;
    sellPriceMan: number;
    packetPrice: number;
    packetPriceMan: number;
    validFrom: string;
    validTo: string;
}

export const EMPTY_PROCEDURE_ITEM: ProcedureItem = {
    id: -1, name: '', machine: undefined, sellPrice: 0, sellPriceMan: 0, packetPrice: 0, packetPriceMan: 0, validFrom: '', validTo: ''
}

export interface ConfigProceduresProps { onSelect?: (c: SelectedProcedureItem) => any, onClose: () => any, zIndex?: number }

export default function ConfigProceduresForm({onClose, onSelect, zIndex}: ConfigProceduresProps) {

    const [procedures, setProcedures] = useState<Array<ProcedureItem>>([]);
    const [proceduresList, setProceduresList] = useState<Array<ProcedureItem>>([]);
    const [editProcedure, setEditProcedure] = useState<ProcedureItem | null>(null);
    const [working, setWorking] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadProcedures()
    }, []);

    useEffect(() => {
        const _list = procedures.filter(p => p.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
        setProceduresList(_list);
    }, [procedures, filter])

    function loadProcedures() {
        API.getProcedures(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setProcedures(api_call.data.data.procedures);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на процедурите!', translateError)
            }
        )
    }


    function saveProcedure(m: ProcedureItem) {
        setWorking(true);

        API.postProcedure(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    setEditProcedure(null);
                    loadProcedures();
                }
            }, m
        );
    }

    function deleteProcedure() {
        setWorking(true);

        API.deleteProcedure(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване', translateError);
                if (isSuccess(apiCall)) {
                    setEditProcedure(null);
                    loadProcedures();
                }
            }, editProcedure
        );
    }

    function selectProcedure(p: ProcedureItem, price?: number) {
        const item: SelectedProcedureItem = {
            itemId: p.id,
            itemName: p.name,
            itemExpense: 0,
            itemSellPrice: price || 0,
            machineId: p.machine?.id,
            machineName: p.machine?.name
        }

        if(onSelect) onSelect(item);
    }

    return (<>
            <Modal show={true} size={"xl"} onHide={onClose} style={{zIndex: zIndex ? zIndex : 1500}} >
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faCrutch} className={"mr-3"}/>Номенклатура на
                        процедурите</Modal.Title>
                    <CloseButton onClick={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Card className={"border-0"}>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" value={filter}
                                                      placeholder={"Филтър..."}
                                                      onChange={(e) => setFilter(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="table-container max-h-44vh">
                            <Table  size={"sm"} hover bordered className={"text-light"}>
                                <thead>
                                <tr className={"bg-secondary"}>
                                    <th rowSpan={2}>Име на процедурата</th>
                                    <th rowSpan={2}>Апарат</th>
                                    <th colSpan={2} className={"text-center"}>Регуларна цена</th>
                                    <th colSpan={2} className={"text-center"}>Пакетна цена</th>
                                    <th rowSpan={2}></th>
                                </tr>
                                <tr className={"bg-secondary"}>
                                    <th className={"text-right"}>Жени</th>
                                    <th className={"text-right"}>Мъже</th>
                                    <th className={"text-right"}>Жени</th>
                                    <th className={"text-right"}>Мъже</th>
                                </tr>
                                </thead>
                                <tbody className={"text-dark"}>
                                {
                                    proceduresList?.length > 0 ?
                                        proceduresList.map(
                                            m => <tr key={m.id} style={{lineHeight: "2rem"}}>
                                                <td>{m.name}</td>
                                                <td>{m.machine?.name}</td>
                                                <td onClick={() => { if(onSelect) selectProcedure(m, m.sellPrice)} }
                                                    className={"text-right text-primary cursor-pointer"}>
                                                    {zeroToEmptyStringAsCurrency(m.sellPrice?.toString())}</td>

                                                <td onClick={() => { if(onSelect) selectProcedure(m, m.sellPriceMan)} }
                                                    className={"text-right text-primary cursor-pointer"}>
                                                    {zeroToEmptyStringAsCurrency(m.sellPriceMan?.toString())}</td>

                                                <td onClick={() => { if(onSelect) selectProcedure(m, m.packetPrice)} }
                                                    className={"text-right text-primary cursor-pointer"}>
                                                    {zeroToEmptyStringAsCurrency(m.packetPrice?.toString())}</td>

                                                <td onClick={() => { if(onSelect) selectProcedure(m, m.packetPriceMan)} }
                                                    className={"text-right text-primary cursor-pointer"}>
                                                    {zeroToEmptyStringAsCurrency(m.packetPriceMan?.toString())}</td>

                                                <td className={"text-center"}>
                                                    <Button variant={"outline-secondary"} size={"sm"} className={"m-0"}
                                                            onClick={() => setEditProcedure(m)}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil}/>
                                                    </Button>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={4}>{!working && <>Няма дефинирани процедури</>}</td>
                                        </tr>
                                }
                                </tbody>
                            </Table>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            {
                                working && !editProcedure ? <Spinner animation="border"/> :
                                    <>
                                        {
                                            <Button
                                                onClick={
                                                    () => setEditProcedure({
                                                        ...EMPTY_PROCEDURE_ITEM
                                                    })
                                                }><FontAwesomeIcon
                                                icon={faPlus}/> Добави процедура</Button>
                                        }
                                    </>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>


            {
                editProcedure &&
                <ConfigProcedureDetails item={editProcedure} working={working}
                                        onSave={(m) => saveProcedure(m)}
                                        onDelete={() => deleteProcedure()}
                                        onCancel={() => setEditProcedure(null)}
                                        zIndex={1 + (zIndex ? zIndex : 1500)}
                />
            }
        </>
    )
}
