import moment from "moment";
import {API_CALL, NET_STATUS} from "./api";

export class VALIDATION_CLASS {
    public validateLoginData(apiCall: API_CALL, email: string, password: string): boolean {
        apiCall.status = NET_STATUS.SUCCESS;
        apiCall.error = null;

        if (!email || email.trim().length === 0) {
            apiCall.status = NET_STATUS.ERROR;
            apiCall.error = [...apiCall.error, 'Липсва името на потребителя'];
        }
        if (!password || password.trim().length === 0) {
            apiCall.status = NET_STATUS.ERROR;
            apiCall.error = [...apiCall.error, 'Липсва паролата на потребителя'];
        }

        return apiCall.status === NET_STATUS.SUCCESS;
    }
}

export const VALIDATION = new VALIDATION_CLASS();



export function validateDate(date: string) {
    const d = moment(date, 'DD.MM.YYYY');
    return d.isValid() ? d.format('DD.MM.YYYY') : '';
}

export function validateNumber(num: string) {
    // @ts-ignore
    const _num = num * 1;
    return (isNaN(_num)) ? '' : num;
}
