import React, {FC} from "react";
import {Table} from "react-bootstrap";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../common";

interface Props {
    report: any;
}
const DentalReportDoctorsTotals: FC<Props> = ({report}) => {
    return (<Table bordered size={"sm"} hover striped className={"text-light small"}>
        <thead className={"not-sticky"}>
        <tr className={"bg-secondary"}>
            <th className={"text-right text-nowrap"} rowSpan={2}>No:</th>
            <th className={"text-nowrap"} rowSpan={2}>Доктор</th>
            <th className={"text-center"} colSpan={4}>Кешов отчет</th>
            <th className={"text-center"} colSpan={3}>По банка</th>
            <th className={"text-right text-nowrap"} rowSpan={2}>НЗОК</th>
            <th className={"text-right text-nowrap"} rowSpan={2}>Приход</th>
            <th className={"text-right text-nowrap"} rowSpan={2}>Разход</th>
            <th className={"text-right text-nowrap"} rowSpan={2}>Разлика</th>
        </tr>
        <tr className={"bg-secondary"}>
            <th className={"text-right"}>В брой</th>
            <th className={"text-right"}>С карта</th>
            <th className={"text-right text-nowrap"}>от аванс</th>
            <th className={"text-right text-nowrap"}>Всичко</th>
            <th className={"text-right bg-success-light"}>Потвърд.</th>
            <th className={"text-right bg-danger-light"}>Не потв.</th>
            <th className={"text-right text-nowrap"}>Всичко</th>
        </tr>
        </thead>
        <tbody className={"text-dark"}>
        {
            report.doctors.map(
                (doctor: any, idx: number) => <tr key={idx}>
                    <td className={"text-right"}> {(idx + 1).toString()} </td>
                    <td> {doctor.name} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCash)} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCard)} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByAvans)} </td>
                    <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByCash + doctor.paidByCard + doctor.paidByAvans)} </td>
                    <td className={"text-right bg-success-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBankConf)} </td>
                    <td className={"text-right bg-danger-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBankNotConf)} </td>
                    <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByBank)} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.paidByNhif)} </td>
                    <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.price)} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.expenses)} </td>
                    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(doctor.price - doctor.expenses)} </td>
                </tr>
            )
        }
        <tr className={"text-right font-weight-bold bg-secondary-light"}>
            <td className={"text-right font-weight-bold"} colSpan={2}> ВСИЧКО:</td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByCash)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByCard)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByAvans)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByCash + report.total.paidByCard + report.total.paidByAvans)} </td>
            <td className={"text-right bg-success-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByBankConf)} </td>
            <td className={"text-right bg-danger-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByBankNotConf)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByBank)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByNhif)} </td>
            <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.price)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.expenses)} </td>
            <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.price - report.total.expenses)} </td>
        </tr>
        </tbody>
    </Table>)
}

export default DentalReportDoctorsTotals;
