import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Col, Container, ListGroup, Row, Tab} from "react-bootstrap";
import {
    faBedPulse,
    faCross,
    faCrutch, faHospital,
    faStore, faTooth,
    faTruckMedical,
    faUser,
    faUsers
} from "@fortawesome/free-solid-svg-icons";
import React, {Suspense, useState} from "react";
import ConfigMaterialsForm from "./materials/ConfigMaterialsForm";
import ConfigProceduresForm from "./procedures/ConfigProceduresForm";
import ConfigMachinesForm from "./machines/ConfigMachinesForm";
import ConfigClientsForm from "./clients/ConfigClientsForm";
import ConfigSuppliersForm from "./suppliers/ConfigSuppliersForm";
import ConfigInfusionsForm from "./infusions/ConfigInfusionsForm";
import ConfigManipulationsForm from "./manipulations/ConfigManipulationsForm";
import ConfigClinicsForm from "./clinics/ConfigClinicsForm";

export function ConfigPage() {
    const ConfigUsersPage = React.lazy(() => import("./users/ConfigUsersPage"));

    enum CONFIG_TABS {
        'USERS' = 'users',
        'MATERIALS' = 'materials',
        'PROCEDURES' = 'procedures',
        'INFUSIONS' = 'infusions',
        'MACHINES' = 'machines',
        'CLIENTS' = 'clients',
        'SUPPLIERS' = 'suppliers',
        'MANIPULATIONS' = 'manipulations',
        'CLINICS' = 'clinics',
    }

    const defaultActiveKey = CONFIG_TABS.USERS;

    const [activeTab, setActiveTab] = useState<string | null>(null);


    return (
        <>
            <Container fluid>
                <Tab.Container id="list-group-tabs-example" defaultActiveKey={undefined}>
                    <Row>
                        <Col xs={"auto"}>
                            <Row>
                                <ListGroup className={"w-320px ml-4"} onSelect={eventKey => setActiveTab(eventKey)}>
                                    <ListGroup.Item className={"text-center bg-dark text-light pt-3"}>
                                        <h4>Номенклатури</h4>
                                    </ListGroup.Item>

                                    <ListGroup.Item eventKey={CONFIG_TABS.SUPPLIERS} action>
                                        <FontAwesomeIcon icon={faTruckMedical} className={"mr-2"}/>Номенклатура доставчици
                                    </ListGroup.Item>

                                    <ListGroup.Item eventKey={CONFIG_TABS.USERS} action>
                                        <FontAwesomeIcon icon={faUsers} className={"mr-2"}/>Служители и терапевти
                                    </ListGroup.Item>

                                    <ListGroup.Item eventKey={CONFIG_TABS.MATERIALS} action>
                                        <FontAwesomeIcon icon={faStore} className={"mr-2"}/>Номенклатура материали
                                    </ListGroup.Item>

                                    <ListGroup.Item eventKey={CONFIG_TABS.PROCEDURES} action>
                                        <FontAwesomeIcon icon={faCrutch} className={"mr-2"}/>Номенклатура процедури
                                    </ListGroup.Item>

                                    <ListGroup.Item eventKey={CONFIG_TABS.INFUSIONS} action>
                                        <FontAwesomeIcon icon={faCrutch} className={"mr-2"}/>Номенклатура вливки
                                    </ListGroup.Item>

                                    <ListGroup.Item eventKey={CONFIG_TABS.MACHINES} action>
                                        <FontAwesomeIcon icon={faBedPulse} className={"mr-2"}/>Номенклатура апарати
                                    </ListGroup.Item>
                                    <ListGroup.Item eventKey={CONFIG_TABS.MANIPULATIONS} action>
                                        <FontAwesomeIcon icon={faTooth} className={"mr-2"}/>Номенклатура манипулации
                                    </ListGroup.Item>
                                    <ListGroup.Item eventKey={CONFIG_TABS.CLINICS} action>
                                        <FontAwesomeIcon icon={faHospital} className={"mr-2"}/>Номенклатура клиники
                                    </ListGroup.Item>
                                </ListGroup>
                            </Row>
                        </Col>

                        <Col>
                            <Suspense fallback={() => <div>Зареждам...</div>}>
                                {activeTab === CONFIG_TABS.SUPPLIERS && <ConfigSuppliersForm onClose={() => setActiveTab(null)}/>}
                                {activeTab === CONFIG_TABS.CLIENTS && <ConfigClientsForm onClose={() => setActiveTab(null)}/>}
                                {activeTab === CONFIG_TABS.MATERIALS && <ConfigMaterialsForm onClose={() => setActiveTab(null)}/>}
                                {activeTab === CONFIG_TABS.PROCEDURES && <ConfigProceduresForm onClose={() => setActiveTab(null)}/>}
                                {activeTab === CONFIG_TABS.INFUSIONS && <ConfigInfusionsForm onClose={() => setActiveTab(null)}/>}
                                {activeTab === CONFIG_TABS.MACHINES && <ConfigMachinesForm onClose={() => setActiveTab(null)}/>}
                                {activeTab === CONFIG_TABS.MANIPULATIONS && <ConfigManipulationsForm onClose={() => setActiveTab(null)}/>}
                                {activeTab === CONFIG_TABS.CLINICS && <ConfigClinicsForm onClose={() => setActiveTab(null)}/>}
                                {activeTab === CONFIG_TABS.USERS && <ConfigUsersPage/>}
                            </Suspense>
                        </Col>
                    </Row>
                </Tab.Container>


            </Container>
        </>
    )
}
