import {ClientRecord} from "./DentalClinicPage";
import {useEffect, useState} from "react";
import {Dropdown, Spinner, Table} from "react-bootstrap";
import {API} from "../../api";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast, textToFloatOrZero,
    translateError,
    zeroToEmptyStringAsCurrencyWithCommas
} from "../../common";
import moment from "moment/moment";
import ClientRecordManipulationForm, {ClientRecordManipulation} from "./ClientRecordManipulationForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faTrash} from "@fortawesome/free-solid-svg-icons";
import {ConfirmDeleteModal} from "../../common/ConfirmDeleteModal";

export interface ClientDentalRecordManipulationsProps {
    record?: ClientRecord
    onUpdatePrepaid: (balance: number) => void;
}

export default function ClientDentalRecordManipulations(props: ClientDentalRecordManipulationsProps) {
    const [working, setWorking] = useState(true);
    const [manipulations, setManipulations] = useState<Array<ClientRecordManipulation>>([]);
    const [editmanipulation, setEditManipulation] = useState<ClientRecordManipulation | null>(null);
    const [removeManipulation, setRemoveManipulation] = useState<ClientRecordManipulation | null>(null);

    function loadPrepaid() {
        API.getClientPrepaid(
            apiCall => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно зареждане на данните.', translateError);
                if (isSuccess(apiCall)) {
                    props.onUpdatePrepaid(apiCall.data.data.balance);
                }
            }, props.record!.clientId
        );
    }

    function loadData() {
        API.getClientDentalRecordManipulations(
            apiCall => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно зареждане на данните.', translateError);
                if (isSuccess(apiCall)) {
                    setManipulations(apiCall.data.data.manipulations.map(
                        (r: any) => {
                            return {
                                ...r,
                                manipulationDate: moment(r.manipulationDate, 'DD.MM.YYYY').toDate(),
                                bankConfirmedDate: r.bankConfirmedDate ? moment(r.bankConfirmedDate, 'DD.MM.YYYY').toDate() : null
                            } as ClientRecordManipulation;
                        }
                    ));
                    loadPrepaid();
                }
            }, props.record!.id
        );
    }

    useEffect(() => {
        if (props.record) {
            loadData();
        }
    }, [props.record]);

    if (working) {
        return <Spinner animation={"border"}/>
    }

    if (manipulations.length === 0) {
        return <div className={"small"}>Няма въведени манипулации.</div>
    }

    let total = 0;
    let totalPaid = 0;
    let totalDiff = 0;
    manipulations.forEach(m => {
        total += (m.manipulationPrice || 0) * 1;
        totalPaid += calculatePaid(m);
        totalDiff += calculateDiff(m);
    });

    function doRemoveManipulation() {
        API.deleteClientDentalRecordManipulation(
            apiCall => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване на манипулацията.', translateError);
                if (isSuccess(apiCall)) {
                    setRemoveManipulation(null);
                    loadData();
                }
            }, removeManipulation
        );
    }

    function calculatePaid(m: ClientRecordManipulation) {
        const paid = textToFloatOrZero(m.manipulationPaidByNhif?.toString()) +
            textToFloatOrZero(m.manipulationPaidByBank?.toString()) +
            textToFloatOrZero(m.manipulationPaidByCash?.toString()) +
            textToFloatOrZero(m.manipulationPaidByPrepaid?.toString()) +
            textToFloatOrZero(m.manipulationPaidByCard?.toString());
        return paid;
    }

    function getPaid(m: ClientRecordManipulation) {
        const paid = calculatePaid(m);
        return zeroToEmptyStringAsCurrencyWithCommas(paid.toString(), '');
    }

    function calculateDiff(m: ClientRecordManipulation) {
        const paid = calculatePaid(m);
        const price = textToFloatOrZero(m.manipulationPrice?.toString());
        let diff = price - paid;
        if (diff < 0) diff = 0;
        return diff;
    }

    function getDiff(m: ClientRecordManipulation) {
        const diff = calculateDiff(m);
        return zeroToEmptyStringAsCurrencyWithCommas(diff.toString(), '');
    }

    return <>
        <Table className={"small"} bordered>
            <thead>
            <tr>
                <th>Дата</th>
                <th>Манипулация</th>
                <th className={"text-right"}>Цена</th>
                <th className={"text-right"}>Платено</th>
                {/*<th className={"text-right"}>Разлика</th>*/}
            </tr>
            </thead>
            <tbody>
            {
                manipulations.map(
                    m => <tr className={getDiff(m) !== "" ? "text-danger" : ""}>
                        <td>
                            {moment(m.manipulationDate).format('DD.MM.YYYY')}
                        </td>

                        <td>
                            <div>{m.manipulationName}</div>
                            {
                                m.manipulationDescription &&
                                <div className={"font-italic"}>{m.manipulationDescription} </div>
                            }
                            {
                                m.doctorName &&
                                <div className={"font-italic text-info"}>{m.doctorName}, {m.clinicName} </div>
                            }
                        </td>

                        <td className={"text-right"}>
                            {zeroToEmptyStringAsCurrencyWithCommas(m.manipulationPrice?.toString() || "", '')}
                        </td>

                        <td className={"text-right"}>
                            {getPaid(m)}
                        </td>

                        {/*<td className={"text-right"}>*/}
                        {/*    {getDiff(m)}*/}
                        {/*</td>*/}


                        <td className={"w-50px text-right"}>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary" size={"sm"}></Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setEditManipulation(m)}><FontAwesomeIcon
                                        icon={faPencil}/>&nbsp;&nbsp;Редакция</Dropdown.Item>
                                    <Dropdown.Item className={"text-danger"}
                                                   onClick={() => setRemoveManipulation(m)}><FontAwesomeIcon
                                        icon={faTrash}/>&nbsp;&nbsp;Изтриване</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                )
            }
            <tr className={"font-weight-bold"}>
                <td></td>
                <td className={"text-right"}>
                    Общо:
                </td>
                <td className={"text-right"}>
                    {zeroToEmptyStringAsCurrencyWithCommas(total.toString(), '')}
                </td>
                <td className={"text-right"}>
                    {zeroToEmptyStringAsCurrencyWithCommas(totalPaid.toString(), '')}
                </td>
                <td className={"text-right"}>
                    {zeroToEmptyStringAsCurrencyWithCommas(totalDiff.toString(), '')}
                </td>
                <td></td>
            </tr>
            </tbody>
        </Table>

        {
            editmanipulation &&
            <ClientRecordManipulationForm record={props.record!}
                                          manipulation={editmanipulation}
                                          onClose={() => {
                                              setEditManipulation(null);
                                              loadData();
                                          }} />
        }

        {
            removeManipulation &&
            <ConfirmDeleteModal
                working={working}
                onReject={() => setRemoveManipulation(null)}
                onConfirm={() => doRemoveManipulation()}
            />
        }
    </>
}
