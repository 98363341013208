

export interface SwitchComponentProps {
    selector: number|string;
    values: Map<number|string, string>
    defaultValue?: string
}


export default function SwitchComponent({selector, values, defaultValue}: SwitchComponentProps) {
    return <>
        {
            values.get(selector) || defaultValue
        }
    </>
}