
export enum FileUploadStatus {
    PENDING = 'PENDING',
    UPLOADING = 'UPLOADING',
    DONE = 'DONE',
    FAILED = 'FAILED'
}

export type FileUploadState = {
    fileData: any;
    fileName: string;
    progress: number;
    status: FileUploadStatus;
}

export type FileUploadReducerState = {
    files: Array<FileUploadState>;
    allDone: boolean;
    hasFailed: boolean;
}


export type FileUploadAction = {
    type: ActionKind;
    filedata?: any;
    filename: string;
    progress?: number;
}


export const initialState: FileUploadReducerState = {
    files: [],
    allDone: false,
    hasFailed: false
};

export enum ActionKind {
    ADD_FILE = 'ADD_FILE',
    SET_PROGRESS = 'SET_PROGRESS',
    FAILED = 'FAILED',
}

function checkAllDone(list: Array<FileUploadState>): boolean {
    let has = list.length > 0 && !list.some(value => value.status === FileUploadStatus.PENDING || value.status === FileUploadStatus.UPLOADING);
    return has;
}

function checkHasFailed(list: Array<FileUploadState>): boolean {
    let has = list.some(value => {
        let res = value.status === FileUploadStatus.FAILED;
        return res;
    });
    return has;
}

function setProgress(state: FileUploadReducerState, action: FileUploadAction): FileUploadReducerState {
    let newList = [...state.files];
    let idx = newList.findIndex(value => value.fileName === action.filename);
    if(idx > -1) {
        newList[idx].progress = action.progress || 0;
        if(newList[idx].status !== FileUploadStatus.FAILED) {
            newList[idx].status = newList[idx].progress === 100 ? FileUploadStatus.DONE : FileUploadStatus.UPLOADING;
        }
    }

    return {files: newList, allDone: checkAllDone(newList), hasFailed: checkHasFailed(newList)};
}

function setFailed(state: FileUploadReducerState, action: FileUploadAction): FileUploadReducerState {
    let newList = [...state.files];
    let idx = newList.findIndex(value => value.fileName === action.filename);
    if(idx > -1) {
        newList[idx].status = FileUploadStatus.FAILED;
    }

    return {files: newList, allDone: checkAllDone(newList), hasFailed: true};
}

function addFile(state: FileUploadReducerState, action: FileUploadAction): FileUploadReducerState {
    let newFile: FileUploadState = {
        fileData: action.filedata,
        fileName: action.filename,
        progress: 0,
        status: FileUploadStatus.PENDING
    }
    let newList = [...state.files];
    newList.push(newFile);

    return {files: newList, allDone: false, hasFailed: checkHasFailed(newList)};
}

export function fileUploadReducer(state: FileUploadReducerState, action: FileUploadAction): FileUploadReducerState {
    switch (action.type) {
        case ActionKind.ADD_FILE: return addFile(state, action);
        case ActionKind.SET_PROGRESS: return setProgress(state, action);
        case ActionKind.FAILED: return setFailed(state, action);
        default:
            throw new Error();
    }
}
