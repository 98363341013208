import {AxiosResponse} from "axios";

export const MONTHS_NAMES: string[] = ['Януари', 'Февруари', 'Март', 'Април', 'Май', 'Юни', 'Юли', 'Август', 'Септември', 'Октомври', 'Ноември', 'Декември'];

export interface AppModule {
    id: number,
    name: string,
    url: string
    top_modul?: number,
    owner?: number,
    group_name: string,
    order_pos: number,
    deleted_at?: number
}

export interface IdName {
    id: number,
    name: string
}

export interface Account extends IdName{
    email: string;
    machine_id: number;
    department: string;
    position: string;
    is_admin: string;
    is_doctor: string;
    is_operator: string;
    is_super_operator: string;
    is_employee: string;
    employee_type: 0 | 1; // 0 - помощник; 1 - оператор
    data: any[];
    isNewPassword: boolean;
    password: string;
    active_to: string;
}

export const EMPTY_ACCOUNT: Account = {
    id: -1, name: '', email: '', machine_id: -1, position: '', department: '', is_admin: 'N', is_doctor: 'N', is_operator: 'N',
    is_super_operator: 'N', is_employee: 'N', employee_type: 0, data: [], isNewPassword: false, password: '', active_to: ''
}

export interface Supplier {

}
