import {Button, Card, CloseButton, Form, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faIndustry, faPencil, faPlus, faSave, faStore, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {EMPTY_MATERIAL_ITEM_PARAMS, MaterialGroup, MaterialItem} from "./ConfigMaterialsForm";
import {onlyPozitive, showErrorsListInToast} from "../../../common";
import {ConfirmDeleteModal} from "../../../common/ConfirmDeleteModal";
import {validateDate} from "../../../validations";


export interface EditMaterialItem {
    groupId: string;
    name: string;
    me: string;
    minQty: string;
    startQty: string;
    expense: string;
    sellPrice: string;
    expiryDate: string;
    position: string;
}

export interface ConfigMaterialDetailsProps {
    working: boolean;
    groups: Array<MaterialGroup>;
    material: MaterialItem;
    onSave: (material: MaterialItem) => any;
    onDelete: () => any;
    onCancel: () => any;
    zIndex?: number;
}

export default function ConfigMaterialDetails({working, groups, material, onSave, onDelete, onCancel, zIndex}: ConfigMaterialDetailsProps) {
    const refForm = useRef(null);
    const [editMaterial, setEditMaterial] = useState<EditMaterialItem>(() => {
        return {
            groupId: material.groupId || '',
            name: material.name || '',
            me: material.me || '',
            minQty: onlyPozitive(material.minQty) || '',
            startQty: onlyPozitive(material.startQty) || '',
            expense: onlyPozitive(material.expense) || '',
            sellPrice: onlyPozitive(material.sellPrice) || '',
            expiryDate: material.expiryDate || '',
            position: material.position || ''
        } as EditMaterialItem
    });
    const [deleteFlag, setDeleteFlag] = useState(false);


    function handleSubmit(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];
        const em = {...EMPTY_MATERIAL_ITEM_PARAMS};

        em.name = e.target.elements.name?.value.trim();
        if(em.name?.length === 0) errors.push("Въведете наименование.");

        em.me = e.target.elements.me?.value;
        if(em.me?.length === 0) errors.push("Въведете мерна единица.");

        em.minQty = onlyPozitive(e.target.elements.minQty?.value);
        em.startQty = onlyPozitive(e.target.elements.startQty?.value);
        em.expense = onlyPozitive(e.target.elements.expense?.value);
        em.sellPrice = onlyPozitive(e.target.elements.sellPrice?.value);
        em.expiryDate = e.target.elements.expiryDate?.value;
        em.position = e.target.elements.position?.value || '';

        em.id = material.id;
        em.groupId = e.target.elements.groupId?.value;

        if(errors.length === 0) {
            onSave(em);
        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    console.log(material)
    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: zIndex || 9990}} />
            <Modal show={!deleteFlag} size={"lg"} onHide={onCancel} style={{zIndex: zIndex ? zIndex : 9991}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faStore} className={"mr-3"}/>Детайли за
                        материал</Modal.Title>
                    <CloseButton onClick={onCancel}/>
                </Modal.Header>
                <Modal.Body>
                    <Form ref={refForm} onSubmit={handleSubmit}>
                        <Card className={"border-0"}>
                            <Card.Body className={"max-h-60vh scrollable"}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Група</Form.Label>
                                    <Form.Control as={"select"} name="groupId"
                                                  defaultValue={editMaterial.groupId}
                                                 onChange={(e) => setEditMaterial({...editMaterial, groupId: e.target.value})}>
                                        {
                                            groups?.map(
                                                g => <option key={g.id} value={g.id}>{ g.name }</option>
                                            )
                                        }

                                    </Form.Control>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Наименование</Form.Label>

                                    <Form.Control name={"name"} value={editMaterial.name}
                                                  onChange={(e) => setEditMaterial({...editMaterial, name: e.target.value})}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Мерна единица</Form.Label>
                                    <Form.Control name={"me"} value={editMaterial.me}
                                                  onChange={(e) => setEditMaterial({...editMaterial, me: e.target.value})}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Начално количество</Form.Label>
                                    <Form.Control name={"startQty"} value={editMaterial.startQty}
                                                  onChange={(e) => setEditMaterial({...editMaterial, startQty: e.target.value})}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Минимално количество</Form.Label>
                                    <Form.Control name={"minQty"} value={editMaterial.minQty}
                                                  onChange={(e) => setEditMaterial({...editMaterial, minQty: e.target.value})}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Разход за единица материал (лв)</Form.Label>
                                    <Form.Control name={"expense"} value={editMaterial.expense}
                                        onChange={(e) => setEditMaterial({...editMaterial, expense: e.target.value})}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Продажна цена (лв)</Form.Label>
                                    <Form.Control name={"sellPrice"} value={editMaterial.sellPrice}
                                        onChange={(e) => setEditMaterial({...editMaterial, sellPrice: e.target.value})}/>
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Годен до</Form.Label>
                                    <Form.Control type="text" value={editMaterial.expiryDate}
                                                  name={"expiryDate"}
                                                  placeholder={"формат: дд.мм.гггг"}
                                                  onChange={(e) => setEditMaterial({...editMaterial, expiryDate: e.target.value})}
                                                  onBlur={() => setEditMaterial({...editMaterial, expiryDate: validateDate(editMaterial.expiryDate)})}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Стелаж No:</Form.Label>
                                    <Form.Control name={"position"} value={editMaterial.position}
                                                  onChange={(e) => setEditMaterial({...editMaterial, position: e.target.value})}/>
                                </Form.Group>
                            </Card.Body>
                            <Card.Footer>
                                {
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button type={"submit"}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>&nbsp;
                                            <Button onClick={() => onCancel()} variant={"secondary"}><FontAwesomeIcon
                                                icon={faCancel}/> Откажи</Button>
                                            <Button onClick={() => setDeleteFlag(true)} variant={"danger"} style={{ float: "right"}}>
                                                <FontAwesomeIcon
                                                icon={faTrash}/> Изтрий</Button>
                                        </>
                                }
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>

            {
                deleteFlag && <ConfirmDeleteModal working={working} onConfirm={onDelete} onReject={() => setDeleteFlag(false)} />
            }
        </>
    )
}
