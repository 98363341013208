import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, Col, Container, Form, Modal, Row, Spinner, Tab, Table, Tabs,} from "react-bootstrap";
import {faPlus, faPrint, faRedo, faStore} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {MaterialGroup, MaterialItem} from "../config/materials/ConfigMaterialsForm";
import {API, API_CALL} from "../../api";
import {
    dateToString,
    isError,
    isLoading,
    isSuccess, mapToObject,
    showErrorsInToast,
    translateError, zeroToEmptyStringAsCurrency, zeroToEmptyStringAsCurrencyWithCommas,
    zeroToEmptyStringAsNumber
} from "../../common";
import {ReadOnlyDatePickerComponent} from "../../common/ReadOnlyDatePickerComponent";
import {ConfirmModal} from "../../common/ConfirmModal";
import moment from "moment";
import SwitchComponent from "../../common/SwitchComponent";

interface StoreMaterialData {
    income: string;
    outcome: string;
    correction: string;
}

export function StorePage() {
    const [reportDate, setReportDate] = useState<Date>(new Date());
    const [groups, setGroups] = useState<Array<MaterialGroup>>([]);
    const [materials, setMaterials] = useState<Array<MaterialItem>>([]);
    const [materialsList, setMaterialsList] = useState<Array<MaterialItem>>([]);
    const [changed, setChanged] = useState(false);
    const [data, setData] = useState<Map<number, StoreMaterialData>>(new Map());
    const [working, setWorking] = useState(false);
    const [confirmSave, setConfirmSave] = useState(false);
    const [activeGroup, setActiveGroup] = useState<MaterialGroup | null>(null);
    const [filter, setFilter] = useState('');
    const [activeClinic, setActiveClinic] = useState(0);

    useEffect(() => {
        loadGroups()
    }, [activeClinic]);


    function loadGroups() {
        API.getMaterialsGroups(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setGroups(api_call.data.data.groups);
                    if (api_call.data.data.groups.length > 0) {
                        setActiveGroup(api_call.data.data.groups[0]);
                        loadMaterials();
                    }
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на групите!', translateError)
            }, activeClinic
        )
    }

    function loadMaterials() {
        API.getMaterialsItems(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setMaterials([...api_call.data.data.materials]);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на материалите!', translateError)
            }, undefined, moment(reportDate).format('Y-MM-DD')
        )
    }

    useEffect(() => {
        let _mat: Array<MaterialItem> = [];
        if (materials && activeGroup) {
            _mat = materials.filter(
                m => {
                    return (activeGroup && m && m.name && m?.groupId === activeGroup.id && (m?.name?.toLowerCase().indexOf(filter.toLowerCase()) >= 0))
                }
            )
        }
        setMaterialsList(_mat);
    }, [materials, activeGroup, filter]);

    useEffect(() => {
        loadData()
    }, [materials]);


    useEffect(() => {
        loadMaterials()
    }, [reportDate]);

    function changeIncome(id: number, value: string) {
        if (data.has(id)) {
            // @ts-ignore
            const old: StoreMaterialData = data.get(id);
            data.set(id, {...old, income: value} as StoreMaterialData);

            setData(new Map(data));
        }
    }

    function validateIncome(id: number) {
        if (data.has(id)) {
            const old = data.get(id) || {income: '0', outcome: '0', correction: '0', singlePrice: '0'};
            const tryNum = Number.parseFloat(old.income.replace(',', '.'));
            if (!isNaN(tryNum)) {
                data.set(id, {
                    outcome: old.outcome, correction: old.correction,
                    income: zeroToEmptyStringAsNumber(old.income).replace('.00', '')
                })
            } else {
                data.set(id, {outcome: old.outcome, correction: old.correction, income: '0'})
            }
        }

        setChanged(true);
    }

    function changeOutcome(id: number, value: string) {
        if (data.has(id)) {
            // @ts-ignore
            const old: StoreMaterialData = data.get(id);
            data.set(id, {...old, outcome: value} as StoreMaterialData);

            setData(new Map(data));
        }
    }

    function changeCorrection(id: number, value: string) {
        if (data.has(id)) {
            // @ts-ignore
            const old: StoreMaterialData = data.get(id);
            data.set(id, {...old, correction: value} as StoreMaterialData);

            setData(new Map(data));
        }
    }

    function changeSinglePrice(id: number, value: string) {
        if (data.has(id)) {
            // @ts-ignore
            const old: StoreMaterialData = data.get(id);
            data.set(id, {...old, singlePrice: value} as StoreMaterialData);

            setData(new Map(data));
        }
    }

    function validateOutcome(id: number) {
        if (data.has(id)) {
            const old = data.get(id) || {income: '0', outcome: '0', correction: '0', singlePrice: '0'};
            const tryNum = Number.parseFloat(old.outcome.replace(',', '.'));
            if (!isNaN(tryNum)) {
                data.set(id, {
                    income: old.income,
                    correction: old.correction,
                    outcome: zeroToEmptyStringAsNumber(old.outcome).replace('.00', '')
                })
            } else {
                data.set(id, {
                    income: old.income,
                    correction: old.correction,
                    outcome: '0',
                })
            }
        }

        setChanged(true);
    }

    function validateCorrection(id: number) {
        if (data.has(id)) {
            const old = data.get(id) || {income: '0', outcome: '0', correction: '0', singlePrice: '0'};
            const tryNum = Number.parseFloat(old.correction.replace(',', '.'));
            if (!isNaN(tryNum)) {
                data.set(id, {
                    income: old.income, outcome: old.outcome,
                    correction: zeroToEmptyStringAsNumber(old.correction).replace('.00', '')
                })
            } else {
                data.set(id, {income: old.income, outcome: old.outcome, correction: '0'})
            }
        }

        setChanged(true);
    }

    function loadData() {

        API.getMaterialsData(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    const report = api_call.data.data.report;

                    materials.forEach(
                        m => {
                            if (report[m.id || 0]) {
                                data.set(m.id || 0, {
                                    income: report[m.id || 0].income,
                                    outcome: report[m.id || 0].outcome,
                                    correction: report[m.id || 0].correction,
                                })
                            } else {
                                data.set(m.id || 0, {income: '0', outcome: '0', correction: '0'})
                            }
                        }
                    )
                    setData(new Map(data));
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на оборотите!', translateError)
            }, moment(reportDate).format('YYYY-MM-DD')
        )
    }

    function saveData() {
        setConfirmSave(true)
    }

    function doSaveData(btn: string) {
        setConfirmSave(false)

        if (btn === "Запази") {
            API.postMaterialsData(
                (api_call: API_CALL) => {
                    setWorking(isLoading(api_call));
                    if (isSuccess(api_call)) {
                        loadMaterials();
                    }
                    if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на материалите!', translateError)
                }, moment(reportDate).format('YYYY-MM-DD'), mapToObject(data)
            )
        }
    }

    let totalExp = 0;
    let total = 0;

    return (
        <>
            <Container fluid>
                <Card className={"border-0"}>
                    <Card.Header>
                        <Row className={"pt-2 pb-0"}>
                            <Col>
                                <h5 className={"text-uppercase font-weight-bold"}>
                                    <FontAwesomeIcon icon={faStore} className={"mr-3"}/>Складови
                                    наличности
                                </h5>
                            </Col>
                            <Col xs={"auto"} style={{lineHeight: "2.25rem"}}>
                                <ReadOnlyDatePickerComponent onChange={(date: Date) => {
                                    setReportDate(date)
                                }} value={dateToString(reportDate)}/>
                            </Col>
                            <Col xs={"auto"} style={{lineHeight: "2.25rem"}}>
                                <Button onClick={() => window.print()} variant={"secondary"}>
                                    <FontAwesomeIcon icon={faPrint}/>
                                </Button>
                            </Col>
                        </Row>
                        <div className={"d-none d-print-inline-block font-weight-bold p-0 m-0 mb-2"}>
                            <Row className={"pt-0 mt-0"}>
                                <Col className={"align-content-center justify-content-center"}>
                                    <SwitchComponent selector={activeClinic}
                                                     values={new Map([[0, "ЕСТЕТИЧНА КЛИНИКА"], [1, "ДЕНТАЛНА КЛИНИКА"]])}/>, {activeGroup?.name?.toLocaleUpperCase()}
                                </Col>
                            </Row>
                        </div>
                        <Tabs activeKey={activeClinic} className={"d-print-none"}
                              onSelect={eventKey => setActiveClinic(parseInt(eventKey || "0"))}>
                            <Tab eventKey={0} title={"Естетична клиника"}/>
                            <Tab eventKey={1} title={"Дентална клиника"}/>
                        </Tabs>
                    </Card.Header>
                    <Card.Body className={"max-h-60vh scrollable"}>
                        {
                            activeGroup &&
                            <>
                                <Tabs activeKey={activeGroup.name || undefined} variant={"pills"}
                                      onSelect={(eventKey) => setActiveGroup(groups.find(g => g.name === eventKey) || null)}
                                      className={"mb-3 ml-0 d-print-none"}
                                >
                                    {
                                        groups.map(
                                            g => <Tab key={g.id} eventKey={g.name} title={g.name}></Tab>
                                        )
                                    }
                                </Tabs>
                                <Row className={"d-print-none"}>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Control type="text" value={filter}
                                                          placeholder={"Филтър..."}
                                                          onChange={(e) => setFilter(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                <div className="table-container max-h-44vh">
                                    <Table bordered size={"sm"} hover className={"text-light"}>
                                        <thead>
                                        <tr className={"bg-secondary"}>
                                            <th>Име на материала</th>
                                            <th className={"w-120px"}>М/Е</th>
                                            <th className={"text-right w-120px"}>Мин.кол.</th>
                                            <th className={"text-right w-120px"}>Необх.кол.</th>
                                            <th className={"text-right w-120px"}>Текущо кол.</th>
                                            <th className={"text-right w-120px"}>Разход (лв.)</th>
                                            <th className={"text-right w-120px"}>Всичко (лв.)</th>
                                            <th className={"w-100px"}>Приход</th>
                                            <th className={"w-100px"}>Разход</th>
                                            <th className={"w-100px"}>Корекция</th>
                                        </tr>
                                        </thead>
                                        <tbody className={"text-dark"}>
                                        {
                                            materialsList.length > 0 ?
                                                <>
                                                    {
                                                        materialsList.map(
                                                            m => {
                                                                const neededQty = (m.minQty && m.onStockQty) && (m.minQty - m.onStockQty > 0) ? (m.minQty - m.onStockQty) : 0;
                                                                total += (1 * (m.expense || 0) * (m.upToDateQty || 0));
                                                                return (
                                                                    <tr key={m.id} style={{lineHeight: "2rem"}}
                                                                        className={neededQty > 0 ? 'bg-danger-light' : ''}>
                                                                        <td>{m.name}</td>
                                                                        <td>{m.me}</td>
                                                                        <td className={"text-right"}>{zeroToEmptyStringAsNumber(m.minQty?.toString() || "")}</td>
                                                                        <td className={"text-right"}>{zeroToEmptyStringAsNumber(neededQty.toString())}</td>
                                                                        <td className={"text-right bg-secondary-light font-weight-bold"}>{zeroToEmptyStringAsNumber(m.upToDateQty?.toString() || "")}</td>
                                                                        {/*<td className={"text-right bg-secondary-light font-weight-bold"}>{zeroToEmptyStringAsNumber(m.onStockQty?.toString() || "")}</td>*/}
                                                                        <td className={"text-right bg-secondary-light font-weight-bold"}>{zeroToEmptyStringAsCurrency(m.expense?.toString() || "")}</td>
                                                                        <td className={"text-right bg-secondary-light font-weight-bold"}>
                                                                            {zeroToEmptyStringAsCurrency((1 * (m.expense || 0) * (m.upToDateQty || 0)).toString())}</td>
                                                                        <td className={"text-center w-100px bg-success-light"}>
                                                                            <Form.Control size={"sm"}
                                                                                          value={data.get(m.id || 0)?.income}
                                                                                          className={"text-right w-100 bg-success font-weight-bold"}
                                                                                          onChange={(e) => changeIncome(m.id || 0, e.target.value)}
                                                                                          onBlur={(e: any) => validateIncome(m.id || 0)}
                                                                            />
                                                                        </td>
                                                                        <td className={"text-center w-100px bg-danger-light"}>
                                                                            <Form.Control size={"sm"}
                                                                                          readOnly
                                                                                          value={data.get(m.id || 0)?.outcome}
                                                                                          className={"text-right w-100 bg-danger-light font-weight-bold"}
                                                                                          onChange={(e) => changeOutcome(m.id || 0, e.target.value)}
                                                                                          onBlur={(e: any) => validateOutcome(m.id || 0)}
                                                                            />
                                                                        </td>
                                                                        <td className={"text-center w-100px bg-primary-light"}>
                                                                            <Form.Control size={"sm"}
                                                                                          value={data.get(m.id || 0)?.correction}
                                                                                          className={"text-right w-100 bg-primary-light font-weight-bold"}
                                                                                          onChange={(e) => changeCorrection(m.id || 0, e.target.value)}
                                                                                          onBlur={(e: any) => validateCorrection(m.id || 0)}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        )
                                                    }
                                                    <tr className={"bg-secondary text-light font-weight-bold"}>
                                                        <td colSpan={6} className={"text-right"}>Всичко:</td>
                                                        <td className={"text-right"}>{zeroToEmptyStringAsCurrencyWithCommas(total.toString())}</td>
                                                        <td colSpan={3}></td>
                                                    </tr>
                                                </>
                                                :
                                                <tr>
                                                    <td colSpan={11}>{!working && <>Няма материали в тази
                                                        група</>}</td>
                                                </tr>
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                        }
                    </Card.Body>
                    <Card.Footer>
                        {
                            working ? <Spinner animation="border"/> :
                                <>
                                    <Button
                                        disabled={!changed}
                                        onClick={saveData}
                                    ><FontAwesomeIcon
                                        icon={faPlus}/> Запази промените</Button>

                                    <Button
                                        disabled={!changed}
                                        onClick={() => loadData()}
                                        variant={"secondary"}
                                        style={{float: "right"}}
                                    ><FontAwesomeIcon
                                        icon={faRedo}/> Изчисти промените</Button>

                                </>
                        }
                    </Card.Footer>
                </Card>

            </Container>


            {
                confirmSave && <ConfirmModal
                    title={"Внимание"}
                    message={"Моля, потвърдете записването на промените!"}
                    onClose={() => {
                    }}
                    buttons={["Запази", "Откажи"]}
                    primaryBtn={"Запази"}
                    onButtonClick={(btn) => doSaveData(btn)}

                />
            }
        </>
    )
}
