import React, {FC} from "react";
import {Table} from "react-bootstrap";
import {zeroToEmptyStringAsCurrencyWithCommas} from "../../common";

interface Props {
    report: any;
}

function PatientsCells(props: { patient: any }) {
    return <>
        <td> {props.patient.name} </td>
        <td> {props.patient.date} </td>
        <td> {props.patient.manipulation} </td>
        <td> {zeroToEmptyStringAsCurrencyWithCommas(props.patient.paidByBankNotConf)} </td>
        </>;
}

function DoctorCells(props: { doctor: any, idx: number }) {
    const doctor = props.doctor;
    const patients = doctor.patients.filter( (p: any) => p.paidByBankNotConf !== 0);
    if(patients.length === 0) return null;

    return (
        <>
        <tr>
            <td className={"text-right"} rowSpan={patients.length}> {(props.idx + 1).toString()} </td>
            <td rowSpan={patients.length}> {doctor.name} </td>
            <PatientsCells patient={patients[0]} />
        </tr>
            {
                patients.map(
                    (p: any, idx: number) => idx === 0 ? null :
                        <tr key={idx} >
                            <PatientsCells patient={p} key={idx} />
                        </tr>
                )
            }
        </>
    );
}

const DentalReportBankNotConfirmed: FC<Props> = ({report}) => {
    return (<Table bordered size={"sm"} hover striped className={"text-light small"}>
        <thead className={"not-sticky"}>
        <tr className={"bg-secondary"}>
            <th className={"text-right"}>No:</th>
            <th className={"text-nowrap"}>Доктор</th>
            <th className={"text-center"}>Пациент</th>
            <th className={"text-nowrap"}>Дата</th>
            <th className={"text-center"}>Манипулация</th>
            <th className={"text-center"}>Сума</th>
        </tr>
        </thead>
        <tbody className={"text-dark"}>
        { report.doctors.map((doctor: any, idx: number) => <DoctorCells doctor={doctor} idx={idx} key={idx} />) }
        {/*<tr className={"text-right font-weight-bold bg-secondary-light"}>*/}
        {/*    <td className={"text-right font-weight-bold"} colSpan={2}> ВСИЧКО:</td>*/}
        {/*    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByCash)} </td>*/}
        {/*    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByCard)} </td>*/}
        {/*    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByAvans)} </td>*/}
        {/*    <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByCash + report.total.paidByCard + report.total.paidByAvans)} </td>*/}
        {/*    <td className={"text-right bg-success-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByBankConf)} </td>*/}
        {/*    <td className={"text-right bg-danger-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByBankNotConf)} </td>*/}
        {/*    <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByBank)} </td>*/}
        {/*    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.paidByNhif)} </td>*/}
        {/*    <td className={"text-right bg-primary-light"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.price)} </td>*/}
        {/*    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.expenses)} </td>*/}
        {/*    <td className={"text-right"}> {zeroToEmptyStringAsCurrencyWithCommas(report.total.price - report.total.expenses)} </td>*/}
        {/*</tr>*/}
        </tbody>
    </Table>)
}

export default DentalReportBankNotConfirmed;
