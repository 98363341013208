import {ClientRecord} from "./DentalClinicPage";
import {Button, Col, Form, InputGroup, Modal, Row, Spinner} from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import React, {useState} from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import {API, API_CALL} from "../../api";
import {
    dateToString,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    showErrorsListInToast, textToFloat,
    translateError,
    zeroToEmptyString, zeroToEmptyStringAsCurrency, zeroToEmptyStringAsCurrencyWithCommas
} from "../../common";
import {StringInput} from "../../common/StringInput";
import ConfigClientsForm from "../config/clients/ConfigClientsForm";
import ConfigManipulationsForm from "../config/manipulations/ConfigManipulationsForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faList} from "@fortawesome/free-solid-svg-icons";
import BoldFormLabel from "../../common/FormLabel";
import moment from "moment";
import {Account} from "../../types";
import SelectUserComponent from "../config/users/SelectUserComponent";
import ConfigClinicsForm, {ClinicItem, EMPTY_CLINIC_ITEM} from "../config/clinics/ConfigClinicsForm";

export interface ClientRecordManipulation {
    id: number;
    clinicId: number;
    clinicName: string;
    clientId: number;
    recordId: number;
    doctorId: number;
    doctorName: string;
    manipulationId?: number;
    manipulationDate: Date;
    manipulationName: string;
    manipulationDescription?: string;
    manipulationExpenses?: number;
    manipulationExpensesDescription?: string;
    manipulationPrice?: number;
    manipulationPaidByCash?: number;
    manipulationPaidByCard?: number;
    manipulationPaidByNhif?: number;
    manipulationPaidByBank?: number;
    bankConfirmed: boolean;
    bankConfirmedDate?: Date;
    manipulationPaidByPrepaid?: number;
    description?: string;
}


export interface ClientRecordManipulationFormProps {
    record: ClientRecord;
    manipulation: ClientRecordManipulation;
    onClose: () => void;
    zIndex?: number;
}

export default function ClientRecordManipulationForm(props: ClientRecordManipulationFormProps) {
    const [working, setWorking] = useState(false);
    const [manipulation, setManipulation] = useState<ClientRecordManipulation>(props.manipulation);
    const [selectManipulationFlag, setSelectManipulationFlag] = useState(false);
    const [selectDoctorFlag, setSelectDoctorFlag] = useState(false);
    const [selectClinicFlag, setSelectClinicFlag] = useState(false);

    function handleClose() {
        props.onClose();
    }

    function saveManipulation() {
        let errors = [];
        if (!manipulation.manipulationDate) {
            errors.push("Моля, посочете дата за манипулацията.");
        }

        if (manipulation.manipulationName.trim().length === 0) {
            errors.push("Моля, посочете извършената манипулация.");
        }

        if (errors.length > 0) {
            showErrorsListInToast("Внимание", errors);
            return;
        }


        API.postClientDentalRecordManipulation(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    handleClose();
                }
            }, {...manipulation,
                manipulationDate: moment(manipulation.manipulationDate).format("DD.MM.YYYY"),
                bankConfirmedDate: manipulation.bankConfirmedDate ?
                    moment(manipulation.bankConfirmedDate).format("DD.MM.YYYY") : null
            }
        );
    }

    return <>
        <Modal show={true} onHide={handleClose} style={{zIndex: (props.zIndex || 1500)}} size={"lg"}>
            <Modal.Header closeButton>
                <Modal.Title>Манипулация</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Form className={working ? " opacity-50 " : ""}>
                    <Row>
                        <Col xs={"auto"}>
                            <Form.Group className={"w-180px"}>
                                <BoldFormLabel>Дата на извършване: </BoldFormLabel>
                                <ReactDatePicker className={"form-control"} isClearable
                                                 dateFormat="dd.MM.yyyy"
                                                 onChange={date => setManipulation({
                                                     ...manipulation,
                                                     manipulationDate: date as Date
                                                 })}
                                                 selected={manipulation.manipulationDate}
                                />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className={"font-weight-bold"}>Доктор:</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control value={manipulation?.doctorName} disabled={true}/>
                                    <Button variant="outline-secondary"
                                            onClick={() => setSelectDoctorFlag(true)}>
                                        <FontAwesomeIcon icon={faList}/>
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label className={"font-weight-bold"}>Клиника:</Form.Label>
                                <InputGroup className="mb-3">
                                    <Form.Control value={manipulation?.clinicName} disabled={true}/>
                                    <Button variant="outline-secondary"
                                            onClick={() => setSelectClinicFlag(true)}>
                                        <FontAwesomeIcon icon={faList}/>
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <BoldFormLabel>Манипулация:</BoldFormLabel>
                                <InputGroup className="mb-3">
                                    <Form.Control value={manipulation.manipulationName} disabled={true}/>
                                    <Button variant="outline-secondary" onClick={() => setSelectManipulationFlag(true)}>
                                        <FontAwesomeIcon icon={faList}/>
                                    </Button>
                                </InputGroup>
                            </Form.Group>
                        </Col>
                        <Col xs={2}>
                            <Form.Group>
                                <BoldFormLabel>Цена:</BoldFormLabel>
                                <StringInput
                                    value={zeroToEmptyStringAsCurrencyWithCommas(manipulation.manipulationPrice?.toString() ?? '0')}
                                    onChange={e => setManipulation({
                                        ...manipulation,
                                        manipulationPrice: textToFloat(e) || undefined
                                    })}/>
                            </Form.Group>
                        </Col>
                    </Row>


                    <Row>
                        <Col xs={12}>
                            <Form.Group>
                                <StringInput
                                    value={manipulation.manipulationDescription || "Зъб(и): "}
                                    onChange={e => setManipulation({
                                        ...manipulation,
                                        manipulationDescription: e || undefined
                                    })}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={2}>
                            <Form.Group>
                                <BoldFormLabel>Разход:</BoldFormLabel>
                                <StringInput
                                    value={zeroToEmptyStringAsCurrencyWithCommas(manipulation.manipulationExpenses?.toString() ?? '0')}
                                    onChange={e => setManipulation({
                                        ...manipulation,
                                        manipulationExpenses: textToFloat(e) || undefined
                                    })}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <BoldFormLabel>Забележки:</BoldFormLabel>
                                <StringInput value={manipulation.manipulationExpensesDescription || ""}
                                             onChange={e => setManipulation({
                                                 ...manipulation,
                                                 manipulationExpensesDescription: e || undefined
                                             })}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className={"mb-2"}>
                        <Col xs={"auto"}>
                            <BoldFormLabel>Плащане</BoldFormLabel>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group>
                                <BoldFormLabel>в брой:</BoldFormLabel>
                                <StringInput
                                    value={zeroToEmptyStringAsCurrencyWithCommas(manipulation.manipulationPaidByCash?.toString() ?? '0')}
                                    onChange={e => setManipulation({
                                        ...manipulation,
                                        manipulationPaidByCash: textToFloat(e) || undefined
                                    })}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <BoldFormLabel>с карта:</BoldFormLabel>
                                <StringInput
                                    value={zeroToEmptyStringAsCurrencyWithCommas(manipulation.manipulationPaidByCard?.toString() ?? '0')}
                                    onChange={e => setManipulation({
                                        ...manipulation,
                                        manipulationPaidByCard: textToFloat(e) || undefined
                                    })}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <BoldFormLabel>по НЗОК:</BoldFormLabel>
                                <StringInput
                                    value={zeroToEmptyStringAsCurrencyWithCommas(manipulation.manipulationPaidByNhif?.toString() ?? '0')}
                                    onChange={e => setManipulation({
                                        ...manipulation,
                                        manipulationPaidByNhif: textToFloat(e) || undefined
                                    })}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <BoldFormLabel>по банка:</BoldFormLabel>
                                <StringInput
                                    value={zeroToEmptyStringAsCurrencyWithCommas(manipulation.manipulationPaidByBank?.toString() ?? '0')}
                                    onChange={e => setManipulation({
                                        ...manipulation,
                                        manipulationPaidByBank: textToFloat(e) || undefined
                                    })}/>
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group>
                                <BoldFormLabel>от аванс:</BoldFormLabel>
                                <StringInput
                                    value={zeroToEmptyStringAsCurrencyWithCommas(manipulation.manipulationPaidByPrepaid?.toString() ?? '0')}
                                    onChange={e => setManipulation({
                                        ...manipulation,
                                        manipulationPaidByPrepaid: textToFloat(e) || undefined
                                    })}/>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className={"mb-2"}>
                        <Col className={"text-right pt-2"}>
                            <Form.Check label={"Банковото плащане е получено на:"}
                            checked={manipulation.bankConfirmed}
                            onChange={event => setManipulation(
                                {...manipulation, bankConfirmed: event.target.checked}
                            )}/>
                        </Col>
                        <Col xs={"auto"}>
                            <ReactDatePicker className={"form-control w-140px"} isClearable
                                             dateFormat="dd.MM.yyyy"
                                             onChange={date => setManipulation({
                                                 ...manipulation,
                                                 bankConfirmedDate: date as Date
                                             })}
                                             selected={manipulation.bankConfirmedDate}
                            />
                        </Col>
                    </Row>

                    <Form.Group>
                        <BoldFormLabel>Бележки:</BoldFormLabel>
                        <Form.Control as={"textarea"} value={manipulation.description}
                                      onChange={e => setManipulation({...manipulation, description: e.target.value})}
                        />
                    </Form.Group>
                </Form>

            </Modal.Body>
            <Modal.Footer>
                {
                    working ? <Spinner animation={"border"}/> :
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                Откажи
                            </Button>
                            <Button variant="primary" onClick={saveManipulation} disabled={working}>
                                Запази
                            </Button>
                        </>
                }
            </Modal.Footer>
        </Modal>

        {
            selectManipulationFlag &&
            <ConfigManipulationsForm onClose={() => setSelectManipulationFlag(false)}
                                     zIndex={1600}
                                     onSelect={(c) => {
                                         if (c) {
                                             setManipulation({
                                                 ...manipulation,
                                                 manipulationId: c.id,
                                                 manipulationName: c.name,
                                                 manipulationPrice: c.price
                                             });
                                         }
                                         setSelectManipulationFlag(false);
                                     }}
            />
        }

        {
            selectDoctorFlag &&
            <>
                <Modal show={true} style={{zIndex: 1610}}>
                    <Modal.Body>
                        <SelectUserComponent forDate={dateToString(manipulation.manipulationDate)}
                                             doctors={true}
                                             onSelect={(u) => {
                                                 setManipulation({
                                                     ...manipulation,
                                                     doctorId: u.id,
                                                     doctorName: u.name
                                                 });
                                                 setSelectDoctorFlag(false)
                                             }}
                                             onCancel={() => setSelectDoctorFlag(false)}
                        />
                    </Modal.Body>
                </Modal>
            </>
        }

        {
            selectClinicFlag &&
            <ConfigClinicsForm onClose={() => setSelectClinicFlag(false)}
                                     zIndex={1600}
                                     onSelect={(c) => {
                                         if (c) {
                                             setManipulation({
                                                 ...manipulation,
                                                 clinicId: c.id,
                                                 clinicName: c.name,
                                             });
                                         }
                                         setSelectClinicFlag(false);
                                     }}
            />
        }
    </>
}
