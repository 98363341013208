import {ClientRecord} from "./DentalClinicPage";
import {Button, Col, Dropdown, Nav, Row, Spinner} from "react-bootstrap";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAdd, faClipboardCheck, faFileMedical,} from "@fortawesome/free-solid-svg-icons";
import CardRounded from "../../common/CardRounded";
import ClientDentalRecordManipulations from "./ClientDentalRecordManipulations";
import ClientRecordManipulationForm from "./ClientRecordManipulationForm";
import UploadFilesDialog from "../../common/upload-files-dialog/UploadFilesDialog";
import {API, API_CALL} from "../../api";
import {isError, isLoading, isSuccess, showErrorsInToast, translateError} from "../../common";
import ClientDentalRecordDocuments from "./ClientDentalRecordDocuments";

function BlockHeader(props: { title: string, children: any }) {
    return <Row className={"justify-content-center align-content-center"}>
        <Col><h6 className={"lh-2em"}>{props.title}</h6></Col>
        <Col className={"text-right"}>
            {
                props.children
            }
        </Col>
    </Row>
}

export enum DENTAL_RECORD_DOCUMENT_TYPE {
    'Пародонтална карта' = 'Пародонтална карта',
    'Рентгенова снимка' = 'Рентгенова снимка',
    'Фотоснимкa' = 'Фотоснимкa',
    'Други документи' = 'Други документи'
}

export const DENTAL_RECORD_DOCUMENT_TYPE_KEYS = Object.keys(DENTAL_RECORD_DOCUMENT_TYPE);

interface UploadFileDescriptor {
    clientId: number;
    recordId: number;
    documentType: string;
}

export interface ClientRecordDocument {
    id: number;
    clientId: number;
    recordId: number;
    documentType: number;
    documentDate: Date;
    fileName: string;
    fileType: string;
}

export default function ClientDentalRecords(props: { clientName: string;
    clientPrepaid: number;
    records: Array<ClientRecord> | null;
    onRecordChanged: (record: ClientRecord | null) => void;
    onReload: () => void;
    onUpdatePrepaid: (balance: number) => void; }) {
    const [working, setWorking] = useState(false);
    const [showManipulationForm, setShowManipulationForm] = useState(false);
    const [activeRecord, setActiveRecord] = useState<ClientRecord | null>(null);
    const [documents, setDocuments] = useState<Array<ClientRecordDocument>>([]);
    const [uploadType, setUploadType] = useState<string | null>(null);

    useEffect(
        () => {
            if (props.records && props.records.length > 0) {
                if (!activeRecord) {
                    setActiveRecord(props.records[0]);
                } else {
                    let id = activeRecord.id;
                    let record = props.records.find(r => r.id === id) || null;
                    setActiveRecord(record || props.records[0]);
                }
            } else setActiveRecord(null);
        }, [props.records]
    );

    function loadDocuments() {
        if (activeRecord) {
            API.getClientDentalRecordDocuments(
                (apiCall: API_CALL) => {
                    setWorking(isLoading(apiCall));
                    if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно зареждане на документите.', translateError);
                    if (isSuccess(apiCall)) {
                        setDocuments(apiCall.data.data.documents.map(
                            (r: any) => {
                                return {
                                    ...r, documentDate: moment(r.documentDate, 'DD.MM.YYYY').toDate()
                                } as ClientRecordDocument;
                            }
                        ));
                    }
                }, activeRecord!.id
            );
        }
    }

    useEffect(() => {
        props.onRecordChanged(activeRecord);
        loadDocuments();
    }, [activeRecord]);

    if (!props.records) return null;
    if (props.records.length === 0) return <Row className={"mt-4 font-weight-bold"}><Col>Пациента няма регистрирани
        лечения. Моля, започнете ново лечение от бутона долу.</Col></Row>;

    function addManipulation() {
        setShowManipulationForm(true);
    }

    function uploadDocuments(docType: string) {
        setUploadType(docType);
    }

    function doDeleteDocument(document: ClientRecordDocument) {
        if (activeRecord) {
            API.deleteClientDentalRecordDocument(
                (apiCall: API_CALL) => {
                    setWorking(isLoading(apiCall));
                    if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване на документа.', translateError);
                    if (isSuccess(apiCall)) {
                        loadDocuments();
                    }
                }, activeRecord!.id, document.id
            );
        }
    }

    return <>
        <Row className={"border border-secondary pt-2 pb-2 mt-3 rounded shadow-sm d-print-none"}>
            <Col xs={"auto"}>
                <h6 className={"lh-2em"}>Изберете дата:</h6>
                <CardRounded>
                    <Nav className="flex-column" variant={"pills"} activeKey={activeRecord?.id}>
                        {
                            props.records.map(
                                r => <Nav.Link key={r.id}
                                               onClick={() => setActiveRecord(r)}
                                               eventKey={r.id}><FontAwesomeIcon icon={faClipboardCheck}/>
                                    <span
                                        className={"ml-3"}>{moment(r.recordDate).format("DD.MM.YYYY")}</span></Nav.Link>
                            )
                        }
                    </Nav>
                </CardRounded>
            </Col>

            {
                activeRecord && <>
                    <Col>
                        <BlockHeader title={"План за лечение:"}>
                        </BlockHeader>
                        <CardRounded>
                            <div dangerouslySetInnerHTML={{__html: activeRecord?.treatmentPlan || "Не е изготвен план."}}
                                 className={"small"}></div>
                        </CardRounded>
                    </Col>


                    <Col xs={4}>
                        <BlockHeader title={"Извършени манипулации:"}>
                            <Button size={"sm"} variant={"outline-primary"} onClick={addManipulation}>
                                <FontAwesomeIcon icon={faAdd}/>
                            </Button>
                        </BlockHeader>
                        <CardRounded>
                            <ClientDentalRecordManipulations record={activeRecord || undefined}
                                                             onUpdatePrepaid={props.onUpdatePrepaid}
                            />
                        </CardRounded>
                    </Col>

                    <Col>
                        <BlockHeader title={"Прикачени документи:"}>
                            <Dropdown>
                                <Dropdown.Toggle variant="outline-primary" size={"sm"}>
                                    <FontAwesomeIcon icon={faAdd}/>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {
                                        DENTAL_RECORD_DOCUMENT_TYPE_KEYS.map(
                                            (k, idx) =>

                                                <Dropdown.Item key={k} onClick={() => uploadDocuments(k)}><FontAwesomeIcon
                                                    icon={faFileMedical}/>&nbsp;&nbsp;{k}</Dropdown.Item>
                                        )
                                    }
                                </Dropdown.Menu>
                            </Dropdown>


                        </BlockHeader>
                        <CardRounded>
                            {
                                working ? <Spinner animation={"border"}/> :
                                    <ClientDentalRecordDocuments documents={documents || undefined}
                                                                 onDelete={doDeleteDocument}/>
                            }
                        </CardRounded>
                    </Col>
                </>
            }
        </Row>

        {
            activeRecord &&
            <Row className={"d-none d-print-block"}>
                <Col>
                    <h1>{props.clientName}</h1>
                    <h3>План за лечение</h3>
                    <h4>{moment(activeRecord?.recordDate).format('DD.MM.yyyy')}</h4>
                    <hr />
                    <div className={"content border p-2 mt-2"}>
                        <div dangerouslySetInnerHTML={{ __html: activeRecord.treatmentPlan }} />
                    </div>
                </Col>
            </Row>
        }

        {
            showManipulationForm &&
            <ClientRecordManipulationForm record={activeRecord!}
                                          manipulation={{
                                              id: -1, manipulationDate: new Date(),
                                              clientId: activeRecord!.clientId,
                                              recordId: activeRecord!.id,
                                              clinicId: -1,
                                              clinicName: "",
                                              doctorId: -1,
                                              doctorName: "",
                                              manipulationName: "",
                                              bankConfirmed: false
                                          }}
                                          onClose={() => {
                                              setShowManipulationForm(false);
                                              props.onReload()
                                          }}/>
        }

        {
            uploadType &&
            <UploadFilesDialog
                onClose={() => {
                    setUploadType(null);
                    setTimeout(() => loadDocuments(), 500);
                }}
                fileDescriptor={{
                    clientId: activeRecord!.clientId,
                    recordId: activeRecord!.id,
                    documentType: uploadType
                } as UploadFileDescriptor}
            />
        }
    </>
}
