import {configureStore} from "@reduxjs/toolkit";
import {recordReducer} from "../features/RecordsSlice";
import {clientsReducer} from "../features/ClientsSlice";

const store = configureStore({
    reducer: {
        clients: clientsReducer,
        records: recordReducer,
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;