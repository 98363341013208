import {Button, Card, CloseButton, Col, Form, Modal, Row, Spinner, Table} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faCrutch, faPencil, faPlus} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError, zeroToEmptyStringAsCurrency
} from "../../../common";
import {API, API_CALL} from "../../../api";
import ConfigInfusionDetails from "./ConfigInfusionDetails";
import {SelectedProcedureItem} from "../../records/RecordDetailsPage";
import {ProcedureItem} from "../procedures/ConfigProceduresForm";


export interface InfusionItem {
    id: number;
    name: string;
    expense: number;
    sellPrice: number;
    validFrom: string;
    validTo: string;
}

export const EMPTY_INFUSION_ITEM: InfusionItem = {
    id: -1, name: '', expense: 0, sellPrice: 0, validFrom: '', validTo: ''
}

export interface ConfigInfusionsProps { onSelect?: (c: SelectedProcedureItem) => any, onClose: () => any, zIndex?: number }

export default function ConfigInfusionsForm({onClose, onSelect, zIndex}: ConfigInfusionsProps) {

    const [infusions, setInfusions] = useState<Array<InfusionItem>>([]);
    const [infusionsList, setInfusionsList] = useState<Array<InfusionItem>>([]);
    const [editInfusion, setEditInfusion] = useState<InfusionItem | null>(null);
    const [working, setWorking] = useState(false);
    const [filter, setFilter] = useState('');

    useEffect(() => {
        loadInfusions()
    }, []);

    useEffect(() => {
        const _list = infusions.filter(p => p.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0);
        setInfusionsList(_list);
    }, [infusions, filter])

    function loadInfusions() {
        API.getInfusions(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setInfusions(api_call.data.data.infusions);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на вливките!', translateError)
            }
        )
    }


    function saveInfusion(m: InfusionItem) {
        setWorking(true);

        API.postInfusion(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно записване', translateError);
                if (isSuccess(apiCall)) {
                    setEditInfusion(null);
                    loadInfusions();
                }
            }, m
        );
    }

    function deleteInfusion() {
        setWorking(true);

        API.deleteInfusion(
            (apiCall: API_CALL) => {
                setWorking(isLoading(apiCall));
                if (isError(apiCall)) showErrorsInToast(apiCall, 'Неуспешно изтриване', translateError);
                if (isSuccess(apiCall)) {
                    setEditInfusion(null);
                    loadInfusions();
                }
            }, editInfusion
        );
    }

    function selectInfusion(p: InfusionItem) {
        const item: SelectedProcedureItem = {
            itemId: p.id,
            itemName: p.name,
            itemExpense: p.expense || 0,
            itemSellPrice: p.sellPrice || 0,
        }

        if(onSelect) onSelect(item);
    }

    return (<>
            <Modal show={true} size={"xl"} onHide={onClose} style={{zIndex: zIndex ? zIndex : 1500}} >
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faCrutch} className={"mr-3"}/>Номенклатура на
                        вливките</Modal.Title>
                    <CloseButton onClick={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <Card className={"border-0"}>
                        <Card.Body className={"max-h-60vh scrollable"}>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Control type="text" value={filter}
                                                      placeholder={"Филтър..."}
                                                      onChange={(e) => setFilter(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <div className="table-container max-h-44vh">
                            <Table  size={"sm"} hover bordered className={"text-light"}>
                                <thead>
                                <tr className={"bg-secondary"}>
                                    <th>Име на вливката</th>
                                    <th className={"text-right"}>Разход</th>
                                    <th className={"text-right"}>Продажна цена</th>
                                    <th className={"w-100px"}></th>
                                </tr>
                                </thead>
                                <tbody className={"text-dark"}>
                                {
                                    infusionsList?.length > 0 ?
                                        infusionsList.map(
                                            m => <tr key={m.id} style={{lineHeight: "2rem"}}>
                                                <td onClick={() => { if(onSelect) selectInfusion(m)} }>{m.name}</td>
                                                <td onClick={() => { if(onSelect) selectInfusion(m)} } className={"text-right"}>
                                                    {zeroToEmptyStringAsCurrency(m.expense.toString())}</td>
                                                <td onClick={() => { if(onSelect) selectInfusion(m)} } className={"text-right"}>
                                                    {zeroToEmptyStringAsCurrency(m.sellPrice.toString())}</td>
                                                <td className={"text-center"}>
                                                    <Button variant={"outline-secondary"} size={"sm"} className={"m-0"}
                                                            onClick={() => setEditInfusion(m)}
                                                    >
                                                        <FontAwesomeIcon icon={faPencil}/>
                                                    </Button>
                                                    &nbsp;
                                                    {
                                                        onSelect &&
                                                        <Button variant={"outline-secondary"} size={"sm"}
                                                                className={"m-0"}
                                                                onClick={() => selectInfusion(m)}
                                                        >
                                                            <FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
                                                        </Button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                        :
                                        <tr>
                                            <td colSpan={4}>{!working && <>Няма дефинирани вливки</>}</td>
                                        </tr>
                                }
                                </tbody>
                            </Table>
                            </div>
                        </Card.Body>
                        <Card.Footer>
                            {
                                working && !editInfusion ? <Spinner animation="border"/> :
                                    <>
                                        {
                                            <Button
                                                onClick={
                                                    () => setEditInfusion({
                                                        ...EMPTY_INFUSION_ITEM
                                                    })
                                                }><FontAwesomeIcon
                                                icon={faPlus}/> Добави вливка</Button>
                                        }
                                    </>
                            }
                        </Card.Footer>
                    </Card>
                </Modal.Body>
            </Modal>


            {
                editInfusion &&
                <ConfigInfusionDetails item={editInfusion} working={working}
                                       onSave={(m) => saveInfusion(m)}
                                       onDelete={() => deleteInfusion()}
                                       onCancel={() => setEditInfusion(null)}
                                       zIndex={1 + (zIndex ? zIndex : 1500)}
                />
            }
        </>
    )
}
