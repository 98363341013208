import {Col, Row, Spinner, Tab, Table, Tabs} from "react-bootstrap";
import React, {FC, useEffect, useState} from "react";
import {API, API_CALL} from "../../api";
import {
    anyToDateString,
    isError,
    isLoading,
    isSuccess,
    showErrorsInToast,
    translateError, zeroToEmptyStringAsCurrencyWithCommas, zeroToEmptyStringAsNumber,
} from "../../common";
import ReportFinanceResultTable, {
    reportDataToFinanceItem,
    ReportFinanceResultTableItem
} from "./ReportFinanceResultTable";
import {ClientItem} from "../config/clients/ConfigClientsForm";
import {ClinicItem} from "../config/clinics/ConfigClinicsForm";
import DentalReportDoctorsTotals from "./DentalReportDoctorsTotals";
import DentalReportDoctorsDetails from "./DentalReportDoctorsDetails";
import DentalReportDoctorPatientsDetails from "./DentalReportDoctorPatientsDetails";
import DentalReportBankNotConfirmed from "./DentalReportBankNotConfirmed";

interface Props {
    printMode: string;
    fromDate: Date;
    toDate: Date;
}

const DentalReportsPage: FC<Props> = (props) => {
    const fromDate = props.fromDate;
    const toDate = props.toDate;
    const [working, setWorking] = useState(false);
    const [report, setReport] = useState<any>(null);
    const [clinics, setClinics] = useState<ClinicItem[]>([]);
    const [activeClinic, setActiveClinic] = useState<ClinicItem | null>(null);
    const [selectedReport, setSelectedReport] = useState("ALL");

    useEffect(() => {
        loadClinics();
    }, [props.fromDate, props.toDate]);

    function loadData() {
        API.getDentalFinanceReport(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    const report = api_call.data.data.report;
                    setReport(report);
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на отчета!', translateError)
            }, anyToDateString(fromDate), anyToDateString(toDate), activeClinic?.id.toString() || ""
        )
    }

    function loadClinics() {
        API.getClinics(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    setClinics(api_call.data.data.clinics);
                    loadData();
                }
                if (isError(api_call)) showErrorsInToast(api_call, 'Възникна грешка при зареждане на клиниките!', translateError)
            }
        )
    }

    function selectClinicHandle(eventKey: string | null) {
        if (!eventKey) return;
        setActiveClinic(clinics.find(
            value => value.id.toString() === eventKey
        ) || null);
    }

    useEffect(() => {
        loadData();
    }, [activeClinic]);

    const reportTable = selectedReport === "ALL" ?
        <DentalReportDoctorsTotals report={report}/> :
        (
            selectedReport === "NOT_CONFIRMED_BANK" ? <DentalReportBankNotConfirmed report={report}/> :
                <DentalReportDoctorsDetails report={report} doctorId={selectedReport}/>
        );

    return (
        <>
            {
                working &&
                <Row><Col className={"text-center p-5"}><Spinner animation={"border"}/></Col></Row>
            }
            {
                !working && report &&
                <>
                    <Row className={"mb-3"}>
                        <Col xs={"12"}>
                            <div className={"d-print-none"}>
                                <Tabs variant={"pills"} activeKey={activeClinic?.id || "Всички"}
                                      onSelect={eventKey => selectClinicHandle(eventKey)}
                                >
                                    <Tab title={"Всички"} key={"Всички"} eventKey={"Всички"}/>
                                    {
                                        clinics.map(
                                            value => <Tab title={value.name} key={value.id} eventKey={value.id}/>
                                        )
                                    }
                                </Tabs>
                            </div>
                            <div
                                className={"d-print-block d-none"}>Клиника: <strong>{activeClinic?.name?.toUpperCase() || "ВСИЧКИ КЛИНИКИ"}</strong>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={"12"}>
                            <div className={"d-print-none"}>
                                <Tabs activeKey={selectedReport}
                                      onSelect={eventKey => setSelectedReport(eventKey || "ALL")}
                                      variant={"pills"}>
                                    <Tab title={"Обобщена справка"} eventKey={"ALL"}/>
                                    {
                                        report.doctors.map(
                                            (item: any) => <Tab title={item.name} key={item.id} eventKey={item.id}/>
                                        )
                                    }
                                    <Tab title={"Непотвърдени плащания по банка"} eventKey={"NOT_CONFIRMED_BANK"}/>
                                </Tabs>
                            </div>
                            <div
                                className={"d-print-block d-none"}>Доктор: <strong>{
                                report.doctors.find((d: any) => d.id.toString() === selectedReport)?.name?.toUpperCase() || "ВСИЧКИ ДОКТОРИ"
                            }</strong>
                            </div>
                            <div className={"mt-3"}>
                                {reportTable}
                            </div>
                        </Col>
                        {
                            props.printMode !== "short" && [...report.doctors, report.avanses]
                                .filter(
                                    d => {
                                        if (selectedReport === "ALL") {
                                            return true;
                                        }
                                        return (selectedReport === d.id.toString())
                                    }
                                )
                                .map(
                                    (doctor: any) => <Col xs={selectedReport === "ALL" ? 12 : 12} key={doctor.id}>
                                        <DentalReportDoctorPatientsDetails doctor={doctor}/>
                                    </Col>
                                )}
                    </Row>

                </>
            }
        </>
    )
}

export default DentalReportsPage;
