import {Button, Card, CloseButton, Col, Form, InputGroup, Modal, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCancel, faFileInvoice, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {InvoiceItem} from "./InvoicesPage";
import {
    isError,
    isLoading,
    isSuccess, showErrorsInToast,
    showErrorsListInToast, showMsgInToast, translateError,
    zeroToEmptyString,
    zeroToEmptyStringAsNumber
} from "../../common";
import {ConfirmDeleteModal} from "../../common/ConfirmDeleteModal";
import moment from "moment";
import ConfigSuppliersForm, {SupplierItem} from "../config/suppliers/ConfigSuppliersForm";
import {StringInput} from "../../common/StringInput";
import {API, API_CALL} from "../../api";


export interface InvoiceDetailsProps {
    item: InvoiceItem;
    onChange: () => any;
    onCancel: () => any;
    zIndex?: number;
}

export default function InvoiceDetails({item, onChange, onCancel, zIndex}: InvoiceDetailsProps) {
    const [working, setWorking] = useState(false);
    const [selectSupplier, setSelectSupplier] = useState(false);
    const [deleteFlag, setDeleteFlag] = useState(false);

    const [invType, setInvType] = useState<string>(item.invType);
    const [description, setDescription] = useState(item.description);
    const [invNumber, setInvNumber] = useState(item.invNumber);
    const [invDate, setInvDate] = useState(item.invDate.toString());
    const [supplier, setSupplier] = useState<SupplierItem | null>(item.supplier);
    const [amount, setAmount] = useState(zeroToEmptyStringAsNumber(item.amount));
    const [currency, setCurrency] = useState(item.currency);
    const [alertDate, setAlertDate] = useState(item.alertDate.toString());

    useEffect(() => {
        let newDate = alertDate || invDate;

        const invMomentDate = moment(invDate, 'DD.MM.YYYY');
        if (invMomentDate.isValid()) {
            const newMomentDate = invMomentDate.add(supplier?.paymentDelay || 0, "days").format('DD.MM.YYYY');
            if (!item.id) setAlertDate(newMomentDate);
        } else {
            setInvDate('');
            setAlertDate('');
        }
    }, [supplier, invDate])

    function save(e: any) {
        e.preventDefault();

        const errors: Array<string> = [];

        // if (invNumber.trim().length === 0) errors.push("Въведете номер на фактура.");

        const am = parseFloat(amount);
        if (isNaN(am) || am === 0) errors.push("Въведете сума за плащане.");

        let _supl = supplier;
        let _invNum = invNumber;
        let _invType: "BANK" | "CASH" | "OTHER" = (invType === "BANK" ? "BANK" : "CASH");

        if (invType === "OTHER") {
            _invType = "OTHER";
            _supl = {id: -1, name: '', iban: '', paymentDelay: 0};
            _invNum = '00000000000';
        }
        if (!_supl) errors.push("Изберете доставчик.");

        if (errors.length === 0) {
            const i: InvoiceItem = {
                id: item.id,
                clinica: item.clinica,
                invType: _invType,
                invNumber: _invNum,
                description: description,
                amount: am,
                alertDate: moment(alertDate, 'DD.MM.YYYY').toDate(),
                currency: currency,
                invDate: moment(invDate, 'DD.MM.YYYY').toDate(),
                isPaid: false,
                supplier: _supl,
                updated_at: '',
            }

            API.postInvoice(
                (api_call: API_CALL) => {
                    setWorking(isLoading(api_call));
                    if (isSuccess(api_call)) {
                        onChange();
                    }
                    if (isError(api_call)) {
                        showErrorsInToast(api_call, 'Неуспешно запазване на фктурата', translateError)
                    }
                }, i
            );

        } else {
            showErrorsListInToast("Грешка", errors);
        }
    }

    function onDelete() {
        if (!item.id) return;

        API.deleteInvoice(
            (api_call: API_CALL) => {
                setWorking(isLoading(api_call));
                if (isSuccess(api_call)) {
                    onChange();
                    showMsgInToast("Фактурата е изтрита успешно.");
                }
                if (isError(api_call)) {
                    showErrorsInToast(api_call, 'Неуспешно изтриване на фактурата.', translateError)
                }
            }, item.id
        );
    }

    function validateAmount() {
        setAmount(zeroToEmptyString(amount || 0));
    }

    return (
        <>
            <div className={"modal-overlay"} style={{zIndex: zIndex || 1500}}/>
            <Modal show={!deleteFlag} onHide={onCancel} style={{zIndex: zIndex ? zIndex + 1 : 1501}}>
                <Modal.Header>
                    <Modal.Title><FontAwesomeIcon icon={faFileInvoice} className={"mr-3"}/>Детайли за
                        фактура/разход</Modal.Title>
                    <CloseButton onClick={onCancel}/>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={save}>
                        <Card className={"border-0"}>
                            <Card.Body className={"max-h-60vh scrollable"}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Тип плащане:</Form.Label>
                                    <Form.Control as={"select"} value={invType}
                                                  onChange={e => setInvType(e.target.value)}>
                                        <option value={"BANK"}>Плащане по банка</option>
                                        <option value={"CASH"}>Плащане в брой</option>
                                        <option value={"OTHER"}>Разход без фактура</option>
                                    </Form.Control>
                                </Form.Group>
                                {
                                    invType !== "OTHER" &&
                                    <Form.Group className="mb-3">
                                        <Form.Label>Фактура номер:</Form.Label>
                                        <Form.Control value={invNumber || ''}
                                                      onChange={(e) => setInvNumber(e.target.value)}
                                        />
                                    </Form.Group>
                                }
                                        <StringInput value={invDate || ''}
                                                     label={"Издадена на:"} placeholder={"дд.мм.гггг"}
                                                     onChange={(e) => setInvDate(e)}
                                        />

                                {
                                    invType !== "OTHER" &&
                                    <>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Издадена от:</Form.Label>
                                            <Form.Control value={supplier?.name || ''}
                                                          placeholder={"Изберете доставчик..."}
                                                          onClick={() => setSelectSupplier(true)}
                                            />
                                            {
                                                supplier?.iban &&
                                                <Form.Text>IBAN: <strong>{supplier?.iban || "-- не е въведен --"}</strong></Form.Text>
                                            }
                                        </Form.Group>
                                    </>
                                }
                                <Form.Group className="mb-3">
                                    <Form.Label>Сума и валута за плащане:</Form.Label>
                                    <InputGroup>
                                        <Form.Control value={amount || ''} placeholder={"0.00"}
                                                      onChange={(e) => setAmount(e.target.value)}
                                                      onBlur={validateAmount}
                                        />
                                        <InputGroup.Append>
                                            <Form.Control as={"select"} value={currency}
                                                          onChange={e => setCurrency(e.target.value === "BGN" ? "BGN" : "EUR")}>
                                                <option value={"BGN"}>BGN</option>
                                                <option value={"EUR"}>EUR</option>
                                            </Form.Control>
                                        </InputGroup.Append>
                                    </InputGroup>
                                </Form.Group>

                                {
                                    invType === "BANK" &&
                                    <StringInput value={alertDate || ''}
                                                 label={"Да се плати преди:"} placeholder={"дд.мм.гггг"}
                                                 onChange={(e) => setAlertDate(e)}
                                    />
                                }

                                {
                                    invType === "OTHER" &&
                                    <Form.Group className="mb-3">
                                        <Form.Label>Описание на разхода:</Form.Label>
                                        <Form.Control as={"textarea"} value={description || ''}
                                                      onChange={(e) => setDescription(e.target.value)}
                                        />
                                    </Form.Group>
                                }

                            </Card.Body>
                            <Card.Footer>
                                {
                                    working ? <Spinner animation="border"/> :
                                        <>
                                            <Button type={"submit"}><FontAwesomeIcon
                                                icon={faSave}/> Запази</Button>&nbsp;
                                            <Button onClick={() => onCancel()} variant={"secondary"}><FontAwesomeIcon
                                                icon={faCancel}/> Откажи</Button>
                                            <Button onClick={() => setDeleteFlag(true)} variant={"danger"}
                                                    style={{float: "right"}}>
                                                <FontAwesomeIcon
                                                    icon={faTrash}/> Изтрий</Button>
                                        </>
                                }
                            </Card.Footer>
                        </Card>
                    </Form>
                </Modal.Body>
            </Modal>

            {
                deleteFlag &&
                <ConfirmDeleteModal working={working} onConfirm={onDelete} onReject={() => setDeleteFlag(false)}/>
            }

            {
                selectSupplier && <ConfigSuppliersForm
                    onSelect={(s) => {
                        setSupplier(s);
                        setSelectSupplier(false)
                    }}
                    onClose={() => setSelectSupplier(false)} zIndex={1600}/>
            }
        </>
    )
}
