import {createSlice} from "@reduxjs/toolkit";
import { ClientItem } from "../pages/config/clients/ConfigClientsForm";

export interface ClientsSliceState {
    clients: ClientItem[];
}

export const clientsSlice = createSlice({
    name: 'clients',

    initialState: {
        clients: []
    } as ClientsSliceState,

    reducers: {
        addClient : (state, action) => {
            try {
                getClientIdxById(state.clients, action.payload.id);
            } catch (e) {
                state.clients.push(action.payload);
            }
            return state;
        },
        updateClient : (state, action) => {
            let idx = getClientIdxById(state.clients, action.payload.id);
            state.clients[idx] = action.payload;

        },
        removeClient : (state, action) => {
            let idx = getClientIdxById(state.clients, action.payload.id);
            state.clients.splice(idx, 1);
        },
    },
});

export const { addClient, updateClient, removeClient } = clientsSlice.actions;

export const selectClients = (state: any) => state.clients.clients;

export function getClientById (clients: ClientItem[], id: number): ClientItem {
    let client = clients.find(c => c.id === id);
    if(!client) throw new Error('Client not found');
    return client;
}

export function getClientIdxById (clients: ClientItem[], id: number): number {
    let idx = clients.findIndex(c => c.id === id);
    if(idx > -1) {
        return idx;
    } else throw new Error("Client not found");
}

export const clientsReducer =  clientsSlice.reducer;